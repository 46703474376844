import { Button, Group, Modal, PasswordInput, Stack, Text, TextInput } from '@mantine/core';
import { useUserAPI } from 'api/userAPI';
import { Permission, useAuth } from 'hooks/useAuth';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DeleteAccountButton = () => {
    const navigate = useNavigate()
    const { useDeleteAccount } = useUserAPI()
    const deleteAccount = useDeleteAccount()
    const { logout } = useAuth(Permission.USER_ONLY);

    const [deleteOpen, setDeleteOpen] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState("")


    const handleDeleteAccount = async () => {
        deleteAccount.mutate({
            password
        }, {
            onSuccess: () => {
                navigate("/")
                logout()
            },
            onError(e: any) {
                console.log(e)
                const message = e.response.data.error.message
                setPasswordError(message)    
            },
        })
    }

    return (
        <>
            <Modal
                opened={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                title="Confirm delete account?"
                centered
            >
                <Stack>
                    <Text ta="center" color='red'>Your account will be deleted.</Text>
                    <PasswordInput
                        label='Type in your password to delete'
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        error={passwordError}
                    />
                    <Group>
                        <Button
                            sx={{ flex: 1 }}
                        >
                            Cancel</Button>
                        <Button
                            sx={{ flex: 1 }}
                            color="red"
                            variant='outline'
                            disabled={password.length === 0}
                            onClick={() => handleDeleteAccount()}
                        >
                            Confirm
                        </Button>
                    </Group>
                </Stack>
            </Modal>
            <Button
                variant='outline'
                color="red"
                onClick={() => setDeleteOpen(true)}
            >
                Delete Account
            </Button>
        </>
    );
};

export default DeleteAccountButton;