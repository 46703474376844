import API from "../api/API";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { flattenObj } from "helpers/strapi";
import { Permission, useAuth } from "hooks/useAuth";
const qs = require("qs");

export const useAgencyAPI = () => {
    const { user } = useAuth(Permission.USER_ONLY);

    const getOneAgency = async (id: string) => {
        try {
            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`agencies/${id}?populate=*`);

            return flattenObj(response.data);
        } catch (e) {
            return e;
        }
    };

    const getPotentialAgentData = async (id: string) => {
        try {
            const query = qs.stringify(
                {
                    populate: "*",
                    filters: {
                        selectedAgencies: {
                            id: {
                                $contains: id,
                            }
                        },
                    },
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );

            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`potential-agents?${query}`);

            return flattenObj(response.data);
        } catch (e) {
            return e;
        }
    };

    const useGetPotentialAgentData = (id: string) =>
        useQuery(
            ["GET_POTENTIAL_AGENT_BY_AGENCY"],
            () => getPotentialAgentData(id),
            {
                enabled: !!id,
            }
        );

    const useGetOneAgency = (id: string) =>
        useQuery(["GET_ONE_AGENCY", id], () => getOneAgency(id), {
            enabled: !!id,
        });

    const getOneAgencyBySlug = async (slug: string) => {
        try {
            const query = qs.stringify(
                {
                    populate: "*",
                    filters: {
                        slug: {
                            $eq: slug,
                        },
                    },
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );

            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`agencies?${query}`);

            return flattenObj(response.data);
        } catch (e) {
            return e;
        }
    };

    const useGetOneAgencyBySlug = (slug: string) =>
        useQuery(
            ["GET_ONE_AGENCY_BY_SLUG", slug],
            () => getOneAgencyBySlug(slug),
            {
                enabled: !!slug,
            }
        );

    const useUpdateAgency = () =>
        useMutation(async (payload: any) => {
            const resp = await API({ requireAuth: true, token: user.jwt }).put<
                any,
                any
            >(`agencies/${payload.id}`, {
                data: { ...payload },
            });
            return flattenObj(resp);
        });

    return {
        useGetOneAgency,
        useGetOneAgencyBySlug,
        useUpdateAgency,
        useGetPotentialAgentData,
    };
};
