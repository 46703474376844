//@ts-nocheck
import ReCAPTCHA from "react-google-recaptcha";

import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import {
    Box,
    Grid,
    Text,
    TextInput,
    Button,
    FileInput,
    UnstyledButton,
    ScrollArea,
    Checkbox,
    Affix,
    Group,
    Loader,
    Container,
    Center,
    Anchor,
    Image,
    Stack,
    LoadingOverlay,
    Modal,
    useMantineTheme,
    Card,
    Flex,
    Select,
} from "@mantine/core";
import { BaseFormInput } from "../components/Inputs/BaseFormInput";
import { BaseFileInput } from "components/Inputs/BaseFileInput";
import { useForm } from "@mantine/form";
import {
    useLocation,
    useNavigate,
    useNavigation,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { useAuth, Permission } from "hooks/useAuth";
import { BasePasswordInput } from "components/Inputs/BasePasswordInput";
import { notifications } from "@mantine/notifications";
import { USER_ACTIONS } from "context/user/userActions";
import { RegisterTitle } from "components/Header/userHeader";
import { Trash, X } from "tabler-icons-react";
import PdfDisplay from "components/PdfDisplay";
import { useUpload } from "hooks/useUpload";
import { accountTypes } from "types/accountTypes";
import { AcceptTnc } from "components/Profile/AcceptTnc";
import { RegisterFooter } from "components/Footer/RegisterFooter";
import { FormPasswordInput } from "components/Inputs/FormPasswordInput";
import { EditFormImageInput } from "components/Inputs/EditFormImageInput";
import { REGISTRATION_PIXEL, formStep, validateUserStatus } from "helpers/constants";
import { RegisterOtp } from "./RegisterOtp";
import { removeAllWhitespaces, sanitizePhoneNumber, selectFilter } from "helpers/utility";
import { useGetAgentFromReferral } from "api/userAPI";
import { StickyContainer, Sticky } from "react-sticky";
import { theme } from "ThemeProvider";
import { useMediaQuery, useScrollIntoView } from "@mantine/hooks";
import { strictNumberInput, validateEmail } from "helpers/validations";
import { useInsightsAPI } from "api/useInsightsAPI";
import RegisterFeedback from "components/RegisterFeedback";
import PropmothFree from "components/Banners/PropmothFree";
import AgentTagInput from "components/Inputs/AgentTagInput";
import MaintainenceModal from "components/Modals/MaintainenceModal";

const numberlessRen = ['Probation', 'Referral']
const numberedRen = ['REN', 'PEA', 'REA']
const renTypes = ['REN', 'PEA', 'REA', 'Probation', 'Referral']

type FormValues = {
    fullname: string;
    email: string;
    phoneNumber: string;
    password: string;
    passwordConfirm: string;
    renId: string;
    agencyName: string;
    renImg: File | null;
};

const AgentForm: React.FC<any> = ({
    setView,
    setVerifyData,
    verifyData,
    referrer,
    ReactPixel
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useMantineTheme();
    const { useGetUserInsights } = useInsightsAPI()
    const {
        data: insightsData,
        isLoading: insightsIsLoading,
        error: insightsError,
    } = useGetUserInsights();

    const [captchaPassed, setCaptchaPassed] = useState(false)
    const mdAndLess = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const { scrollIntoView, targetRef } = useScrollIntoView<
        HTMLDivElement,
        HTMLDivElement
    >();

    const [checkboxes, setCheckboxes] = useState<any>({
        tnc: false,
        privacy: false,
    });
    const [tagType, setTagType] = useState("")
    const [tagNumber, setTagNumber] = useState("")
    const [tagError, setTagError] = useState("")
    const {
        registerMutation,
        userDispatch,
        validateMutation,
        requestOtpMutation,
    } = useAuth(Permission.GUEST_ONLY);
    const [base64Preview, setBase64Preview] = useState<any>();
    const [uploadIsLoading, setUploadIsLoading] = useState(false);
    const form = useForm<FormValues>({
        // initialValues: {
        //     fullname: "testagent",
        //     email: "testagent@gmail.com",
        //     phoneNumber: "128376152",
        //     password: "password",
        //     passwordConfirm: "password",
        //     renId: "",
        //     agencyName: "propmoth",
        //     renImg: null,
        //     preferredName: "testagent"
        // },
        initialValues: {
            fullname: "",
            email: "",
            phoneNumber: "",
            password: "",
            passwordConfirm: "",
            renType: "",
            renNumber: "",
            agencyName: "",
            renImg: null,
            preferredName: "",
            preferredLanguage: ""
        },
        validateInputOnChange: true,
        validate: (values) => ({
            fullname:
                values.fullname.length === 0 ? "Full name is required" : null,
            email: validateEmail(values.email),
            phoneNumber:
                removeAllWhitespaces(values.phoneNumber).length === 0
                    ? "Phone number is required"
                    : null,
            renType: values.renType.length === 0 ? "type is required" : null,
            preferredLanguage: values.preferredLanguage.length === 0 ? "Preferred Language is required" : null,
            renNumber:
                !!numberedRen.includes(values.renType) && values.renNumber.length === 0 ? "number is required" : null,
            agencyName:
                values.agencyName.length === 0
                    ? "Agency name is required"
                    : null,
            renImg: !values.renImg ? "REN image is required" : null,
            passwordConfirm:
                values.passwordConfirm !== values.password
                    ? "Passwords do not match"
                    : null,
        }),
    });

    const onSubmit = useCallback((values: FormValues) => {
        ReactPixel.trackCustom("CompleteRegistration", { type: 'agent' }, [REGISTRATION_PIXEL])
        let formattedIdentifier = sanitizePhoneNumber(values.phoneNumber);

        if (!form.isValid()) {
            return
        }

        const renId = `${form.values.renType ?? ""}${form.values.renNumber ?? ""}`

        setTagError("")

        let payload = {} as any;
        payload = {
            fullname: form.values.fullname,
            email: removeAllWhitespaces(form.values.email),
            password: removeAllWhitespaces(form.values.password),
            phoneNumber: `60${removeAllWhitespaces(form.values.phoneNumber)}`,
            username: `+60${removeAllWhitespaces(form.values.phoneNumber)}`,
            renId,
            renImg: form.values.renImg,
            agencyName: form.values.agencyName,
            userType: accountTypes.AGENT,
            preferredName: form.values.preferredName,
            preferredLanguage: form.values.preferredLanguage
        };

        registerMutation.mutate(payload, {
            onSuccess: (data) => {
                notifications.show({
                    title: data?.user.email,
                    message: "Account has been created.",
                });
                userDispatch({
                    type: USER_ACTIONS.SET,
                    payload: {
                        jwt: data.jwt,
                        refreshToken: data?.refreshToken ?? "",
                        blocked: data.user?.blocked,
                        confirmed: data.user?.confirmed,
                        createdAt: new Date(data.user?.createdAt),
                        email: data.user?.email,
                        id: data.user?.id,
                        provider: data.user?.provider,
                        updatedAt: new Date(data.user?.updatedAt),
                        username: data.user?.username,
                        userType: data.user?.userType,
                        checkoutId: data.user?.checkoutId,
                        fullname: data.user?.fullname,
                        status: data.user?.status,
                        loaded: true,
                    },
                });

                navigate("/profile", {
                    state: {
                        fromRegister: true
                    }
                });
            },
        });

        return

        if (!!process.env.REACT_APP_IS_DEV) {
            setView(formStep.OTP);
            setVerifyData({
                ...form.values,
                userType: accountTypes.AGENT,
                requestId: "dev",
                phoneNumber: `60${formattedIdentifier}`,
                username: `60${formattedIdentifier}`,
            });
        } else {
            validateMutation.mutate(
                {
                    ...values,
                    phoneNumber: `60${formattedIdentifier}`,
                },
                {
                    onSuccess: (validateData) => {
                        switch (validateData.status) {
                            case validateUserStatus.VALID: {
                                requestOtpMutation.mutate(
                                    { phoneNumber: `60${formattedIdentifier}` },
                                    {
                                        onSuccess: (data) => {
                                            setView(formStep.OTP);
                                            setVerifyData({
                                                ...validateData.verifyUser,
                                                userType: accountTypes.AGENT,
                                                requestId: data.data.requestId,
                                            });
                                        },
                                    }
                                );
                                break;
                            }
                            case validateUserStatus.INVALID: {
                                for (const [field, error] of Object.entries(
                                    validateData.formErrors
                                )) {
                                    form.setFieldError(field, error as string);
                                }
                                break;
                            }
                        }
                    },
                }
            );
        }
    }, [form])

    const handleUpload = async (e: any) => {
        // setValue(e);
        setUploadIsLoading((state) => !state);
        const convertFileToBase64 = (file: any) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };
        const base64: string | unknown = await convertFileToBase64(e);
        // console.log('base64?', base64)
        setUploadIsLoading((state) => !state);
        // if (base64) {
        setBase64Preview(base64);
        form.setFieldValue("renImg", e);
        // }
    };

    const clearFileUpload = () => {
        setBase64Preview(null);
        form.setFieldValue("renImg", null);
    };

    useEffect(() => {
        if (registerMutation.error) {
            const errors = (registerMutation.error as any)?.response?.data
                ?.error?.details?.errors;
            if (errors) {
                errors.forEach(({ path, message }: any) => {
                    // console.log(path[0], message);
                    if (path[0] === "username") {
                        form.setFieldError(
                            "phoneNumber",
                            message.replace("username", "phone number")
                        );
                    }
                    form.setFieldError(path[0], message);
                });
            }
        }
    }, [registerMutation.error]);

    useEffect(() => {
        if (verifyData) {
            form.setValues({
                fullname: verifyData.fullname,
                email: verifyData.email,
                phoneNumber: verifyData.phoneNumber.slice(2),
                password: verifyData.password,
                passwordConfirm: verifyData.password,
                renId: verifyData.renId,
                agencyName: verifyData.agencyName,
                renImg: verifyData.renImg,
            });
        }
    }, [verifyData]);

    const handleChange = (key: string) => {
        setCheckboxes((prevState: any) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };
    const allowProceed = useMemo(
        () => {
            const shouldProceed = Object.values(checkboxes).every((value) => !!value === true)
                && !!form.isValid()

            return shouldProceed
        },
        [checkboxes, form]
    );

    const submitIsLoading = useMemo(() => {
        return validateMutation.isLoading || requestOtpMutation.isLoading
    }, [validateMutation, requestOtpMutation,])


    const WelcomeCard = ({ style }) => {
        return (
            <Stack sx={{ height: "100%" }} style={style ?? {}}>
                <Box
                    sx={(theme) => ({
                        background: "#4E7A4D",
                        width: "100%",
                        position: "sticky",
                        top: 0,
                        right: 0,

                        height: "100vh",
                        [theme.fn.smallerThan("md")]: {
                            height: "calc(100vh - 56px)",
                        },
                    })}
                    p={16}
                >
                    <Stack
                        sx={{
                            width: "100%",
                            height: "100%",
                            border: "1px solid #ddd",
                            alignItems: "center",
                        }}
                        p={16}
                    >
                        <Stack
                            sx={{
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                backgroundImage: `url('/referral.png')`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                            }}
                        >
                            <Stack
                                sx={{
                                    background: "rgba(0,0,0,0.6)",
                                    width: "100%",
                                    height: "100%",
                                    flexGrow: 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                p={24}
                                spacing={48}
                            >
                                <Stack spacing={8}>
                                    <Text
                                        align="center"
                                        color="white"
                                        component="h1"
                                        sx={{ margin: 0 }}
                                        fz="2em"
                                        lh={1}
                                        fw={500}
                                    >
                                        You have been invited by
                                    </Text>
                                    <Text
                                        align="center"
                                        color="white"
                                        component="h1"
                                        sx={{ margin: 0 }}
                                        fz="2em"
                                        lh={1}
                                        fw={500}
                                    >
                                        {referrer?.fullname}
                                    </Text>
                                    <Text
                                        align="center"
                                        color="white"
                                        component="h1"
                                        sx={{ margin: 0 }}
                                        fz="2em"
                                        lh={1}
                                        fw={500}
                                    >
                                        to join
                                    </Text>
                                </Stack>
                                <Image
                                    src={"/propmoth-white.png"}
                                    width={142}
                                    height={32}
                                />
                                <Stack spacing={8} align="center">
                                    {mdAndLess ? (
                                        <Button
                                            onClick={() => scrollIntoView({})}
                                        >
                                            Register Now
                                        </Button>
                                    ) : (
                                        <Text
                                            color="white"
                                            component="h2"
                                            sx={{ margin: 0 }}
                                            fz="2em"
                                            lh={1}
                                            fw={500}
                                        >
                                            Register now
                                        </Text>
                                    )}
                                    <Text
                                        color="white"
                                        component="h2"
                                        sx={{ margin: 0 }}
                                        fz="2em"
                                        lh={1}
                                        fw={500}
                                        align="center"
                                    >
                                        and get your 12 months subscription
                                    </Text>
                                    <Text
                                        color="white"
                                        component="h2"
                                        sx={{ margin: 0 }}
                                        fz="2em"
                                        lh={1}
                                        fw={500}
                                        align="center"
                                    >
                                        for only RM300
                                    </Text>
                                    {/* <Group spacing={8}>
                                        <Text
                                            color="white"
                                            component="h2"
                                            fz="2em"
                                            lh={1}
                                            sx={{
                                                margin: 0,
                                            }}
                                            fw={500}
                                        >
                                            5
                                        </Text>
                                        <Image
                                            src={"/token.png"}
                                            height={24}
                                            width={24}
                                        />
                                    </Group> */}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    return (
        <StickyContainer>
            <Grid
                m={0}
                sx={(theme) => ({
                    position: "relative",
                    [theme.fn.smallerThan("md")]: {
                        flexDirection: "column-reverse",
                    },
                })}
            >
                {!referrer && (
                    <Grid.Col
                        p={0}
                        md={4}
                        sx={(theme) => ({
                            [theme.fn.smallerThan("md")]: {
                                display: "none",
                            },
                        })}
                    >
                        <Box
                            sx={{
                                backgroundImage: `url('/section-img.png')`,
                                width: "100%",
                                height: "100%",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                minHeight: "calc(100vh - 60px)",
                                position: 'relative'
                            }}
                        >
                            <Box sx={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                height: '100%',
                                width: '100%',
                                backgroundColor: 'rgba(0,0,0,0.6)'
                            }}>
                                <Stack sx={{ height: '100%' }} align="center" justify="center" p={8}>
                                    <Text color="white" fz={'3vw'} align="center">
                                        Join <Text color="white" component="span" fw={700}>{100 + insightsData?.totalAgents}</Text> of agents <br /> looking for leads today!
                                    </Text>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid.Col>
                )}
                <Grid.Col
                    md={!!referrer ? 6 : 8}
                    xs={12}
                    p={0}
                    px={24}
                    sx={(theme) => ({
                        backgroundColor: theme.colors.bgYellow,
                        [theme.fn.largerThan("md")]: {
                            // paddingTop: "96px",
                            paddingBottom: "96px",
                        },
                        [theme.fn.smallerThan("md")]: {
                            // paddingTop: "24px",
                            paddingBottom: "32px",
                        },
                    })}
                    ref={targetRef}
                >
                    <Group sx={{ justifyContent: 'center' }} mt={16}>
                        <Stack spacing={32} sx={{ maxWidth: "450px" }}>
                            <form onSubmit={form.onSubmit(onSubmit)}>
                                <Box
                                    mx="auto"
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 8,
                                    }}
                                    px={16}
                                >
                                    <Stack spacing={0} mb={16}>
                                        <Text size={32} weight={500}>
                                            Create an Account
                                        </Text>
                                        {insightsData?.totalAgents ? (
                                            <Text size={16}>Join the new generation of property browsing with <br /> {100 + insightsData?.totalAgents} other agents today!</Text>
                                        ) : (
                                            <Text size={16}>
                                                Welcome to the future of property
                                            </Text>
                                        )}
                                    </Stack>
                                    <BaseFormInput
                                        label="Full Name (As per NRIC)"
                                        placeholder="Enter full name"
                                        {...form.getInputProps("fullname")}
                                        required
                                    />
                                    <BaseFormInput
                                        label="Email Address"
                                        placeholder="Enter email address"
                                        {...form.getInputProps("email")}
                                        required
                                    />
                                    <Box>
                                        <BaseFormInput
                                            // type="number"
                                            label="Phone Number"
                                            icon={
                                                <Text
                                                    color="black"
                                                    sx={{ whiteSpace: "nowrap" }}
                                                >
                                                    {`MY (+60)`}
                                                </Text>
                                            }
                                            onKeyDown={(evt: any) => strictNumberInput(evt)}
                                            iconWidth={90}
                                            {...form.getInputProps("phoneNumber")}
                                            required
                                        />
                                        {!!form.errors?.phoneNumber && (
                                            <Text size={12} color="red">
                                                Please double-check this number
                                            </Text>
                                        )}
                                    </Box>
                                    <FormPasswordInput
                                        {...form.getInputProps("password")}
                                        form={form}
                                        required
                                    />
                                    <BasePasswordInput
                                        label="Confirm Password"
                                        {...form.getInputProps("passwordConfirm")}
                                        required
                                    />
                                    <BaseFormInput
                                        label="Preferred Name"
                                        {...form.getInputProps("preferredName")}
                                    />
                                    <Select
                                        data={[
                                            {label: "English", value: "ENGLISH"},
                                            {label: "Malay", value: "MALAY"},
                                            {label: "Chinese", value: "CHINESE"},
                                        ]}
                                        label="Preferred Language"
                                        {...form.getInputProps("preferredLanguage")}
                                    />
                                    <Stack spacing={2}>
                                        <Text>REN/PEA/REA Tag </Text>
                                        <Flex sx={{ width: '100%' }}>
                                            <Select
                                                data={renTypes}
                                                searchable
                                                filter={selectFilter}
                                                sx={{
                                                    maxWidth: numberlessRen.includes(form.values.renType) ? '100%' : 100,
                                                    width: numberlessRen.includes(form.values.renType) ? '100%' : ''
                                                }}
                                                {...form.getInputProps("renType")}
                                            />
                                            {(numberedRen.includes(form.values.renType)) && (
                                                <TextInput
                                                    sx={{ flex: 1 }}
                                                    {...form.getInputProps("renNumber")}
                                                    required
                                                />
                                            )}
                                        </Flex>
                                    </Stack>
                                    <BaseFormInput
                                        label="Firm/Agency Name"
                                        {...form.getInputProps("agencyName")}
                                        required
                                    />
                                    <EditFormImageInput
                                        {...form.getInputProps("renImg")}
                                        label="REN Image"
                                        required
                                    />
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                                        onChange={(e) => {
                                            setCaptchaPassed(!!e)
                                        }}
                                    />
                                    <Checkbox
                                        label={
                                            <Text>
                                                I have read and agree to the{" "}
                                                <Anchor
                                                    href="/termsandconditions"
                                                    target="_blank"
                                                    sx={{ textDecoration: "underline" }}
                                                >
                                                    Terms of Service
                                                </Anchor>
                                            </Text>
                                        }
                                        checked={checkboxes.tnc}
                                        onChange={() => handleChange("tnc")}
                                        mb={16}
                                    />
                                    <Checkbox
                                        label={
                                            <Text>
                                                I have read and agree to the{" "}
                                                <Anchor
                                                    href="/termsandconditions"
                                                    target="_blank"
                                                    sx={{ textDecoration: "underline" }}
                                                >
                                                    Privacy Policy
                                                </Anchor>
                                            </Text>
                                        }
                                        checked={checkboxes.privacy}
                                        onChange={() => handleChange("privacy")}
                                    />
                                    <Text mt={16}>
                                        We will validate your data with your
                                        firm/agency, this process might take up to 3
                                        business days for you to be approved
                                    </Text>
                                    <Button
                                        size="lg"
                                        radius={10}
                                        my={16}
                                        // type="submit"
                                        onClick={() => onSubmit(form.values)}
                                        loading={submitIsLoading}
                                        disabled={
                                            submitIsLoading ||
                                            !allowProceed ||
                                            !captchaPassed
                                        }
                                    >
                                        Register
                                    </Button>
                                    <RegisterFooter />
                                </Box>
                            </form>
                            <RegisterFeedback />
                        </Stack>
                    </Group>
                </Grid.Col>
                {!!referrer && (
                    <Grid.Col md={6} p={0}>
                        {!!mdAndLess ? (
                            <WelcomeCard />
                        ) : (
                            <Sticky>
                                {({ style, isSticky }) => (
                                    <WelcomeCard style={style} />
                                )}
                            </Sticky>
                        )}
                    </Grid.Col>
                )}
            </Grid>
        </StickyContainer>
    );
};

const RegisterAgent = (props) => {
    const { code } = useParams();

    const navigate = useNavigate();
    const [view, setView] = useState(formStep.REGISTER);
    const [verifyData, setVerifyData] = useState<any>(null);
    const [hasInvalidCode, setHasInvalidCode] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        data: referralCodeData,
        isLoading,
        error,
    } = useGetAgentFromReferral(code ?? searchParams.get("code") ?? "");

    useEffect(() => {
        if (!!referralCodeData && referralCodeData.length === 1 && !isLoading) {
            // setSearchParams(true);
        }
    }, [referralCodeData, isLoading]);

    const content = useMemo(() => {
        let referralObj = false;
        if (!!referralCodeData && referralCodeData.length === 1 && !isLoading) {
            referralObj = referralCodeData[0]
        }

        switch (view) {
            case formStep.REGISTER: {
                return (
                    <AgentForm
                        setView={setView}
                        verifyData={verifyData}
                        setVerifyData={setVerifyData}
                        referrer={referralObj.user}
                        {...props}
                    />
                );
            }
            case formStep.OTP: {
                return (
                    <RegisterOtp
                        verifyData={verifyData}
                        setView={setView}
                        referralObj={referralObj}
                        {...props}
                        skipOtp
                    />
                );
            }
        }
    }, [view, referralCodeData]);

    return (
        <Box p={0}>
            {/* <MaintainenceModal /> */}
            <Modal
                opened={hasInvalidCode}
                onClose={() => setHasInvalidCode(false)}
                title="Your referral link is not valid"
            >
                <Button onClick={() => navigate("/register/agent")}>OK</Button>
            </Modal>
            {content}
        </Box>
    );
};

export default RegisterAgent;
