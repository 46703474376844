import API from "../api/API";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { flattenObj } from "helpers/strapi";
const qs = require("qs");

export const useOtpAPI = () => {
    const useRequestOTP = () =>
        useMutation(async (payload: any) => {
            const resp = await API().post<
                any,
                any
            >(`code/request`, {
                data: { ...payload },
            });
            return flattenObj(resp);
        });
        
    const useVerifyOTP = () =>
        useMutation(async (payload: any) => {
            const resp = await API().post<
                any,
                any
            >(`code/verify`, {
                data: { ...payload },
            });
            return flattenObj(resp);
        });
    
    return {
        useRequestOTP,
        useVerifyOTP
    }
}