import React, {
    useEffect, useMemo
} from "react";
import {
    Box,
    Affix,
    Text,
    Container,
    Group,
    Button,
    Textarea,
    Select,
    PasswordInput,
    TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useProfileController } from "hooks/useProfile";
import { ListerDetail } from "types/userTypes";
import { SectionContainer } from "./SectionContainer";
import { EditInput } from "components/Inputs/EditInput";
import { EditFormImageInput } from "components/Inputs/EditFormImageInput";
import { updateUserPayload } from "api/updateUserAPI";
import { accountTypes } from "types/accountTypes";
import { useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { UpdateUserDetail } from "./UpdateUserDetail";
import { useGetConfigs } from "api/configAPI";
import { allCountries } from "country-region-data";
import { CONFIG_KEYS } from "helpers/constants";
import { DateInput } from "@mantine/dates";
import DeleteAccountButton from "./DeleteAccountButton";

type FormValues = {
    avatar: File | string | null
    fullname: string
    email: string
    phoneNumber: string
    nationality: string
    race: string
    maritalStatus: string
    noOfChildren: number | undefined,
    occupation: string
    remark: string
    password: string
}

const useListerFormController = () => {
    const {
        profileData,
        profileIsLoading,
        updateUserMutation,
    } = useProfileController();
    const navigate = useNavigate();
    const profile = profileData as ListerDetail
    const form = useForm<FormValues>({
        initialValues: {
            avatar: null,
            fullname: '',
            email: '',
            phoneNumber: '',
            nationality: '',
            race: '',
            maritalStatus: '',
            noOfChildren: undefined,
            occupation: '',
            remark: '',
            password: ''
        },
        validateInputOnChange: true,
        validate: values => ({
            fullname: !values.fullname ? 'Full name is required' : null,
            email: !values.email ? 'Email is required' : null,
            phoneNumber: !values.phoneNumber ? 'Phone number is required' : null,
        })
    })

    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    const allCountriesFormatted = useMemo(() => {
        if (!!allCountries) {
            return allCountries.map((c) => c[0]);
        }

        return [];
    }, [allCountries]);

    const allOccupations = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find((p: any) => p.key === CONFIG_KEYS.OCCUPATION)
                .value;
        }

        return [];
    }, [configData]);

    useEffect(() => {
        if (profile) {
            form.setValues({
                avatar: profile.user?.avatar?.formats?.thumbnail?.url,
                fullname: profile.user?.fullname,
                email: profile?.user?.email,
                phoneNumber: profile?.user?.phoneNumber,
                nationality: profile?.nationality || '',
                race: profile?.race || '',
                maritalStatus: profile?.maritalStatus || '',
                noOfChildren: profile?.noOfChildren || undefined,
                occupation: profile?.occupation || '',
                remark: profile?.remark || '',
            })
        }
    }, [profile])

    const onSubmit = (values: FormValues) => {
        // console.log('in onsubmit', values)
        if (profile?.user?.id) {
            const payload: updateUserPayload = {
                ...values,
                username: values.phoneNumber,
                userType: accountTypes.LISTER,
                id: profile.user.id,
                detailId: profile.id
            }
            updateUserMutation.mutate(payload, {
                onSuccess: (data) => {
                    navigate('/profile')
                },
                onError: (error: any) => {
                    const errorMessage = error?.response?.data?.error?.message;
                    const errorDetails = error?.response?.data?.error?.details;
                    if (errorDetails && Object.keys(errorDetails).length === 0) {
                        notifications.show({
                            title: "",
                            message: errorMessage || "",
                        });
                    }
                },
            })
        }
    }

    useEffect(() => {
        if (updateUserMutation.error) {
            // const error: axios
            // console.log('type', typeof updateUserMutation.error, updateUserMutation.error)
            const errors = (updateUserMutation.error as any)?.response?.data
                ?.error?.details?.errors;
            if (errors) {
                errors.forEach(({ path, message }: any) => {
                    // console.log(path[0], message);
                    if (path[0] === "username") {
                        form.setFieldError(
                            "phoneNumber",
                            message.replace("username", "phone number")
                        );
                    }
                    form.setFieldError(path[0], message);
                });
            }
        }
    }, [updateUserMutation.error]);

    return {
        form,
        profileIsLoading,
        onSubmit,
        updateUserMutation,
        allCountriesFormatted,
        allOccupations
    }
}

export const EditListerForm: React.FC<any> = ({
}) => {
    const {
        form,
        profileIsLoading,
        onSubmit,
        updateUserMutation,
        allCountriesFormatted,
        allOccupations
    } = useListerFormController();
    const navigate = useNavigate();
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Box
                pb={48}
            >
                <Container size={"800px"}>
                    <SectionContainer>
                        <Text size={20} weight={600}>Basic Info</Text>
                        <EditFormImageInput
                            {...form.getInputProps('avatar')}
                            label='Profile Picture'
                        />
                        <TextInput
                            {...form.getInputProps('fullname')}
                            label='Full Name'
                            required
                        />
                    </SectionContainer>
                    <SectionContainer>
                        <Text size={20} weight={600}>Contact Info</Text>
                        <TextInput
                            // disabled
                            {...form.getInputProps('email')}
                            label='Email Address'
                            required
                        />
                        <TextInput
                            disabled
                            {...form.getInputProps('phoneNumber')}
                            label='Phone Number'
                            required
                        />
                    </SectionContainer>
                    <SectionContainer>
                        <Text size={20} weight={600}>Security Info</Text>
                        <PasswordInput
                            label='Password'
                            {...form.getInputProps('password')}
                        />
                    </SectionContainer>
                    <Group w="100%" position="apart">
                        <Box
                            sx={theme => ({
                                display: 'flex',
                                justifyContent: 'flex-start',
                                gap: 12,
                                padding: '32px 0',
                                [theme.fn.smallerThan('xs')]: {
                                    padding: '16px 0'
                                }
                            })}
                        >
                            <Button
                                variant='outline'
                                onClick={() => navigate('/profile')}
                                sx={theme => ({
                                    [theme.fn.smallerThan('xs')]: {
                                        flexGrow: 1
                                    }
                                })}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                onClick={() => onSubmit(form.values)}
                                sx={theme => ({
                                    [theme.fn.smallerThan('xs')]: {
                                        flexGrow: 1
                                    }
                                })}
                            >
                                Save Changes
                            </Button>
                        </Box>
                        <DeleteAccountButton />
                    </Group>
                </Container>
                <Container size={"800px"}>
                    <SectionContainer>
                        <Text size={20} weight={600}>Additional Info</Text>
                        <UpdateUserDetail plainForm />
                    </SectionContainer>
                </Container>
            </Box>
        </form>
    )
}