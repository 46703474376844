import {
    Container,
    Title,
    Text,
    UnstyledButton,
    Box,
    Button,
    PinInput,
    Group,
    useMantineTheme,
    Loader,
    TextInput,
    Center,
    Stack,
    Image,
} from "@mantine/core";
import { useCounter, useInterval } from "@mantine/hooks";
import API from "api/API";
import axios from "axios";
import { USER_ACTIONS } from "context/user/userActions";
import { UserContext } from "context/user/userContext";
import { USER_TYPE, formStep } from "helpers/constants";
import { Permission, useAuth } from "hooks/useAuth";
// import { useMediaQuery } from "@mantine/hooks";
import React, { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { useMutation } from "react-query";
import { redirect, useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { accountTypes } from "types/accountTypes";
import { notifications } from "@mantine/notifications";
import { usePromoAPI } from "api/usePromoAPI";
import { useCreateRequest } from "./PropRequestForm/hooks/useCreateRequest";
import usePostLoginFunctions from "hooks/usePostLoginFunctions";
import { useGetAgentFromReferral } from "api/userAPI";
import { useOtpAPI } from "api/useOtpAPI";

const RESEND_TIMEOUT = 30;

const useOtpController = ({ verifyData, referralObj, nextStep}: any) => {
    const { code: customCode } = useParams();
    const navigate = useNavigate();
    const { user, userDispatch, registerMutation, requestOtpMutation } =
        useAuth(Permission.GUEST_ONLY);

    const [searchParams, setSearchParams] = useSearchParams();
    const {
        data: referralCodeData,
        isLoading,
        error,
    } = useGetAgentFromReferral(customCode ?? searchParams.get("code") ?? "");

    const [code, setCode] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [show, setShow] = useState(false);
    const [resendTimer, timerHandlers] = useCounter(0);
    const countdown = useInterval(() => timerHandlers.decrement(), 1000);
    const [requestId, setRequestId] = useState("");
    const { useJoinPromo } = usePromoAPI()
    const joinPromoMutation = useJoinPromo()
    const { useVerifyOTP } = useOtpAPI()
    const verifyOTP = useVerifyOTP()

    const registerForPromo = async (userId: string) => {
        let promoId = sessionStorage.getItem("promo_id")

        if (!!promoId) {
            await joinPromoMutation.mutateAsync({
                userId,
                id: promoId
            })

            sessionStorage.removeItem("promo_id")
        }
    }

    const referralCode = useMemo(() => {
        if (!isLoading && !!referralCodeData && referralCodeData.length === 1) {
            return referralCodeData[0]
        }

        return false
    }, [referralCodeData, isLoading])

    const handleRegistration = () => {
        let payload = {} as any;
        if (verifyData.userType === accountTypes.LISTER) {
            payload = {
                fullname: verifyData.fullname,
                email: verifyData.email,
                phoneNumber: verifyData?.phoneNumber,
                username: `+${verifyData?.phoneNumber}`,
                password: verifyData.password,
                userType: accountTypes.LISTER,
                from: verifyData.from,
                nonLocal: verifyData.nonLocal
            };
        } else {
            payload = {
                fullname: verifyData.fullname,
                email: verifyData.email,
                password: verifyData.password,
                phoneNumber: verifyData?.phoneNumber,
                username: `+${verifyData?.phoneNumber}`,
                renId: verifyData.renId,
                renImg: verifyData.renImg,
                agencyName: verifyData.agencyName,
                userType: accountTypes.AGENT,
                preferredName: verifyData.preferredName,
                from: verifyData.from
            };

            if (!!referralCode) {
                payload.referredBy = referralCode;
            }
        }
        console.log({ payload })
        registerMutation.mutate(payload, {
            onSuccess: (data) => {
                notifications.show({
                    title: data?.user.email,
                    message: "Account has been created.",
                });
                userDispatch({
                    type: USER_ACTIONS.SET,
                    payload: {
                        jwt: data.jwt,
                        refreshToken: data?.refreshToken ?? "",
                        blocked: data.user?.blocked,
                        confirmed: data.user?.confirmed,
                        createdAt: new Date(data.user?.createdAt),
                        email: data.user?.email,
                        id: data.user?.id,
                        provider: data.user?.provider,
                        updatedAt: new Date(data.user?.updatedAt),
                        username: data.user?.username,
                        userType: data.user?.userType,
                        checkoutId: data.user?.checkoutId,
                        fullname: data.user?.fullname,
                        status: data.user?.status,
                        loaded: true,
                    },
                });

                registerForPromo(data.user.id)

                if (data.user?.userType === USER_TYPE.AGENT) {
                    if(!!nextStep){
                        nextStep()
                    }else{
                        navigate("/profile", {
                            state: {
                                fromRegister: true
                            }
                        });
                    }
                }

                if (data.user?.userType === USER_TYPE.LISTER) {
                    if(!!nextStep){
                        nextStep()
                    }else{
                        navigate("/profile", {
                            state: {
                                fromRegister:
                                    verifyData.userType ===
                                    USER_TYPE.LISTER,
                            },
                        });
                    }
                }
            },
        });
    }


    const verifyOtpMutation = useMutation({
        mutationFn: (data: any) => API({}).post("/otp/verify", data),
        onSuccess: (data) => {
            // console.log("otp verify succ", data);
            if (data.data.status !== "0") {
                setErrorMsg(`The code you've entered is invalid`);
            } else {
                handleRegistration()
            }
        },
    });

    const verifyCustomOtp = (code: string) => {
        verifyOTP.mutate({
            otp: code,
            username: verifyData.username
        },{
            onSuccess: (data) => {
                console.log("verfied",data)
                handleRegistration()
            },
            onError: (error) => {
                setErrorMsg(`The code you've entered is invalid or has expired`);
            },
        })
    }

    const cancelOtpMutation = useMutation({
        mutationFn: (data: any) => API({}).post("/otp/cancel", data),
    });

    const cancelOtp = () =>
        cancelOtpMutation.mutate(
            { requestId },
            {
                onSuccess: (data) => {
                    requestOtpMutation.mutate(
                        { phoneNumber: verifyData?.phoneNumber },
                        {
                            onSuccess: (data) => {
                                timerHandlers.set(RESEND_TIMEOUT);
                                setRequestId(data.data.requestId);
                                setShow(true);
                                if (!countdown.active) {
                                    setTimeout(() => {
                                        countdown.start();
                                    }, 1000);
                                }
                            },
                        }
                    );
                },
            }
        );

    // const onRequest = (phoneNumber: string) => {
    //     requestOtpMutation.mutate({ phoneNumber }, {
    //         onSuccess: (data) => {
    //             setRequestId(data.data.requestId)
    //             timerHandlers.set(RESEND_TIMEOUT)
    //             setShow(true)
    //             setTimeout(() => {
    //                 countdown.start()
    //             }, 1000);
    //         }
    //     })
    // }

    const onVerify = useCallback(() => {
        
        if (!!process.env.REACT_APP_IS_DEV) {
            handleRegistration()
        } else {

            if(!!verifyData.nonLocal){
                verifyCustomOtp(code)
            }else{
                const payload = {
                    requestId,
                    code,
                };
    
                verifyOtpMutation.mutate(payload);
            }
        }
    },[verifyData, code])

    const onCancel = () => {
        const payload = { requestId };
        cancelOtpMutation.mutate(payload);
    };

    // useEffect(() => {
    //     console.log(verifyData)
    //     console.log('otp ph', verifyData?.phoneNumber)
    // },[verifyData])

    // useEffect(() => {
    //     if (!requestOtpMutation.data && !requestOtpMutation.isLoading) {
    //         onRequest(verifyData?.phoneNumber)
    //     }
    // },[verifyData?.phoneNumber, requestOtpMutation])

    useEffect(() => {
        if (show && resendTimer === 0) {
            setTimeout(() => {
                setShow(false);
            }, 1000);
        }
    }, [show, resendTimer]);

    // useEffect(() => {
    //     if (requestOtpMutation.data) {
    //         console.log('OTP DATA', requestOtpMutation.data)
    //     }
    // },[requestOtpMutation])
    useEffect(() => {
        if (verifyData) {
            timerHandlers.set(RESEND_TIMEOUT);
            setRequestId(verifyData.requestId);
            setShow(true);
            if (!countdown.active) {
                setTimeout(() => {
                    countdown.start();
                }, 1000);
            }
        }
    }, [verifyData]);

    return {
        requestOtpMutation,
        onVerify,
        onCancel,
        code,
        setCode,
        errorMsg,
        setErrorMsg,
        verifyOtpMutation,
        resendTimer,
        show,
        registerMutation,
        cancelOtp,
        handleRegistration,
    };
};

export const RegisterOtp: React.FC<any> = ({
    verifyData,
    setView,
    referralCode,
    skipOtp,
    nextStep
}) => {
    const navigate = useNavigate();
    const {
        requestOtpMutation,
        onCancel,
        onVerify,
        code,
        setCode,
        verifyOtpMutation,
        errorMsg,
        setErrorMsg,
        resendTimer,
        show,
        registerMutation,
        cancelOtp,
        handleRegistration,
    } = useOtpController({
        verifyData,
        referralCode,
        nextStep
    });

    useEffect(() => {
        console.log("logged")
        if (!!skipOtp) {
            handleRegistration()
        }
    }, [skipOtp])

    if (!!skipOtp) {
        return (
            <Center sx={{ minHeight: '100vh' }}>
                <Stack align="center">
                    {/* <Group spacing={8}>
                    </Group> */}
                    <Image
                        src={"/logo.svg"}
                        alt={"propmoth logo"}
                        width={148}
                        height={48}
                    />
                    <Text fz={36} fw={'bold'}>Registering...</Text>
                    <Loader />
                </Stack>
            </Center>
        )
    }

    return (
        <Container size="800px">
            <Title order={1} align="center" py={24}>
                OTP Verification
            </Title>
            <Text color="primaryGreen" size={18}>
                {verifyData?.nonLocal ? `Please enter the 6-digit code we sent to ${verifyData?.email}.` : `Please enter the 4-digit code we sent over SMS to ${verifyData?.phoneNumber}.`}
            </Text>
            <PinInput
                length={verifyData.nonLocal ? 6 : 4}
                mt={24}
                type="number"
                placeholder=""
                size="xl"
                oneTimeCode // to enable auto complete
                autoFocus
                value={code}
                onChange={(x) => {
                    setCode(x);
                    setErrorMsg("");
                }}
                onComplete={onVerify}
                disabled={
                    verifyOtpMutation.isLoading || registerMutation.isLoading
                }
                error={!!errorMsg}
                sx={{
                    input: {
                        height: 80,
                        width: 80,
                        fontSize: "48px",
                    },
                }}
            />
            {errorMsg && (
                <Text color="red" size={14}>
                    {errorMsg}
                </Text>
            )}
            {/* <Button onClick={onVerify}>
                verify
            </Button>
            <Button onClick={onCancel}>
                cancel
            </Button> */}
            <Box
                sx={{
                    display: "flex",
                    gap: 4,
                    alignItems: "center",
                }}
                mt={8}
            >
                <Text color="primaryGreen" size={14}>
                    {verifyData?.nonLocal ? "Didn't get an Email?" : "Didn't get an SMS?"}
                </Text>
                {requestOtpMutation.isLoading ? (
                    <Loader size={16} />
                ) : show ? (
                    <Text color="primaryGreen" size={14}>
                        {moment.utc(resendTimer * 1000).format("mm:ss")}
                    </Text>
                ) : (
                    <UnstyledButton onClick={cancelOtp}>
                        <Text
                            color="primaryGreen.7"
                            underline
                            size={14}
                            weight={500}
                        >
                            Send again
                        </Text>
                    </UnstyledButton>
                )}
            </Box>
            <UnstyledButton onClick={() => setView(formStep.REGISTER)}>
                <Text color="primaryGreen" weight={500} size={14}>
                    Try a different phone number.
                </Text>
            </UnstyledButton>
            <Box
                sx={{
                    position: "sticky",
                    bottom: 0,
                }}
            >
                <Container size="1200px">
                    <Group position="right" py={32}>
                        <Button onClick={() => navigate("/")} variant="outline">
                            Cancel
                        </Button>
                        <Button
                            disabled={
                                verifyOtpMutation.isLoading ||
                                registerMutation.isLoading ||
                                !!errorMsg ||
                                code.length < 4
                            }
                            loading={
                                verifyOtpMutation.isLoading ||
                                registerMutation.isLoading
                            }
                            onClick={onVerify}
                        >
                            Verify
                        </Button>
                    </Group>
                </Container>
            </Box>
        </Container>
    );
};
