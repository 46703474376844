import API from "../api/API";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { flattenObj } from "helpers/strapi";
import { Permission, useAuth } from "hooks/useAuth";
const qs = require("qs");

export const useDeveloperAPI = () => {
    const { user } = useAuth(Permission.USER_ONLY);

    const getOneDeveloper = async (id: string) => {
        try {
            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`developers/${id}?populate=*`);

            return flattenObj(response.data);
        } catch (e) {
            return e;
        }
    };

    const useGetOneDeveloper = (id: string) =>
        useQuery(["GET_ONE_DEVELOPER", id], () => getOneDeveloper(id), {
            enabled: !!id,
        });

    const getOneDeveloperBySlug = async (slug: string) => {
        try {
            const query = qs.stringify(
                {
                    populate: "*",
                    filters: {
                        slug: {
                            $eq: slug,
                        },
                    },
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );

            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`developers?${query}`);

            return flattenObj(response.data);
        } catch (e) {
            return e;
        }
    };

    const useGetOneDeveloperBySlug = (slug: string) =>
        useQuery(
            ["GET_ONE_DEVELOPER_BY_SLUG", slug],
            () => getOneDeveloperBySlug(slug),
            {
                enabled: !!slug,
            }
        );

    const useUpdateDeveloper = () =>
        useMutation(async (payload: any) => {
            const resp = await API({ requireAuth: true, token: user.jwt }).put<
                any,
                any
            >(`developers/${payload.id}`, {
                data: { ...payload },
            });
            return flattenObj(resp);
        });

    return {
        useGetOneDeveloper,
        useGetOneDeveloperBySlug,
        useUpdateDeveloper,
    };
};
