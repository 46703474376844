import { Center, Grid, Image, Text } from '@mantine/core';
import React from 'react';

const PartneredAgencies = () => {
    const logos = [
        {
            title: "MLP",
            img: "/mlp.png",
        },
        {
            title: "propnex",
            img: "/propnex.png",
        },
        {
            title: "kith and kin",
            img: "/kith-and-kin.jpeg",
        },
        {
            title: "chester property",
            img: "/chester.png",
        },
    ];
    return (
        <>
            <Text
                component="h1"
                sx={(theme) => ({
                    fontSize: "1.5em",
                    lineHeight: 1,
                    [theme.fn.smallerThan("xs")]: {
                        textAlign: "center",
                    },
                })}
                align="center"
            >
                Partnered Teams and Agencies
            </Text>
            <Grid gutter={24} justify="center">
                {logos.map((logo) => (
                    <Grid.Col xs={12} sm={6} md={3}>
                        <Center sx={{ height: "100%" }}>
                            <Image
                                src={logo.img}
                                alt={logo.title}
                                fit="contain"
                                width={150}
                            />
                        </Center>
                    </Grid.Col>
                ))}
            </Grid>
        </>
    );
};

export default PartneredAgencies;