import { BackgroundImage, Badge, Box, Button, Card, Flex, Group, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const AgencyCard = ({
    agency,
    onLearnMore,
    onApply,
    disableLearnMore,
    isApplied
}: {
    agency: any,
    onLearnMore: any,
    onApply: any,
    disableLearnMore?: boolean,
    isApplied?: boolean,
}) => {
    const theme = useMantineTheme()
    const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

    return (
        <Card withBorder shadow="md" radius={16}>
            <Stack>
                <Flex
                    gap={16}
                    sx={{
                        flexDirection: isMd ? "column" : "row"
                    }}
                >
                    <Box p={8} sx={{ border: "1px solid #ddd", borderRadius: 8 }}>
                        <BackgroundImage src={agency.logo?.url} h="128px" w="128px" sx={{ backgroundSize: "contain", backgroundRepeat: "no-repeat" }} />
                    </Box>
                    <Stack sx={{ flex: 1 }}>
                        <Stack spacing={8} sx={{ flex: 1 }}>
                            <Title order={3}>{agency.name}</Title>
                            <Text>{agency.size}</Text>
                            {agency.state && (<Text c="#2e523c" fw="bold">{agency.state}</Text>)}
                        </Stack>
                        {agency.tags && (
                            <Group
                                sx={{ flex: 1, width: "100%", alignItems: "flex-start", justifyContent: "flex-start" }}
                            >{agency.tags.map((t: string) => (<Badge variant='outline'>{t}</Badge>))}</Group>
                        )}
                    </Stack>
                </Flex>
                <Stack>
                    <Text>{agency.description}</Text>
                    <Button onClick={() => onLearnMore()}>Learn More</Button>
                    <Button
                        onClick={() => onApply(agency)}
                        disabled={!!isApplied}
                    >
                        {!!isApplied ? (
                            <Text>Applied</Text>
                        ):(
                            <Text>Apply</Text>
                        )}
                    </Button>
                </Stack>
            </Stack>
        </Card>
    );
};

export default AgencyCard;