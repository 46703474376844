import {
    Badge,
    Box,
    Button,
    Card,
    Center,
    Container,
    Grid,
    Group,
    Loader,
    Stack,
    Tabs,
    Image,
    Text,
    Flex,
    Avatar,
    AspectRatio
} from "@mantine/core";
import { PROPERTY_REQUEST_COLOR_SETTINGS } from "helpers/constants";
import { Bath, Bed, Edit } from "tabler-icons-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { formatListingType } from "helpers/utility";

export default function ListingCard({ ...props }) {
    const navigate = useNavigate();
    const listingData = props.data as any;
    
    const transactionType = props.data.transactionType as
        | "BUY"
        | "RENT"
        | "INVEST";
    const tagArr =
        !!listingData.tags && listingData.tags.length > 0
            ? listingData.tags.map((t: any) => (
                <Badge
                    sx={{
                        background: t.tag?.badgeBackground ?? "#00A550",
                        color: t.tag?.badgeColor ?? "#FFFFFF",
                    }}
                >
                    {t.value}
                </Badge>
            ))
            : [];

    const mergedImages = useCallback(() => {
        let mergedArr = [] as any
        if (!!listingData?.images && listingData.images.length > 0) {
            const imageArr = listingData.images.map((i: any) => ({
                src: i?.url ?? "/img-placeholder.jpeg",
                alt: "offer-image",
            }));
            mergedArr = [...mergedArr, ...imageArr]
        }

        if (!!listingData?.imageLinks && listingData.imageLinks.length > 0) {
            const imageArr = listingData.imageLinks.map((i: any) => ({
                src: i,
                alt: "offer-image",
            }))
            mergedArr = [...mergedArr, ...imageArr]
        }

        return mergedArr
    }, [listingData])
    // const imageArr = listingData.images.map((i: any) => ({
    //     src: i.url,
    //     alt: "listing-image",
    // }));

    return (
        <Card
            sx={{
                width: "100%",
                border: "1px solid #BFC8C3",
                cursor: "pointer",
            }}
            shadow="sm"
            onClick={() => navigate(`/listings/${props.data.id}`)}
        >
            <Grid>
                {listingData.status === "draft" && (
                    <Grid.Col xs={12}>
                        <Badge color="dark">DRAFT</Badge>
                    </Grid.Col>
                )}
                <Grid.Col xs={props.vertical ? 12 : 3}>
                    <Center sx={{ height: "100%" }}>
                        <AspectRatio ratio={180 / 180} w={'100%'}>
                            <Image
                                width={"100%"}
                                height={"100%"}
                                fit="cover"
                                src={mergedImages()[0]?.src}
                            />
                        </AspectRatio>
                    </Center>
                </Grid.Col>
                <Grid.Col xs={props.vertical ? 12 : 9}>
                    <Stack spacing={8}>
                        <Group position="apart">
                            <Group spacing={8}>
                                <Badge
                                    sx={{
                                        color: PROPERTY_REQUEST_COLOR_SETTINGS[
                                            transactionType
                                        ].color,
                                        backgroundColor:
                                            PROPERTY_REQUEST_COLOR_SETTINGS[
                                                transactionType
                                            ].background,
                                    }}
                                >
                                    {formatListingType(transactionType)}
                                </Badge>

                                {!!listingData.isDeveloperListing && (
                                    <Badge color="orange" variant="filled">
                                        Developer Listing
                                    </Badge>
                                )}
                            </Group>
                            {/* <Edit /> */}
                        </Group>
                        {!!listingData.isAuction && (
                            <Stack sx={{ border: "1px solid #417256", padding: 8, borderRadius: 8 }}>
                                <Badge>
                                    Auction
                                </Badge>
                                <Group>
                                    <Text ta="center" sx={{ flex: 1 }}>
                                        <Text component="span" fw="bold">Start Date:</Text> {listingData.auctionStart ? moment(listingData.auctionStart).format("DD/MM/YYYY HH:mm") : "TBD"}
                                    </Text>
                                    <Text ta="center" sx={{ flex: 1 }}>
                                        <Text component="span" fw="bold">End Date:</Text> {listingData.auctionEnd ? moment(listingData.auctionEnd).format("DD/MM/YYYY HH:mm") : "TBD"}
                                    </Text>
                                </Group>
                            </Stack>
                        )}
                        <Stack spacing={16}>
                            <Group position="apart">
                                <Stack spacing={4} maw={400}>
                                    <Text fw={700}>{listingData.title}</Text>
                                    <Text fw={500} lineClamp={2}>{listingData.address}</Text>
                                    <Text fw={500}>
                                        {`${listingData.area}, ${listingData.state}`}
                                    </Text>
                                </Stack>
                                <Text fw={700} fz={20} align="right">
                                    RM
                                    {parseInt(
                                        listingData.price
                                    ).toLocaleString()}
                                </Text>
                            </Group>
                            <Group position="apart">
                                <Stack spacing={4}>
                                    <Group spacing={8}>
                                        <Text>
                                            {listingData.propertyType} |{" "}
                                            {listingData.tenure}
                                        </Text>
                                        <Group spacing={8}>
                                            <Text>
                                                {listingData.floorSize} sqft
                                            </Text>
                                        </Group>
                                    </Group>
                                    <Group spacing={8}>
                                        <Group spacing={4}>
                                            <Text>
                                                {listingData.noOfBedrooms}
                                            </Text>
                                            <Bed />
                                        </Group>
                                        <Group spacing={4}>
                                            <Text>
                                                {listingData.noOfBathrooms}
                                            </Text>
                                            <Bath />
                                        </Group>
                                    </Group>
                                </Stack>
                            </Group>
                            <Flex
                                sx={{
                                    flexDirection: props.vertical
                                        ? "column"
                                        : "row",
                                    gap: 16,
                                }}
                            >
                                <Box sx={{ flex: 1 }}>
                                    <Group>{tagArr}</Group>
                                </Box>
                                <Button
                                    onClick={() =>
                                        navigate(`/offer/${props.data.id}`)
                                    }
                                >
                                    View listing
                                </Button>
                            </Flex>
                        </Stack>
                    </Stack>
                </Grid.Col>
            </Grid>
        </Card>
    );
}
