import API from "../api/API";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { limit } from "firebase/firestore";
import { flattenObj, flattenWithMeta } from "helpers/strapi";
import { Permission, useAuth } from "hooks/useAuth";
const qs = require("qs");

// keys
export const GET_TAGS = "GET_TAGS";
export const GET_MY_REQUESTS = "GET_MY_REQUESTS";
export const GET_MY_SINGLE_REQUESTS = "GET_MY_SINGLE_REQUESTS";
export const SEARCH_REQUESTS = "SEARCH_REQUESTS";
export const LATEST_REQUESTS = "LATEST_REQUESTS";
export const SEARCH_COBROKE = "SEARCH_COBROKE";
export const SEARCH_LISTINGS = "SEARCH_LISTINGS";
export const GET_LANDING_LISTINGS = "GET_LANDING_LISTINGS";
export const GET_ONE_LISTING = "GET_ONE_LISTING";
export const GET_ONE_REQUEST = "GET_ONE_REQUEST";
export const GET_OFFERS_BY_AGENT = "GET_OFFERS_BY_AGENT";
export const GET_LISTINGS_BY_AGENT = "GET_LISTINGS_BY_AGENT";
export const GET_ONE_OFFER = "GET_ONE_OFFER";
export const GET_OFFERS_BY_REQUEST = "GET_OFFERS_BY_REQUEST";
export const GET_INSIGHTS = "GET_INSIGHTS";
export const GET_LISTING_INSIGHTS = "GET_LISTING_INSIGHTS";

export const useListingAPI = () => {
    const { user } = useAuth(Permission.USER_ONLY);

    async function getTags() {
        const response = await API({}).get<any, any>(`tags`);

        return flattenObj(response.data);
    }

    async function getMyPropRequests(id: string) {
        const query = qs.stringify(
            {
                populate: {
                    tags: {
                        populate: ["tag"],
                    },
                    lister: true,
                    offers: {
                        populate: ["listing"],
                    },
                },
                filters: {
                    lister: {
                        id: {
                            $eq: id,
                        },
                    },
                    status: {
                        $notIn: ["DELETED"],
                    },
                },
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );
        try {
            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`property-requests?${query}`);

            return flattenObj(response.data);
        } catch (e) {
            return e;
        }
    }

    async function getMySinglePropRequest(id: string) {
        const query = qs.stringify(
            {
                populate: {
                    tags: {
                        populate: ["tag"],
                    },
                    lister: true,
                    offers: {
                        populate: ["listing"],
                    },
                },
                filters: {
                    lister: {
                        id: {
                            $eq: id,
                        },
                    },
                    offers: {
                        listing: {
                            status: {
                                $notIn: ["hidden", "rejected", "draft"],
                            },
                        },
                    },
                },
                limit: 1,
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );
        try {
            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`property-requests?${query}`);

            return flattenObj(response.data);
        } catch (e) {
            return e;
        }
    }

    async function getListings(payload: any) {
        const query = qs.stringify(
            {
                populate: {
                    images: true,
                    tags: {
                        populate: ["tag"],
                    },
                    agent: true,
                },
                filters: {
                    ...payload.filters,
                    status: {
                        $notIn: ["hidden", "rejected", "draft"],
                    },
                },
                pagination: {
                    ...payload.pagination,
                },
                sort: ["createdAt:desc"],
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );
        try {
            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`listings?${query}`);

            return {
                data: flattenObj(response.data.data),
                meta: response.data.meta,
            };
        } catch (e) {
            return e;
        }
    }

    async function getLandingListings() {
        const query = qs.stringify(
            {
                populate: {
                    images: true,
                    tags: {
                        populate: ["tag"],
                    },
                    agent: true,
                },
                filters: {
                    status: {
                        $notIn: ["rejected", "deleted", "draft"],
                    },
                    // images: {
                    //     url : {
                    //         $contains: "propmoth-assets"
                    //     }
                    // }
                },
                sort: ["createdAt:desc"],
                pagination: {
                    pageSize: 10,
                },
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );
        try {
            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`listings?${query}`);

            return {
                data: flattenObj(response.data.data),
                meta: response.data.meta,
            };
        } catch (e) {
            return e;
        }
    }

    async function getSearchPropRequests(payload: any) {
        const query = qs.stringify(
            {
                populate: {
                    tags: {
                        populate: ["tag"],
                    },
                    lister: true,
                    offers: {
                        populate: {
                            listing: {
                                populate: {
                                    images: true,
                                    agent: true,
                                    tags: {
                                        populate: ["tag"],
                                    },
                                },
                            },
                            propertyRequest: true,
                        },
                    },
                },
                filters: {
                    ...payload.filters,
                    $or: [
                        {
                            isCobroke: {
                                $eq: false,
                            },
                        },
                        {
                            isCobroke: {
                                $null: true,
                            },
                        },
                    ],
                },
                pagination: {
                    ...payload.pagination,
                },
                sort: ["createdAt:desc"]
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );

        try {
            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`property-requests?${query}`);

            return {
                data: flattenObj(response.data.data),
                meta: response.data.meta,
            };
        } catch (e) {
            return e;
        }
    }

    async function getSearchCobrokeRequests(payload: any) {
        const query = qs.stringify(
            {
                populate: {
                    tags: {
                        populate: ["tag"],
                    },
                    lister: true,
                    offers: {
                        populate: {
                            listing: {
                                populate: {
                                    images: true,
                                    agent: true,
                                    tags: {
                                        populate: ["tag"],
                                    },
                                },
                            },
                            propertyRequest: true,
                        },
                    },
                },
                filters: {
                    ...payload.filters,
                    isCobroke: {
                        $eq: true,
                    },
                },
                pagination: {
                    ...payload.pagination,
                },
                sort: ["createdAt:desc"],
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );

        try {
            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`property-requests?${query}`);

            return {
                data: flattenObj(response.data.data),
                meta: response.data.meta,
            };
        } catch (e) {
            return e;
        }
    }

    async function getOnePropRequests(id: string) {
        try {
            const query = qs.stringify(
                {
                    populate: {
                        tags: {
                            populate: ["tag"],
                        },
                        lister: true,
                        offers: {
                            populate: {
                                listing: {
                                    populate: {
                                        images: true,
                                        agent: {
                                            populate: ["avatar"],
                                        },
                                        tags: {
                                            populate: ["tag"],
                                        },
                                    },
                                },
                                propertyRequest: true,
                            },
                        },
                    },
                    filters: {
                        offers: {
                            listing: {
                                status: {
                                    $notIn: ["hidden", "rejected", "draft"],
                                },
                            },
                        },
                    },
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );

            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`property-requests/${id}?${query}`);

            return flattenObj(response.data);
        } catch (e) {
            return e;
        }
    }

    const createPropRequest = async (payload: {
        tenure: string;
        propertyTitleType: string;
        transactionType: string;
        isFirstTime: boolean;
        subSalesOk: boolean;
        newProjectsOnly: boolean;
        state: string;
        area: string;
        postcode: string;
        propertyType: string;
        budgetMin: number | string;
        budgetMax: number | string;
        incomeRange?: string;
        noOfBedrooms: number | string;
        noOfBathrooms: number | string;
        minSquareFt: number | string;
        maxSquareFt: number | string;
        additionalNotes: string;
        lister: number;
    }) => {
        let parsedPayload = payload as any;

        if (!!parsedPayload.incomeRange) {
            const [min, max] = parsedPayload?.incomeRange
                ?.replace(/[^\d-]/g, "")
                .split("-")
                .map((value: any) => parseInt(value.trim()));

            parsedPayload.minIncomeRange = min;
            parsedPayload.maxIncomeRange = max;
        }

        const resp = await API({ requireAuth: true, token: user.jwt }).post<
            any,
            any
        >(`property-requests`, {
            data: { ...parsedPayload },
        });

        return flattenObj(resp);
    };

    const scrape = async (payload: any) => {
        const resp = await API({}).post<any, any>(`scrape`, {
            link: payload.link,
        });

        return flattenObj(resp);
    };

    const updatePropRequest = async (payload: any) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).put<
            any,
            any
        >(`property-requests/${payload.id}`, {
            data: { ...payload },
        });

        return flattenObj(resp);
    };

    const fulfillPropRequest = async (payload: any) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).post<
            any,
            any
        >(`offer/accept`, {
            id: payload.id,
        });

        return flattenObj(resp);
    };

    const deletePropRequest = async (payload: any) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).delete<
            any,
            any
        >(`property-requests/${payload.id}`, {
            data: { ...payload },
        });

        return flattenObj(resp);
    };

    const updateListing = async (payload: any) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).put<
            any,
            any
        >(`listings/${payload.id}`, {
            data: { ...payload },
        });

        return flattenObj(resp);
    };

    const hideListing = async (payload: any) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).put<
            any,
            any
        >(`listings/${payload.id}`, {
            data: {
                status: "hidden",
            },
        });

        return flattenObj(resp);
    };

    const createListingTag = async (payload: {
        tag: number;
        propertyRequest: number;
        value: string;
    }) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).post<
            any,
            any
        >(`listing-tags`, {
            data: { ...payload },
        });

        return flattenObj(resp);
    };

    const createRequestTag = async (payload: {
        tag: number;
        propertyRequest: number;
        value: string;
    }) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).post<
            any,
            any
        >(`request-tags`, {
            data: { ...payload },
        });

        return flattenObj(resp);
    };

    const createOffer = async (payload: {
        listing: number;
        propertyRequest: number;
    }) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).post<
            any,
            any
        >(`offers`, {
            data: { ...payload },
        });

        return flattenObj(resp);
    };

    const updateListingTags = async (payload: {
        listingId: string;
        tags: any[];
    }) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).post<
            any,
            any
        >(`listings/tags`, {
            data: { ...payload },
        });

        return flattenObj(resp);
    };

    const updatePropertyRequestTags = async (payload: {
        propertyRequestId: string;
        tags: any[];
    }) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).post<
            any,
            any
        >(`property-requests/tags`, {
            data: { ...payload },
        });

        return flattenObj(resp);
    };

    const createListing = async (payload: any) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).post<
            any,
            any
        >(`listings`, {
            data: {
                ...payload,
            },
        });

        return flattenObj(resp);
    };

    const createListingAndOffer = async (payload: {
        title: string;
        importLink: string;
        propertyType: string;
        price: number | string;
        noOfBedrooms: number | string;
        noOfBathrooms: number | string;
        floorSize: number | string;
        landSize: number | string;
        propertyTitleType: string;
        tenure: string;
        developer: string;
        address: string;
        propertyRequest: number;
        tags: {
            tagId: number;
            value: string;
        }[];
        media?: {
            type: string;
            link: string;
        }[];
        imageLinks: any;
        status: string;
        pitch: string;
        // uploads: number | string[];
    }) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).post<
            any,
            any
        >(`listings/offer`, {
            data: {
                ...payload,
            },
        });

        return flattenObj(resp);
    };

    const seenOffer = async (payload: { id: string }) => {
        const resp = await API({ requireAuth: true, token: user.jwt }).put<
            any,
            any
        >(`offers/${payload.id}`, {
            data: {
                seen: true,
            },
        });

        return flattenObj(resp);
    };

    async function getMyListings(payload: any) {
        const { id, pagination } = payload;
        try {
            const query = qs.stringify(
                {
                    populate: {
                        tags: {
                            populate: ["tag"],
                        },
                        images: true,
                        agent: true,
                    },
                    filters: {
                        agent: {
                            id: {
                                $eq: id,
                            },
                        },
                        status: {
                            $notIn: ["rejected", "deleted"],
                        },
                        ...payload.filters,
                    },
                    pagination,
                    sort: ["createdAt:DESC"],
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );

            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`listings?${query}`);

            return flattenWithMeta(response);
        } catch (e) {
            return e;
        }
    }

    async function getMyOffers(payload: any) {
        try {
            const query = qs.stringify(
                {
                    populate: {
                        propertyRequest: {
                            populate: {
                                tags: {
                                    populate: ["tag"],
                                },
                                lister: true,
                            },
                        },
                        listing: {
                            populate: {
                                tags: {
                                    populate: ["tag"],
                                },
                                images: true,
                                agent: true,
                            },
                        },
                    },
                    filters: {
                        listing: {
                            agent: {
                                id: {
                                    $eq: payload.id,
                                },
                            },
                            status: {
                                $notIn: ["rejected", "draft"],
                            },
                            ...payload.filters,
                        },
                    },
                    pagination: payload.pagination,
                    sort: ["createdAt:DESC"],
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );

            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`offers?${query}`);

            return flattenWithMeta(response);
        } catch (e) {
            return e;
        }
    }

    async function getOfferByRequest(id: string) {
        try {
            const query = qs.stringify(
                {
                    populate: {
                        propertyRequest: {
                            populate: {
                                tags: {
                                    populate: ["tag"],
                                },
                                lister: true,
                            },
                        },
                        listing: {
                            populate: {
                                tags: {
                                    populate: ["tag"],
                                },
                                images: true,
                                agent: true,
                                listingEmbeds: true,
                            },
                        },
                    },
                    filters: {
                        propertyRequest: {
                            id: {
                                $eq: id,
                            },
                        },
                        listing: {
                            status: {
                                $notIn: ["rejected", "draft"],
                            },
                        },
                    },
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );

            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`offers?${query}`);

            return flattenObj(response.data);
        } catch (e) {
            return e;
        }
    }

    async function getOneOffer(id: string) {
        try {
            const query = qs.stringify(
                {
                    populate: {
                        propertyRequest: {
                            populate: {
                                tags: {
                                    populate: ["tag"],
                                },
                                lister: true,
                            },
                        },
                        listing: {
                            populate: {
                                tags: {
                                    populate: ["tag"],
                                },
                                images: true,
                                agent: true,
                                listingEmbeds: true,
                            },
                        },
                    },
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );

            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`offers/${id}?${query}`);

            return flattenObj(response.data);
        } catch (e) {
            return e;
        }
    }

    async function getOneListing(id: string) {
        try {
            const query = qs.stringify(
                {
                    populate: {
                        tags: {
                            populate: ["tag"],
                        },
                        images: true,
                        agent: true,
                        listingEmbeds: true,
                    },
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );

            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`listings/${id}?${query}`);

            return flattenObj(response.data);
        } catch (e) {
            return e;
        }
    }

    async function getLisitingsInsights() {
        try {
            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`insights/listings`);

            return flattenObj(response.data);
        } catch (e) {
            return e;
        }
    }

    async function getPropRequestInsights() {
        try {
            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`insights/property-requests`);

            return flattenObj(response.data);
        } catch (e) {
            return e;
        }
    }

    async function getAgentPublicProfile({ slug }: any) {
        try {
            const response = await API({
                requireAuth: true,
                token: user.jwt,
            }).get<any, any>(`agent/listings/${slug}`);

            return flattenObj(response.data);
        } catch (e) {
            return e;
        }
    }

    const useGetSearchPropRequests = (payload: any) =>
        useQuery([SEARCH_REQUESTS, payload], () =>
            getSearchPropRequests(payload)
        );

    const useGetAgentPublicProile = (payload: any) =>
        useQuery(["GET_PUBLIC_PROFILE", payload], () =>
            getAgentPublicProfile(payload)
        );

    const useGetSearchCobrokeRequests = (payload: any) =>
        useQuery([SEARCH_COBROKE, payload], () =>
            getSearchCobrokeRequests(payload)
        );

    const useGetSearchListings = (payload: any) =>
        useQuery([SEARCH_LISTINGS, payload], () => getListings(payload));

    const useGetLandingListings = () =>
        useQuery([GET_LANDING_LISTINGS], () => getLandingListings());

    const useGetTags = () => useQuery([GET_TAGS], () => getTags());

    const useGetMyPropRequests = (id: string) =>
        useQuery([GET_MY_REQUESTS, id], () => getMyPropRequests(id));

    const useGetMySinglePropRequests = (id: string) =>
        useQuery([GET_MY_SINGLE_REQUESTS, id], () =>
            getMySinglePropRequest(id)
        );

    const useGetOnePropRequests = (id: string) =>
        useQuery([GET_ONE_REQUEST, id], () => getOnePropRequests(id), {
            enabled: !!id,
        });

    const useCreatePropRequest = () => useMutation(createPropRequest);

    const useUpdatePropRequest = () => useMutation(updatePropRequest);

    const useFulfillPropRequest = () => useMutation(fulfillPropRequest);

    const useDeletePropRequest = () => useMutation(deletePropRequest);

    const useUpdateListing = () => useMutation(updateListing);

    const useHideListing = () => useMutation(hideListing);

    const useDeleteListing = () =>
        useMutation(async (payload: any) => {
            const resp = await API({ requireAuth: true, token: user.jwt }).put<
                any,
                any
            >(`listings/${payload.id}`, {
                data: {
                    status: "deleted",
                },
            });

            return flattenObj(resp);
        });

    const useCreateListingTag = () => useMutation(createListingTag);

    const useCreateRequestTag = () => useMutation(createRequestTag);

    const useCreateOffer = () => useMutation(createOffer);

    const useScrape = () => useMutation(scrape);

    const useUpdatePropertyRequestTags = () =>
        useMutation(updatePropertyRequestTags);

    const useUpdateListingTags = () => useMutation(updateListingTags);

    const useCreateListing = () => useMutation(createListing);

    const useCreateListingAndOffer = () => useMutation(createListingAndOffer);

    const useSeenOffer = () => useMutation(seenOffer);

    const useGetMyOffers = (payload: any) =>
        useQuery([GET_OFFERS_BY_AGENT, payload], () => getMyOffers(payload));

    const useGetMyListings = (payload: any) =>
        useQuery([GET_LISTINGS_BY_AGENT, payload], () =>
            getMyListings(payload)
        );

    const useGetOfferByRequest = (id: string) =>
        useQuery([GET_OFFERS_BY_REQUEST, id], () => getOfferByRequest(id));

    const useGetOneOffer = (id: string) =>
        useQuery([GET_ONE_OFFER, id], () => getOneOffer(id));

    const useGetOneListing = (id: string) =>
        useQuery([GET_ONE_LISTING, id], () => getOneListing(id), {
            enabled: !!id,
        });

    const useGetPropRequestInsights = () =>
        useQuery([GET_INSIGHTS], () => getPropRequestInsights());

    const useGetListingInsights = () =>
        useQuery([GET_LISTING_INSIGHTS], () => getLisitingsInsights());

    return {
        useGetSearchListings,
        useGetTags,
        useGetMyPropRequests,
        useGetOnePropRequests,
        useCreatePropRequest,
        useUpdatePropRequest,
        useFulfillPropRequest,
        useDeletePropRequest,
        useUpdateListing,
        useCreateListingTag,
        useCreateRequestTag,
        useCreateOffer,
        useUpdatePropertyRequestTags,
        useCreateListing,
        useCreateListingAndOffer,
        useSeenOffer,
        useGetMyOffers,
        useGetOneOffer,
        useGetPropRequestInsights,
        useGetSearchPropRequests,
        useGetMySinglePropRequests,
        useScrape,
        useGetOneListing,
        useGetMyListings,
        useUpdateListingTags,
        useHideListing,
        useGetListingInsights,
        useGetOfferByRequest,
        useGetSearchCobrokeRequests,
        useDeleteListing,
        useGetLandingListings,
        useGetAgentPublicProile,
    };
};
