//@ts-nocheck
import { useCallback, useEffect, useMemo, useState } from "react";
import { LightBox } from "components/LightBox";
import { DetailsMultipleRow, DetailsMobile } from "components/Details/Details";
import {
    Box,
    Text,
    Divider,
    Flex,
    Image,
    Avatar,
    AspectRatio,
    Button,
    Container,
    Grid,
    Stack,
    useMantineTheme,
    Center,
    Overlay,
    Group,
    Badge,
    BackgroundImage,
    Chip,
    Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
    IconMapPin,
    IconLink,
    IconDiscountCheckFilled,
    IconStarFilled,
} from "@tabler/icons-react";
import testImage from "assets/testImage.png";
import testImage2 from "assets/testImage2.png";
import testImage3 from "assets/testImage3.png";
import testImage4 from "assets/testImage4.png";
import testImage5 from "assets/testImage5.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useGetAgentDetail } from "api/userAPI";
import { useCreateChat, useGetListingChat, useGetOfferChat } from "api/chatAPI";
import { Permission, useAuth } from "hooks/useAuth";
import { PROPERTY_REQUEST_COLOR_SETTINGS, PROPERTY_REQUEST_TYPE, USER_TYPE } from "helpers/constants";
import { useListingAPI } from "api/useListingAPI";
import { ArrowLeft } from "tabler-icons-react";
import ImageGrid from "./ViewListing/components/ImageGrid";
import { useProfileController } from "hooks/useProfile";
import { formatListingType } from "helpers/utility";
import DeveloperCard from "./AgentDashboard/components/DeveloperCard";

export default function ViewListing() {
    const { useGetOneOffer, useSeenOffer, useGetOneListing } = useListingAPI();
    const location = useLocation()
    const navigate = useNavigate();
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    let { id } = useParams();
    const { user } = useAuth(Permission.ANYONE);
    const [showLightBox, setShowLightBox] = useState(false);
    const [clickedImage, setClickedImage] = useState(0);
    const { data, isLoading, error } = useGetOneListing(id as string);
    const {
        data: agentData,
        isLoading: agentIsLoading,
        error: agentError,
    } = useGetAgentDetail(data?.agent?.id || "");

    const {
        data: listingChatData,
        isLoading: listingChatIsLoading,
        error: listingChatError,
    } = useGetListingChat(user.id, user.userType, parseInt(id as string));

    const createChatMutation = useCreateChat();

    const developerData = useMemo(() => {
        if (!!agentData && !agentIsLoading) {
            const [agent] = agentData;
            console.log("developer", agent?.developer)
            return agent?.developer
        }
        return null
    }, [agentData, agentIsLoading])

    const agencyData = useMemo(() => {
        if (!!agentData && !agentIsLoading) {
            const [agent] = agentData;
            console.log("agency", agent?.agency)
            return agent?.agency
        }
        return null
    }, [agentData, agentIsLoading])

    const formattedData = useMemo(() => {
        if (!!data && !isLoading && !!agentData && !agentIsLoading) {
            const [agent] = agentData;
            let images = [] as any[]

            if (!!data.images && data.images.length > 0) {
                const arr = data.images.map((i: any) => ({
                    url: i.url ?? "/img-placeholder.jpeg",
                }));

                images = [...images, ...arr]
            }

            if (!!data.imageLinks && data.imageLinks.length > 0) {
                const arr = data.imageLinks.map((i: any) => ({
                    url: i,
                }));

                images = [...images, ...arr]
            }

            let offerDetails = [
                {
                    type: "Developer",
                    text: data.developer,
                },
                {
                    type: "No. of Bathroom(s)",
                    text: data.noOfBathrooms,
                },
                {
                    type: "No. of Bedroom(s)",
                    text: data.noOfBedrooms,
                },
                {
                    type: "Floor Size",
                    text: `${data.floorSize} sqft`,
                },
                {
                    type: "Property Title Type",
                    text: data.propertyTitleType,
                },
            ]

            if (!!data.landSize) {
                offerDetails.push({
                    type: "Land Size",
                    text: `${data.landSize} sqft`,
                })
            }

            // console.log("agent", agent)
            // console.log("data", data)

            const obj = {
                auction: {
                    isAuction: data.isAuction,
                    startDate: data.auctionStart,
                    endDate: data.auctionEnd,
                },
                transactionType: data.transactionType,
                offerImages: images.slice(1) ?? "/img-placeholder.jpeg",
                offerMainImage: images[0] ?? "/img-placeholder.jpeg",
                offerSummary: {
                    price: data.price,
                    title: data.title,
                    addressLine1: data.address,
                    originalListingURL: data.importLink,
                },
                offerDetails,
                propertyDetails: data.tags.map((t: any) => ({
                    value: t.value,
                    background: t.tag?.badgeBackground ?? "#2e523c",
                    color: t.tag?.badgeColor ?? "#FFFFFF",
                })),
                isDevloperListing: data.isDeveloperListing,
                agentInfo: {
                    name: data?.agent?.fullname,
                    renId: agent?.renId,
                    joinDate: moment(data?.agent?.createdAt).format(
                        "DD MMM, YYYY"
                    ),
                    profilePic: agent?.avatar?.url ?? "/avatar",
                    publicProfileUrl: !!agent?.publicProfileSlug ? agent?.publicProfileSlug : false,
                    // averageRating: "4.3",
                    // noOfReviews: 15,
                },
            };
            console.log("obj", obj)
            return obj;
        }

        return {
            auction: {
                isAuction: null,
                startDate: null,
                endDate: null,
            },
            transactionType: 'BUY',
            isDevloperListing: false,
            offerImages: [
                {
                    src: "",
                },
            ],
            offerMainImage: {
                src: "",
            },
            offerSummary: {
                title: "",
                price: "",
                addressLine1: "",
                originalListingURL: "",
            },
            offerDetails: [
                {
                    type: "Developer",
                    text: "",
                },
                {
                    type: "No. of Bathroom(s)",
                    text: "",
                },
                {
                    type: "No. of Bedroom(s)",
                    text: "",
                },
                {
                    type: "Floor Size",
                    text: "",
                },
                {
                    type: "Land Size",
                    text: "",
                },
                {
                    type: "Property Title Type",
                    text: "",
                },
            ],
            propertyDetails: [],
            agentInfo: {
                name: "",
                renId: "",
                joinDate: "",
                profilePic: "",
            },
        };
    }, [data, agentData]);

    useEffect(() => {
        if (!!data && !data.agent) {
            navigate("/404")
        }
    }, [data])


    const hasChat = useMemo(() => {
        return (
            !!listingChatData && listingChatData.length === 1 && !listingChatIsLoading
        );
    }, [listingChatData, listingChatIsLoading]);

    const handleChatNow = async () => {
        if (!user.jwt) {
            sessionStorage.setItem("redirect_url", location.pathname)
            navigate("/login")
            return
        }

        if (hasChat) {
            navigate(`/chats`, { state: { id: listingChatData[0].id } });
        } else {
            const chat = await createChatMutation.mutateAsync({
                lister: user.id,
                agent: data?.agent.id,
                listing: parseInt(id as string),
            });

            navigate(`/chats`, { state: { id: chat.data.id } });
        }
    };

    const AgentDetails = () => {
        return (
            <Flex
                w={"100%"}
                h={"fit-content"}
                direction="column"
                align="center"
                p={isMobile ? 24 : 16}
                gap="16px"
                sx={{
                    border: "1px solid #BFC8C3",
                    background: "#FFFFFF",
                    borderRadius: "12px",
                }}
            >
                <Flex
                    w={"100%"}
                    h={"100%"}
                    direction="column"
                    justify="center"
                    align="center"
                    gap="24px"
                >
                    <Flex direction="column" align="center" gap="10px">
                        <Avatar
                            src={formattedData.agentInfo.profilePic}
                            alt="agent"
                            radius="50%"
                        />

                        <Flex direction="column" align="center" gap="8px">
                            <Flex direction="column" align="center" gap="0px">
                                <Flex
                                    direction="row"
                                    align="center"
                                    gap="4px"
                                    sx={{
                                        "& .tabler-icon-discount-check-filled":
                                            { color: "#4299e1 !important" },
                                    }}
                                >
                                    <Text
                                        sx={{
                                            fontFamily:
                                                "Public Sans, sans-serif",
                                            fontStyle: "normal",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            lineHeight: "20px",
                                            color: "#212922",
                                        }}
                                    >
                                        {`${formattedData.agentInfo.name}(${formattedData.agentInfo.renId})`}
                                    </Text>
                                    <IconDiscountCheckFilled
                                        width={12}
                                        height={12}
                                    />
                                </Flex>
                                <Text
                                    sx={{
                                        fontFamily: "Public Sans, sans-serif",
                                        fontStyle: "normal",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "20px",
                                        color: "#212922",
                                    }}
                                >
                                    Joined PropMoth on{" "}
                                    {formattedData.agentInfo.joinDate}
                                </Text>
                            </Flex>
                            {!!formattedData.agentInfo.publicProfileUrl && (
                                <Button onClick={() => navigate(`/agent/${formattedData.agentInfo.publicProfileUrl}`)}>See Profile</Button>
                            )}
                        </Flex>
                        {/* <Stack>
                            {!!developerData && (
                                <Badge color="orange" variant="filled">
                                    Developer Account
                                </Badge>
                            )}
                        </Stack> */}
                    </Flex>
                    <Flex direction="column" align="center" gap="16px" w="100%">
                        {/* <Button
                            component="a"
                            href=""
                            h={44}
                            sx={{
                                // padding: "10px 115px",
                                backgroundColor: "#294936",
                                fontFamily: "Public Sans, sans-serif",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                width: "100%",
                            }}
                        >
                            Accept Offer
                        </Button> */}
                        {!!user && user.userType !== "Agent" && (
                            <>
                                <Button
                                    sx={{
                                        // padding: "10px 115px",
                                        // border: "1px solid #294936",
                                        // color: "#294936",
                                        // backgroundColor: "#FFFFFF",
                                        // fontFamily: "Public Sans, sans-serif",
                                        // fontStyle: "normal",
                                        // fontWeight: 500,
                                        // fontSize: "16px",
                                        // lineHeight: "24px",
                                        width: "100%",
                                    }}
                                    onClick={() => handleChatNow()}
                                >
                                    Chat Now
                                </Button>
                                <Text
                                    sx={{
                                        fontFamily: "Public Sans, sans-serif",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#294936",
                                    }}
                                >
                                    Personal information is kept private while you chat
                                </Text>
                            </>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        );
    };

    return (
        <Container
            size="1200px"
            py={32}
            px={16}
            sx={{ minHeight: "fit-content !important" }}
        >
            <Group mb={16}>
                <Button
                    variant="subtle"
                    leftIcon={<ArrowLeft />}
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
            </Group>
            <Box pb={32}>
                <Text
                    sx={{
                        fontWeight: 600,
                        fontSize: "36px",
                        lineHeight: "44px",
                        letterSpacing: "-0.02em",
                    }}
                >
                    Listing Details
                </Text>
            </Box>
            {!isMobile && (
                <Divider my="sm" pb={32} m={"0 !important"} w={"100%"} />
            )}
            <ImageGrid mainImage={formattedData.offerMainImage} images={formattedData.offerImages} />
            <Grid gutter={24}>
                <Grid.Col md={8} sm={12}>
                    <Flex
                        w={"100%"}
                        direction="column"
                        align="flex-start"
                        p={0}
                        gap="16px"
                    >
                        <Flex
                            direction="column"
                            align="flex-start"
                            p={0}
                            gap="8px"
                            w="100%"
                        >
                            <Group spacing={8}>
                                {!!developerData && !!formattedData.isDevloperListing && (
                                    <Badge color="orange" variant="filled">
                                        Developer Listing
                                    </Badge>
                                )}
                                <Badge
                                    sx={{
                                        color: PROPERTY_REQUEST_COLOR_SETTINGS[
                                            formattedData.transactionType
                                        ].color,
                                        backgroundColor:
                                            PROPERTY_REQUEST_COLOR_SETTINGS[
                                                formattedData.transactionType
                                            ].background,
                                    }}
                                >
                                    {formatListingType(formattedData.transactionType)}
                                </Badge>
                            </Group>
                            {!!formattedData.auction.isAuction && (
                                <Stack w="100%" sx={{ border: "1px solid #417256", padding: 8, borderRadius: 8 }}>
                                    <Badge>
                                        Auction
                                    </Badge>
                                    <Group>
                                        <Text ta="center" sx={{ flex: 1 }}>
                                            <Text component="span" fw="bold">Start Date:</Text> {formattedData.auction.startDate ? moment(formattedData.auction.startDate).format("DD/MM/YYYY HH:mm") : "TBD"}
                                        </Text>
                                        <Text ta="center" sx={{ flex: 1 }}>
                                            <Text component="span" fw="bold">End Date:</Text> {formattedData.auction.endDate ? moment(formattedData.auction.endDate).format("DD/MM/YYYY HH:mm") : "TBD"}
                                        </Text>
                                    </Group>
                                </Stack>
                            )}
                            <Text
                                sx={{
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    fontSize: "20px",
                                    lineHeight: "30px",
                                    color: "#212922",
                                }}
                            >
                                {`RM ${parseInt(
                                    formattedData.offerSummary.price
                                ).toLocaleString()}`}
                            </Text>
                            <Text
                                sx={{
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    fontSize: "20px",
                                    lineHeight: "30px",
                                    color: "#212922",
                                }}
                            >
                                {formattedData.offerSummary.title}
                            </Text>
                            <Flex
                                direction="row"
                                align="center"
                                p={0}
                                gap="8px"
                            >
                                <IconMapPin
                                    width={"16px"}
                                    height={"16px"}
                                    color="#546D5E"
                                />
                                <Text
                                    sx={{
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        color: "#294936",
                                    }}
                                >
                                    {formattedData.offerSummary.addressLine1}
                                </Text>
                            </Flex>
                            {!!formattedData.offerSummary
                                .originalListingURL && (
                                    <Group>
                                        <IconLink
                                            width={"24px"}
                                            height={"24px"}
                                            color="#546D5E"
                                            style={{ transform: "rotate(45deg)" }}
                                        />
                                        <Text
                                            component="a"
                                            href={
                                                formattedData.offerSummary
                                                    .originalListingURL
                                            }
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                                color: "#294936",
                                                textDecorationLine: "underline",
                                            }}
                                        >
                                            View original listing
                                        </Text>
                                    </Group>
                                )}
                            <Group mt={8}>
                                {formattedData.propertyDetails.map((t: any) => (
                                    <Badge
                                        sx={{
                                            background: t.background,
                                            color: t.color,
                                        }}
                                    >
                                        {t.value}
                                    </Badge>
                                ))}
                            </Group>
                        </Flex>
                        <Divider my="sm" w={"100%"} />

                        <DetailsMultipleRow
                            title="Offer details"
                            detailsInfo={formattedData.offerDetails}
                        />
                    </Flex>
                </Grid.Col>
                <Grid.Col md={4} sm={12}>
                    <AgentDetails />
                </Grid.Col>
                {!!developerData && !!formattedData.isDevloperListing && (
                    <Grid.Col span={12}>
                        <Stack>
                            <Divider my={16} />

                            <Title order={3}>Developer Data</Title>
                            <DeveloperCard
                                developer={developerData}
                                onLearnMore={() => { }}
                                onApply={() => { }}
                            />
                        </Stack>
                    </Grid.Col>
                )}
            </Grid>
        </Container>
    );
}
