export enum USER_STATUS {
    PENDING = "PENDING",
    CONFIRMED = "CONFIRMED",
    BANNED = "BANNED",
}

export enum AGENT_REQUEST_STATUS {
    ACTIVE = "ACTIVE",
    FULFILLED = "FULFILLED",
    DELETED = "DELETED",
}

export const TRANSACTION_TYPE_OPTIONS = [
    {
        value: "RENT",
        label: "Rent",
    },
    {
        value: "SELL",
        label: "Sell",
    },
];

export enum PROPERTY_REQUEST_TYPE {
    BUY = "BUY",
    RENT = "RENT",
    INVEST = "INVEST",
}

export enum CONFIG_KEYS {
    STATES_AND_AREAS = "STATES_AND_AREAS",
    PROPERTY_TYPES = "PROPERTY_TYPES",
    DETAIL_OPTIONS = "DETAIL_OPTIONS",
    OCCUPATION = "OCCUPATION",
    TOKEN_PACKAGES = "TOKEN_PACKAGES",
    TOKEN_PRICING = "TOKEN_PRICING",
    AGENCY_TAGS = "AGENCY_TAGS",
    DEVELOPER_TAGS = "DEVELOPER_TAGS",
}

export enum notificationType {
    OFFER = "Offer",
    REQUEST = "Request",
    CHAT = "Chat",
    REFERRAL = "Referral",
}

export enum validateUserStatus {
    VALID,
    INVALID,
}

export enum formStep {
    REGISTER,
    OTP,
}

export enum forgotStep {
    PHONE_NUMBER,
    OTP,
    RESET,
}

export enum DATE_DISPLAYS {
    NOTIFICATION = "D MMMM YYYY",
    PROFILE = "D MMMM, YYYY",
}

export enum USER_TYPE {
    LISTER = "Lister",
    AGENT = "Agent",
}

export enum CREATE_REQUEST_STEPS {
    DETAILS,
    LOCATION,
    SIZE_AND_AMMENITIES,
    BUDGET,
    ADDITIONAL_NOTES,
}

export const PROPERTY_REQUEST_COLOR_SETTINGS = {
    BUY: {
        color: "#175CD3",
        background: "#EFF8FF",
    },
    RENT: {
        color: "#5925DC",
        background: "#F4F3FF",
    },
    INVEST: {
        color: "#C11574",
        background: "#FDF2FA",
    },
};

export const PROPERTY_TITLE_TYPE_OPTIONS = [
    {
        value: "individual",
        label: "Individual",
    },
    {
        value: "strata",
        label: "Strata",
    },
    {
        value: "master",
        label: "Master",
    },
];

export const TENURE_OPTIONS = [
    {
        value: "leasehold",
        label: "Leasehold",
    },
    {
        value: "freehold",
        label: "Freehold",
    },
];

export const propRequestPropertyTitleOptions = [
    {
        value: "not-sure",
        label: "I'm not sure",
    },
    ...PROPERTY_TITLE_TYPE_OPTIONS,
];

export const propRequestTenureOptions = [
    {
        value: "not-sure",
        label: "I'm not sure",
    },
    ...TENURE_OPTIONS,
];

export const tokenData = [
    {
        tokenAmount: 1,
        price: "5.00",
        apiPrice: 500,
        pricePerLead: "5",
        saveAmount: "",
    },
    {
        tokenAmount: 5,
        price: "23.50",
        apiPrice: 2350,
        pricePerLead: "4.70",
        saveAmount: "1.50",
    },
    {
        tokenAmount: 10,
        price: "45.99",
        apiPrice: 4599,
        pricePerLead: "4.50",
        saveAmount: "4.00",
    },
    {
        tokenAmount: 100,
        price: "430.99",
        apiPrice: 43099,
        pricePerLead: "4.30",
        saveAmount: "70.00",
    },
    {
        tokenAmount: 500,
        price: "2099.99",
        apiPrice: 209999,
        pricePerLead: "4.20",
        saveAmount: "400",
    },
    {
        tokenAmount: 1000,
        price: "3999.99",
        apiPrice: 399999,
        pricePerLead: "4.00",
        saveAmount: "1000",
    },
];

// export const PROPERTY_REQUEST_COLOR_SETTINGS = {
//     BUY: {
//         color: "buyColor.5",
//         background: "buyBackground.0",
//     },
//     RENT: {
//         color: "rentColor.4",
//         background: "rentBackground.0",
//     },
//     INVEST: {
//         color: "investColor.5",
//         background: "investBackground.0",
//     },
// };

export const CHRISTMAS_PROMO_PIXEL = "1566927573844693";
export const REGISTRATION_PIXEL = "240412468887045";
export const HASSEL_FREE_AGENT_LP_PIXEL = "879417273653069";

const tokenPrices = {
    chat: 5,
    offer: 1,
};

export const rentOptions = [
    { label: "RM 500", value: "500" },
    { label: "RM 1,000", value: "1000" },
    { label: "RM 1,500", value: "1500" },
    { label: "RM 2,000", value: "2000" },
    { label: "RM 2,500", value: "2500" },
    { label: "RM 3,000", value: "3000" },
    { label: "RM 3,500", value: "3500" },
    { label: "RM 4,000", value: "4000" },
    { label: "RM 5,000", value: "5000" },
    { label: "RM 6,000", value: "6000" },
    { label: "RM 7,000", value: "7000" },
    { label: "RM 8,000", value: "8000" },
    { label: "RM 9,000", value: "9000" },
    { label: "RM 10,000", value: "10000" },
    { label: "RM 12,000", value: "12000" },
    { label: "RM 15,000", value: "15000" },
    { label: "RM 20,000", value: "20000" },
    { label: "RM 30,000", value: "30000" },
    { label: "RM 40,000", value: "40000" },
    { label: "RM 50,000", value: "50000" },
];

export const buyOptions = [
    { label: "RM 100,000", value: "100000" },
    { label: "RM 200,000", value: "200000" },
    { label: "RM 300,000", value: "300000" },
    { label: "RM 400,000", value: "400000" },
    { label: "RM 500,000", value: "500000" },
    { label: "RM 600,000", value: "600000" },
    { label: "RM 700,000", value: "700000" },
    { label: "RM 800,000", value: "800000" },
    { label: "RM 900,000", value: "900000" },
    { label: "RM 1,000,000", value: "1000000" },
    { label: "RM 1,250,000", value: "1250000" },
    { label: "RM 1,500,000", value: "1500000" },
    { label: "RM 2,000,000", value: "2000000" },
    { label: "RM 2,500,000", value: "2500000" },
    { label: "RM 3,000,000", value: "3000000" },
    { label: "RM 4,000,000", value: "4000000" },
    { label: "RM 5,000,000", value: "5000000" },
    { label: "RM 6,000,000", value: "6000000" },
    { label: "RM 8,000,000", value: "8000000" },
    { label: "RM 10,000,000", value: "10000000" },
    { label: "RM 15,000,000", value: "15000000" },
    { label: "RM 20,000,000", value: "20000000" },
    { label: "RM 30,000,000", value: "30000000" },
    { label: "RM 50,000,000", value: "50000000" },
];

export const incomeOptions = [
    "< RM 1000",
    "RM 1000 - RM 1500",
    "RM 1500 - RM 2000",
    "RM 2000 - RM 3000",
    "RM 3000 - RM 4000",
    "RM 5000+",
    "RM 6000+",
    "RM 7000+",
    "RM 8000+",
    "RM 9000+",
    "RM 10,000+",
];

export const basicPackage = {
    tag: "Basic",
    price: 50,
    priceAnnually: 500,
    annualSavings: 100,
    annualPerMonth: 41.6,
    functionList: {
        agentProfile: true,
        localityInsights: true,
        listings: 20,
        whatsappNotifications: true,
        leadBrowsing: false,
        advancedLeadFilter: false,
        cobrokeFeatures: false,
    },
    priceId: process.env.REACT_APP_BASIC_PRICE_ID,
    annualPriceId: process.env.REACT_APP_BASIC_ANNUAL_PRICE_ID,
    annualCouponId: process.env.REACT_APP_BASIC_ANNUAL_COUPON_ID,
    bg: "#15BC69",
};

export const proPackage = {
    tag: "Pro",
    price: 100,
    priceAnnually: 1000,
    annualSavings: 200,
    annualPerMonth: 83.3,
    functionList: {
        agentProfile: true,
        localityInsights: true,
        listings: 100,
        whatsappNotifications: true,
        leadBrowsing: true,
        advancedLeadFilter: true,
        cobrokeFeatures: true,
    },
    priceId: process.env.REACT_APP_PRO_PRICE_ID,
    annualPriceId: process.env.REACT_APP_PRO_ANNUAL_PRICE_ID,
    annualCouponId: process.env.REACT_APP_PRO_ANNUAL_COUPON_ID,
    bg: "#1F8150",
};

export enum PAYMENT_TYPE {
    MONTHLY,
    ANUALLY,
    PROMO,
}

export const seriousnessRatingOptions = [
    {
        value: "1",
        label: "Not sure, just checking the market",
    },
    {
        value: "2",
        label: "Still Surveying But I haven't found anything I like",
    },
    {
        value: "3",
        label: "I know what I want but I can't find it",
    },
    {
        value: "4",
        label: "I know what I want but I want to see other options",
    },
    {
        value: "5",
        label: "I know what I want and I'm willing to go for viewing",
    },
    {
        value: "6",
        label: "I know what I want and I want to know more ",
    },
    {
        value: "7",
        label: "I'm ready with everything I just need to find the right place",
    },
    {
        value: "8",
        label: "If it suits me I'm ready to deal",
    },
    {
        value: "9",
        label: "I have already surveyed the market and I'm ready to deal",
    },
    {
        value: "10",
        label: "I'm very serious and ready to deal anytime",
    },
];
