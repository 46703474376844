import axios, { AxiosRequestConfig } from "axios";
import { Permission, useAuth } from "../hooks/useAuth";
const useAxios = () => {};
// initialize instance without header
const instance = (params?: Partial<AxiosRequestConfig>) =>
    axios.create({
        // baseURL: 'https://www.r48b1ttech.com/api/',
        baseURL: process.env.REACT_APP_BASE_URL, // pending vercel integration
        ...params,
    });

type APIOptions = {
    token?: string;
    requireAuth?: boolean;
    contentType?: string;
};

// API function that allows users to choose if need to pass auth header
const API = (apiOptions: Partial<APIOptions> = {}) => {
    let authBearerToken = "";
    try {
        const user = JSON.parse(localStorage.getItem("user") || "");

        authBearerToken =
            (apiOptions.requireAuth && user && user.jwt) || undefined;
    } catch (e) {}
    
    // if (!!user && !!user.jwt) {
    //     authBearerToken = user.jwt;
    // }

    const client = instance({
        headers: {
            ...API.header.contentType(
                apiOptions?.contentType
                    ? apiOptions.contentType
                    : "application/json"
            ),
            ...API.header.auth(
                apiOptions.token ? apiOptions.token : authBearerToken
            ),
        },
    });

    client.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            const originalRequest = error.config;
            const user = JSON.parse(localStorage.getItem("user") || "");

            if (error.response.status === 502) {
                window.location.href = `${window.location.origin}/502`;
            }

            if (error.response.status === 404) {
                window.location.href = `${window.location.origin}/404`;
            }

            if (error.response.status === 401 && !user.refreshToken) {
                localStorage.removeItem("user");
                window.location.href = `${window.location.origin}/login?expiredToken=true`;
            }

            if (
                error.response.status === 401 &&
                !!user.refreshToken &&
                !originalRequest._retry
            ) {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}token/refresh`,
                    {
                        refreshToken: user.refreshToken,
                    }
                );

                let refreshedState = {
                    ...user,
                    jwt: response.data.jwt,
                    refreshToken: response.data.refreshToken,
                };

                localStorage.setItem("user", JSON.stringify(refreshedState));

                console.log("refreshedState", refreshedState);

                originalRequest.headers.Authorization = `Bearer ${refreshedState.jwt}`;
                return axios(originalRequest);
                // localStorage.removeItem("user");
                // window.location.href = `${window.location.origin}/login?expiredToken=true`;
            }

            return Promise.reject(error);
        }
    );

    return client;
};

API.header = {
    auth: (token: string | undefined) => ({
        ...(token
            ? {
                  Authorization: `Bearer ${token}`,
              }
            : {}),
    }),
    contentType: (type: string) => ({
        ["Content-Type"]: type,
    }),
};

export default API;
