import { BackgroundImage, Badge, Box, Button, Card, Flex, Grid, Group, Image, Modal, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { usePotentialAgentAPI } from 'api/usePotentialAgentAPI';
import { Permission, useAuth } from 'hooks/useAuth';
import AgencyCard from 'pages/AgentDashboard/components/AgencyCard';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AgencySelection = ({ handleApply, applied }: { handleApply: any, applied: any[] }) => {
    const theme = useMantineTheme()
    const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

    const { user } = useAuth(Permission.USER_ONLY)
    const { useGetAgencies, useCreatePotentialAgent } = usePotentialAgentAPI()
    const { data: agencies, isLoading: agenciesIsLoading, error: agenciesError } = useGetAgencies()
    const [confirmModal, setConfirmModal] = useState<any>(null)
    const navigate = useNavigate()

    useEffect(() => {
        console.log("agencies", agencies)
    }, [agencies])




    return (
        <>
            <Modal
                title="Confirm Apply"
                opened={!!confirmModal}
                onClose={() => setConfirmModal(null)}
                centered
            >
                <Stack>
                    <Text ta="center" fz="xl" fw="bold">Apply for {confirmModal?.name}?</Text>
                    <Group >
                        <Button sx={{ flex: 1 }} color="red" variant='outline'>
                            Cancel
                        </Button>
                        <Button sx={{ flex: 1 }} onClick={() => {
                            handleApply(confirmModal)
                            setConfirmModal(false)
                        }}>
                            Confirm
                        </Button>
                    </Group>
                </Stack>
            </Modal>
            <Grid>
                <Grid.Col span={12}>
                    <Stack>
                        <Title order={2}> Choose from our list of verified teams and agencies!</Title>
                        <Text> You can apply to as many as you want </Text>
                    </Stack>
                </Grid.Col>
                {!!agencies && agencies.length > 0 && agencies.map((a: any) => (
                    <Grid.Col span={12}>
                        <AgencyCard
                            agency={a}
                            onLearnMore={() => window.open(`/agency/${a.slug}`, "_blank")}
                            onApply={setConfirmModal}
                            isApplied={applied.includes(a.id)}
                        />
                    </Grid.Col>
                ))}
            </Grid>
        </>
    );
};

export default AgencySelection;