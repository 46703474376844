import { Badge, Box, Button, Card, Center, Container, Grid, Group, Loader, Stack, Table, Text, Title } from '@mantine/core';
import { useAgencyAPI } from 'api/useAgencyAPI';
import { usePotentialAgentAPI } from 'api/usePotentialAgentAPI';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const PotentialAgentsList = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { useGetPotentialAgentData, } = useAgencyAPI()
    const { useUpdatePotentialAgent } = usePotentialAgentAPI()
    const updatePotentialAgent = useUpdatePotentialAgent()
    const { data: potentialAgentData, isLoading: potentialAgentIsLoading, error: potentialAgentError, refetch } = useGetPotentialAgentData(id ?? "")

    const potentialAgents = useMemo(() => {
        console.log(potentialAgentData)
        // if(!!data?.potentialAgents){
        //     return data?.potentialAgents
        // }

        return potentialAgentData ?? []
    }, [potentialAgentData])

    const handleUpdateStatus = async (id: string, status: string) => {
        await updatePotentialAgent.mutateAsync({
            id,
            data: {
                status
            }
        })

        await refetch()
    }

    return (
        <Container py={32} mih={"100vh"}>
            <Grid>
                {!!potentialAgentIsLoading && (
                    <Grid.Col span={12}>
                        <Center p={24}>
                            <Loader />
                        </Center>
                    </Grid.Col>
                )}
                {potentialAgents.length > 0 && (
                    <>
                        {/* <Grid.Col xs={12}>
                            <Title order={3}>Stats</Title>
                        </Grid.Col>
                        <Grid.Col xs={12}>
                            <Card w="100%" shadow="md" withBorder>
                                <Table withBorder >
                                    <tr>
                                        <Box component='th' sx={{ padding: 8, border: "1px solid #ddd" }} >
                                            <Text w="100%" ta="left">Total Potential Agents</Text>
                                        </Box>
                                        <Box component='td' miw={80} sx={{ padding: 8, border: "1px solid #ddd" }}>{potentialAgents.length}</Box>
                                    </tr>
                                </Table>
                            </Card>
                        </Grid.Col> */}
                        <Grid.Col xs={12}>
                            <Title order={3}>List of Applicants</Title>
                        </Grid.Col>
                        {potentialAgents.map((p: any) => (
                            <Grid.Col xs={12}>
                                <Card w="100%" shadow="md" withBorder>
                                    <Stack>
                                        <Table withBorder >
                                            <tr>
                                                <Box component='th' sx={{ padding: 8, border: "1px solid #ddd" }} >
                                                    <Text w="100%" ta="left">Status</Text>
                                                </Box>
                                                <Box component='td' sx={{ padding: 8, border: "1px solid #ddd" }}>
                                                    {(() => {
                                                        switch (p.status) {
                                                            case 'CONTACTED':
                                                                return <Badge>CONTACTED</Badge>;
                                                            case 'APPLIED':
                                                                return <Badge variant='outline'>APPLIED</Badge>;
                                                        }
                                                    })()}
                                                </Box>
                                            </tr>
                                            <tr>
                                                <Box component='th' sx={{ padding: 8, border: "1px solid #ddd" }} >
                                                    <Text w="100%" ta="left">Applicant Name</Text>
                                                </Box>
                                                <Box component='td' sx={{ padding: 8, border: "1px solid #ddd" }}>{p.user.fullname}</Box>
                                            </tr>
                                            <tr>
                                                <Box component='th' sx={{ padding: 8, border: "1px solid #ddd" }} >
                                                    <Text w="100%" ta="left">Email</Text>
                                                </Box>
                                                <Box component='td' sx={{ padding: 8, border: "1px solid #ddd" }}>{p.user.email}</Box>
                                            </tr>
                                            <tr>
                                                <Box component='th' sx={{ padding: 8, border: "1px solid #ddd" }} >
                                                    <Text w="100%" ta="left">Phone Number</Text>
                                                </Box>
                                                <Box component='td' sx={{ padding: 8, border: "1px solid #ddd" }}>+{p.user.username}</Box>
                                            </tr>
                                            <tr>
                                                <Box component='th' sx={{ padding: 8, border: "1px solid #ddd" }} >
                                                    <Text w="100%" ta="left">Preferred Contact Method</Text>
                                                </Box>
                                                <Box component='td' sx={{ padding: 8, border: "1px solid #ddd" }}>{p.contactMethod}</Box>
                                            </tr>
                                        </Table>
                                        {p.status === "APPLIED" && (
                                            <Button onClick={() => handleUpdateStatus(p.id, "CONTACTED")}>Mark As Contacted</Button>
                                        )}
                                        {p.status === "CONTACTED" && (
                                            <Button onClick={() => handleUpdateStatus(p.id, "APPLIED")}>Unmark as Contacted</Button>
                                        )}
                                    </Stack>
                                </Card>
                            </Grid.Col>
                        ))}
                    </>
                )}
            </Grid>
        </Container>
    );
};

export default PotentialAgentsList;