import {
    Box,
    Button,
    Container,
    Grid,
    Group,
    Radio,
    Select,
    Stack,
    Textarea,
    TextInput,
    Image,
    Stepper,
    Accordion,
    Text,
    Flex,
    Center,
    Switch,
    Card,
    Badge,
    Divider,
    NumberInput,
    SelectItem,
    useMantineTheme,
    Sx,
    Checkbox,
    Modal,
    Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
    CONFIG_KEYS,
    CREATE_REQUEST_STEPS,
    PROPERTY_REQUEST_COLOR_SETTINGS,
    PROPERTY_REQUEST_TYPE,
    PROPERTY_TITLE_TYPE_OPTIONS,
    propRequestPropertyTitleOptions,
    propRequestTenureOptions,
    seriousnessRatingOptions,
    TENURE_OPTIONS,
    USER_TYPE,
} from "helpers/constants";
import { Permission, useAuth } from "hooks/useAuth";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { redirect, useNavigate, useParams } from "react-router-dom";
import {
    Bath,
    Bed,
    CircleCheck,
    InfoCircle,
    Key,
    Moneybag,
    ShoppingBag,
} from "tabler-icons-react";
import { notifications } from "@mantine/notifications";
import { getReadableValueFromSelect, selectFilter } from "helpers/utility";
import { useMediaQuery } from "@mantine/hooks";
import { useGetConfigs } from "api/configAPI";
import { useListingAPI } from "api/useListingAPI";
import SizeSection from "./Sections/SizeSection";
import BudgetInput from "./components/BudgetInput";
import MiniRegisterForm from "./components/MiniRegisterForm";
import { useCreateRequest } from "./hooks/useCreateRequest";
import { useProfileController } from "hooks/useProfile";

export default function PropRequestForm() {
    const {
        useCreateRequestTag,
        useCreatePropRequest,
        useGetTags,
        useUpdatePropRequest,
        useGetOnePropRequests,
        useUpdatePropertyRequestTags,
        useGetMyPropRequests,
    } = useListingAPI();
    const noBedroomsOption = ["Shop Lot", "Office Unit"];
    const navigate = useNavigate();
    const { createRequest } = useCreateRequest();
    const { isPro, isBasic, isUnsubscribed } = useProfileController();
    let { id } = useParams();
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const [createLoading, setCreateLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [tagForm, setTagForm] = useState<any>({});
    const [states, setStates] = useState([]);
    const [accordionStep, setAccordionStep] = useState(
        `${CREATE_REQUEST_STEPS.DETAILS}`
    );
    const { user } = useAuth(Permission.ANYONE);

    const { data, isLoading, error, refetch } = useGetMyPropRequests(
        user.id.toString() || "0"
    );

    // limit 1 request per user
    useEffect(() => {
        if (!!user && user.userType === USER_TYPE.LISTER) {
            if (!!data && !isLoading && !id) {
                if (data.length > 0) {
                    navigate("/profile");
                }
            }
        }
    }, [data, id, user]);


    // agent coagency paywall
    useEffect(() => {
        if (!!user && user.userType === USER_TYPE.AGENT) {
            if (!isPro) {
                navigate("/profile?tab=subscription")
            }
        }
    }, [user, isPro])

    const [draftPopup, setDraftPopup] = useState<any>({});

    const propertyRequestOptions = {
        buy: {
            key: PROPERTY_REQUEST_TYPE.BUY,
            title: "Buy",
            desc: "We will connect you with agents that specialize in handling clients that are looking to buy property directly from owners or developers",
            image: <ShoppingBag size={32} strokeWidth={2} color={"#212922"} />,
        },
        rent: {
            key: PROPERTY_REQUEST_TYPE.RENT,
            title: "Rent",
            desc: "We will connect you with agents that specialize in rental properties and will take care of your interactions with landlords",
            image: <Key size={32} strokeWidth={2} color={"#212922"} />,
        },
        invest: {
            key: PROPERTY_REQUEST_TYPE.INVEST,
            title: "Invest",
            desc: "Your listing will be shown to experts that have experience in working with first time renters",
            image: <Moneybag size={32} strokeWidth={2} color={"#212922"} />,
        },
    };
    const updatePropRequest = useUpdatePropRequest();
    const updatePropRequestTags = useUpdatePropertyRequestTags();

    const {
        data: requestData,
        isLoading: requestIsLoading,
        error: requestError,
    } = useGetOnePropRequests(id as string);

    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    const {
        data: tags,
        isLoading: tagsIsLoading,
        error: tagsError,
    } = useGetTags();

    const sidebarStyles = isMobile
        ? ({
            position: "initial",
            width: "100%",
            padding: "16px",
        } as Sx)
        : ({
            position: "relative",
            minWidth: "250px",
            paddingTop: "16px",
        } as Sx);

    const stepperStyle = isMobile
        ? ({
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
        } as Sx)
        : ({
            position: "fixed",
            left: 0,
            top: 0,
            minWidth: "220px",
            height: "100vh",
            flexDirection: "column",
            padding: "16px",
            gap: "24px",
        } as Sx);

    const form = useForm({
        initialValues: {
            tenure: "",
            propertyTitleType: "",
            transactionType: "",
            isFirstTime: false,
            isUrgent: false,
            subSalesOk: true,
            newProjectsOnly: false,
            showPhoneNumber: true,
            state: "",
            area: "",
            postcode: "",
            propertyType: "",
            budgetMin: "",
            budgetMax: "",
            incomeRange: "",
            noOfBedrooms: "",
            noOfBathrooms: "",
            minSquareFt: "",
            maxSquareFt: "",
            additionalNotes: "",
            seriousnessRating: "",
        },
        validate: {},
    });

    useEffect(() => {
        if (!!id && !!requestData && !!tags) {
            form.setValues({
                tenure: requestData.tenure,
                propertyTitleType: requestData.propertyTitleType,
                transactionType: requestData.transactionType,
                isFirstTime: !!requestData.isFirstTime,
                newProjectsOnly: !!requestData.newProjectsOnly,
                subSalesOk: !!requestData.subSalesOk,
                isUrgent: requestData.isUrgent,
                state: requestData.state,
                area: requestData.area,
                postcode: requestData.postcode,
                propertyType: requestData.propertyType,
                budgetMin: requestData.budgetMin,
                budgetMax: requestData.budgetMax,
                noOfBedrooms: requestData.noOfBedrooms,
                noOfBathrooms: requestData.noOfBathrooms,
                minSquareFt: requestData.minSquareFt,
                maxSquareFt: requestData.maxSquareFt,
                additionalNotes: requestData.additionalNotes,
                incomeRange: requestData.incomeRange,
                showPhoneNumber: requestData.showPhoneNumber,
                seriousnessRating: requestData.seriousnessRating
            });

            const releventTags = tags.filter((t: any) => {
                return t.values.transactionType.includes(
                    requestData.transactionType
                );
            });

            if (releventTags.length > 0) {
                let tagsInput = {} as any;

                releventTags.forEach((tag: any) => {
                    let defaultValue = "" as string | boolean;
                    let savedTag = requestData.tags.find(
                        (rt: any) => rt.tag.title === tag.title
                    );
                    if (!!savedTag) {
                        if (savedTag.tag.values.type === "boolean") {
                            defaultValue = true;
                        }
                        if (savedTag.tag.values.type === "select") {
                            const option = savedTag.tag.values.options.find(
                                (o: any) => {
                                    return o.label === savedTag.value;
                                }
                            );
                            defaultValue = option.value;
                        }
                    } else {
                        defaultValue =
                            tag.values.type !== "boolean" ? "" : false;
                    }

                    tagsInput[tag.title] = { ...tag, value: defaultValue };
                });
                setTagForm(tagsInput);
            }

            setActiveTab(2);
        }
    }, [id, requestData, tags]);

    useEffect(() => {
        const draft = {
            form: form.values,
            tags: tagForm,
            accordionStep,
            activeTab,
        };

        const formDirty = form.isDirty();

        if (!id && (formDirty || Object.keys(tagForm).length > 0)) {
            sessionStorage.setItem("request_draft", JSON.stringify(draft));
        }
    }, [form.values, tagForm, id]);

    useEffect(() => {
        const draft = sessionStorage.getItem("request_draft");

        if (!!draft && !id) {
            const parsed = JSON.parse(draft);
            // console.log("parsed", parsed);
            setDraftPopup(parsed);
        }
    }, [id]);

    const hasDraft = useMemo(() => {
        const hasKeys = Object.keys(draftPopup).length > 0;
        if (!!hasKeys) {
            const hasNotEmptyValues =
                Object.values(draftPopup.form).filter((v) => !!v).length > 0;
            const hasTagsValues =
                Object.values(draftPopup.tags).filter((t: any) => !!t.value)
                    .length > 0;

            return hasNotEmptyValues && hasTagsValues;
        }
        return false;
    }, [draftPopup]);

    const importDraft = () => {
        // console.log(draftPopup);
        setAccordionStep(draftPopup.accordionStep);
        setActiveTab(draftPopup.activeTab);

        if (!!draftPopup.form) {
            // console.log(draftPopup.form);
            form.setValues(draftPopup.form);
        }
        if (Object.keys(draftPopup.tags).length > 0) {
            setTagForm(draftPopup.tags);
        }
        setDraftPopup({});
    };

    const statesAndAreas = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.STATES_AND_AREAS
            ).value;
        }

        return [];
    }, [configData]);

    const propertyTypesData = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.PROPERTY_TYPES
            ).value;
        }

        return [];
    }, [configData]);

    useEffect(() => {
        let statesData = statesAndAreas
            .map((s: any) => ({
                value: s.state,
                label: s.state,
            }))
            .filter((e: any) => !!e);

        setStates(statesData);
    }, [configData]);

    const areas = useMemo(() => {
        if (!!form.values.state) {
            const stateObj = statesAndAreas.find(
                (s: any) => s.state === form.values.state
            );
            // console.log(stateObj);
            if (!!stateObj) {
                return stateObj.areas
                    .map((a: any) => ({
                        value: a,
                        label: a,
                    }))
                    .filter((e: any) => !!e);
            }
            return [];
        }

        return [
            {
                value: "",
                label: "Please select a state first",
            },
        ];
    }, [form.values]);

    const propertyTypes = useMemo(() => {
        if (!!form.values.transactionType) {
            console.log("here", form.values.transactionType);
            const propertyTypeObj = propertyTypesData.find(
                (p: any) => p.type === form.values.transactionType
            );
            // console.log(propertyTypeObj);

            return propertyTypeObj.values
                .map((a: any) => ({
                    value: a,
                    label: a,
                }))
                .filter((e: any) => !!e);
            // if (!!propertyTypeObj) {
            // }
        }

        return [
            {
                value: "",
                label: "",
            },
        ];
    }, [form.values]);

    const handleCreate = useCallback(() => {
        if (!!user.jwt) {
            return onCreateListing()
        }
        console.log("form.values", form.values)
        const cache = {
            action: "CREATE_PROP_REQUEST",
            data: {
                form: form.values,
                tags: tagForm,
            }
        };

        sessionStorage.removeItem("request_draft");
        sessionStorage.setItem("pre_login_draft", JSON.stringify(cache));
        setActiveTab(3)

        // return navigate("/register/lister")
    }, [user, form.values, tagForm])

    const onCreateListing = useCallback(async () => {
        setCreateLoading(true);
        try {
            let payload = {
                ...form.values
            } as any

            if (!!isPro) {
                payload.isCobroke = true
            }
            await createRequest(payload, tagForm, user.id);

            setCreateLoading(false);
            navigate("/profile")
        } catch (e) {
            setCreateLoading(false);
            notifications.show({
                title: "Error creating property request",
                message: "If this issue persist, please contact our support",
                autoClose: 2000,
            });
        }
    }, [isPro, form.values, tagForm, user]);

    const onUpdateListing = async () => {
        try {
            setCreateLoading(true);
            // console.log(form.values);
            await updatePropRequest.mutateAsync({
                ...form.values,
                id,
            });

            await updatePropRequestTags.mutateAsync({
                propertyRequestId: id as string,
                tags: tagForm,
            });

            navigate("/profile");
            notifications.show({
                title: "Success!",
                message: "Successfully updated property request",
                autoClose: 2000,
            });

            setCreateLoading(false);
        } catch (e) {
            setCreateLoading(false);
            notifications.show({
                title: "Error updating property request",
                message: "If this issue persist, please contact our support",
                autoClose: 2000,
            });
        }
    };

    const tagsOptionOnChange = (
        title: string,
        value: string | null | boolean
    ) => {
        if (value === null) {
            return;
        }

        const editing = tagForm[`${title}`];
        setTagForm((prev: any) => ({
            ...tagForm,
            [`${title}`]: {
                ...editing,
                value,
            },
        }));
    };

    const generateTagsForm = useMemo(() => {
        const generateInput = (tagSettings: any) => {
            let label = tagSettings.tooltip ? (
                <Tooltip label={tagSettings.tooltip}>
                    <Group spacing={2} sx={{ alignItems: "center" }}>
                        <InfoCircle size={14} />
                        <Text>{tagSettings.title}</Text>
                    </Group>
                </Tooltip>
            ) : (
                <Text>{tagSettings.title}</Text>
            );
            switch (tagSettings.values.type) {
                case "select":
                    return (
                        <Select
                            label={label}
                            placeholder="Pick one"
                            data={tagSettings.values.options}
                            value={tagSettings.value}
                            onChange={(v) =>
                                tagsOptionOnChange(tagSettings.title, v)
                            }
                        />
                    );
                case "boolean":
                    return (
                        <Flex sx={{ flexDirection: "row", gap: 8 }}>
                            <Checkbox
                                checked={tagSettings.value}
                                onChange={(event) =>
                                    tagsOptionOnChange(
                                        tagSettings.title,
                                        event.currentTarget.checked
                                    )
                                }
                                label={label}
                            />
                        </Flex>
                    );
            }
        };

        const selects = [] as any[];
        const booleans = [] as any[];

        Object.values(tagForm).forEach((tag: any) => {
            if (tag.values.type === "boolean") {
                booleans.push(tag);
            }

            if (tag.values.type === "select") {
                selects.push(tag);
            }
        });

        return (
            <Stack>
                {selects.map((tag: any) => (
                    <React.Fragment key={`tag-${tag.title}`}>
                        {generateInput(tag)}
                    </React.Fragment>
                ))}
                <Grid>
                    {booleans.map((tag: any) => (
                        <Grid.Col md={3} sm={6} xs={6} key={`boolean-tag-${tag.title}`}>
                            {generateInput(tag)}
                        </Grid.Col>
                    ))}
                </Grid>
            </Stack>
        );
    }, [tagForm, form.values]);

    const allTagsReady = () => {
        const fillableInputs = Object.values(tagForm).filter(
            (t: any) => t.values.type !== "boolean"
        );

        const total = fillableInputs.length;
        const notEmptyCount = fillableInputs.filter(
            (t: any) => !!t.value
        ).length;

        return (
            total === notEmptyCount
        );
    };

    const setTransactionType = (transactionType: PROPERTY_REQUEST_TYPE) => {
        form.setFieldValue("transactionType", transactionType);
        // setActiveTab(1);

        // const releventTags = tags.filter((t: any) => {
        //     return t.values.transactionType.includes(transactionType);
        // });

        // if (releventTags.length > 0) {
        //     let tagsInput = {} as any;
        //     releventTags.forEach((tag: any) => {
        //         let defaultValue = tag.values.type !== "boolean" ? "" : false;

        //         tagsInput[tag.title] = { ...tag, value: defaultValue };
        //     });
        //     setTagForm(tagsInput);
        // }
    };

    const TransactionTypeSelect: React.FC<any> = ({ ...props }) => {
        return (
            <Grid>
                {Object.values(propertyRequestOptions).map((v, i) => (
                    <Grid.Col sm={4} xs={12} key={`transactionType-${i}`}>
                        <Box
                            key={`select-${v.key}`}
                            sx={
                                form.values.transactionType === v.key
                                    ? {
                                        backgroundColor:
                                            "rgba(78, 122, 77, 0.7)",
                                        borderRadius: 8,
                                        border: "2px solid #417256",
                                        cursor: "pointer",
                                        flex: 1,
                                        height: "100%",
                                        color: "#fff",
                                    }
                                    : {
                                        "&:hover": {
                                            backgroundColor: "#eaedeb",
                                            borderRadius: 8,
                                            border: "2px solid #417256",
                                        },
                                        backgroundColor: "#fff",
                                        borderRadius: 8,
                                        border: "2px solid #bfc8c3",
                                        cursor: "pointer",
                                        flex: 1,
                                        height: "100%",
                                    }
                            }
                            p={16}
                            onClick={() => {
                                setTransactionType(v.key);
                            }}
                        >
                            <Stack
                                align="flex-start"
                                spacing={16}
                                sx={{ height: "100%" }}
                            >
                                <Center
                                    sx={{
                                        borderRadius: 8,
                                        background: "#fff",
                                        // "&:hover": {
                                        //     background: "#fff",
                                        // },
                                    }}
                                    p={8}
                                >
                                    {v.image}
                                </Center>
                                <Stack align="flex-start" spacing={4}>
                                    <Text fw={700} sx={(theme) => ({
                                        fontSize: "2vw",
                                        [theme.fn.smallerThan("md")]: {
                                            fontSize: "26px",
                                        },
                                    })}>
                                        {v.title}
                                    </Text>
                                    <Text>{v.desc}</Text>
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid.Col>
                ))}
            </Grid>
        );
    };

    const NextButtonRow: React.FC<any> = ({ ...props }) => {
        const currentStep = parseInt(props.currStep);
        return (
            <Group position="right" sx={{ width: "100%" }} mt={16}>
                {props.hasBack && (
                    <Button
                        onClick={() => {
                            setAccordionStep((currentStep - 1).toString());
                        }}
                        variant="subtle"
                    >
                        Back
                    </Button>
                )}
                {props.hasNext && (
                    <Button
                        disabled={props.disabled}
                        onClick={() => {
                            props.nextFn
                                ? props.nextFn()
                                : setAccordionStep(
                                    (currentStep + 1).toString()
                                );
                        }}
                    >
                        Next
                    </Button>
                )}
            </Group>
        );
    };

    const getTagDisplayText = (tag: any, value: string) => {
        const displayText = tag.values.options.find(
            (o: any) => o.value === value
        );

        if (!displayText) {
            return "";
        }

        return displayText.label;
    };

    const translateToTags = () => {
        if (!tagForm) {
            return <></>;
        }

        const badgeArr = Object.values(tagForm)
            .map((tag: any) => {
                if (tag.values.type === "boolean" && !!tag.value) {
                    return {
                        title: tag.title,
                        color: tag.badgeColor,
                        background: tag.badgeBackground,
                    };
                }

                if (tag.values.type === "select") {
                    return {
                        title: getTagDisplayText(tag, tag.value),
                        color: tag.badgeColor,
                        background: tag.badgeBackground,
                    };
                }

                return null;
            })
            .filter((t: any) => !!t);

        return (
            <Group>
                {badgeArr.map((b: any, i: number) => (
                    <Badge
                        key={`badge-${i}`}
                        sx={{ backgroundColor: b.background, color: b.color }}
                    >
                        {b?.title}
                    </Badge>
                ))}
            </Group>
        );
    };

    const EditSectionHeader = ({ ...props }) => {
        return (
            <Group position="apart">
                <Text fz={18} fw={700}>
                    {props.title}
                </Text>
                <Text
                    fz={14}
                    td="underline"
                    sx={{ cursor: "pointer" }}
                    onClick={props.onClick}
                >
                    Edit
                </Text>
            </Group>
        );
    };

    const formCheck = useMemo(() => {

        let detailReady = false

        if (form.values?.transactionType === "RENT") {
            detailReady = !!form.values.propertyType && !!form.values.seriousnessRating
        } else {
            detailReady = !!form.values.propertyType &&
                !!form.values.tenure &&
                !!form.values.propertyTitleType &&
                !!form.values.transactionType &&
                !!form.values.seriousnessRating
        }
        return {
            detail: detailReady,
            accordion: {
                [`${CREATE_REQUEST_STEPS.DETAILS}`]: allTagsReady(),
                [`${CREATE_REQUEST_STEPS.LOCATION}`]:
                    !!form.values.state && !!form.values.area,
                [`${CREATE_REQUEST_STEPS.SIZE_AND_AMMENITIES}`]:
                    !Number.isNaN(form.values.noOfBathrooms) &&
                    !Number.isNaN(form.values.noOfBedrooms) &&
                    !!form.values.minSquareFt &&
                    !!form.values.maxSquareFt,
                [`${CREATE_REQUEST_STEPS.BUDGET}`]:
                    (!!form.values.budgetMin && !!form.values.budgetMax) || !!form.values.incomeRange,
                allReady:
                    !!allTagsReady() &&
                    !!form.values.state &&
                    !!form.values.area &&
                    !!form.values.noOfBathrooms &&
                    !!form.values.noOfBedrooms &&
                    !!form.values.minSquareFt &&
                    !!form.values.maxSquareFt &&
                    !!form.values.budgetMin &&
                    !!form.values.budgetMax &&
                    !!form.values.additionalNotes
            },
        };
    }, [form.values, tagForm]);

    const transactionTypeBadgeSx = useMemo(() => {
        if (!!form.values.transactionType) {
            const transactionType = form.values.transactionType as
                | "BUY"
                | "RENT"
                | "INVEST";
            return {
                color: PROPERTY_REQUEST_COLOR_SETTINGS[transactionType].color,
                backgroundColor:
                    PROPERTY_REQUEST_COLOR_SETTINGS[transactionType].background,
            };
        }

        return {};
    }, [form.values]);

    const onFirstStepDone = () => {
        const releventTags = tags.filter((t: any) => {
            return (
                t.values.transactionType.includes(
                    form.values.transactionType
                ) && t.values.propertyTypes.includes(form.values.propertyType)
            );
        });

        if (releventTags.length > 0) {
            let tagsInput = {} as any;
            // console.log("releventTags", releventTags);
            releventTags.forEach((tag: any) => {
                let defaultValue = tag.values.type !== "boolean" ? "" : false;

                tagsInput[tag.title] = {
                    ...tag,
                    value: defaultValue,
                    tootip: tag.tooltip,
                };
            });
            setTagForm(tagsInput);
        }

        if (!!noBedroomsOption.includes(form.values.propertyType)) {
            form.setFieldValue("noOfBedrooms", "0");
        }

        setActiveTab(1);
    };

    const formatFirstTimeText = useCallback(() => {
        if (!!form.values.transactionType) {
            switch (form.values.transactionType) {
                case "BUY":
                    return "buying";
                case "RENT":
                    return "renting";
                case "INVEST":
                    return "investing";
            }
        }

        return "";
    }, [form.values.transactionType]);

    const handleExit = useCallback(() => {
        if (!!user.jwt) {
            return navigate("/profile")
        }
        return navigate("/")
    }, [user])

    const generateAdditionalInputPlaceholder = useCallback(() => {
        if (form.values.transactionType === "") {
            return "Tell us more..."
        }

        switch (form.values.transactionType) {
            case "BUY":
                return "e.g. I'm looking for a unit with easy access to groceries...";
            case "RENT":
                return "e.g. I'm a student studying in subang jaya...";
            case "INVEST":
                return "e.g. I'm looking for a unit to run an airbnb...";
        }

    }, [form.values])

    const shouldBudgetGoNext = useMemo(() => {
        if (form.values.transactionType === "RENT") {
            return !form.values.budgetMin || !form.values.budgetMax
        } else {
            return !form.values.incomeRange
        }

    }, [form.values])

    if (!tags) return <></>;

    return (
        <>
            <Modal
                centered
                opened={hasDraft}
                onClose={() => setDraftPopup({})}
                title="Continue Draft"
            >
                <Card>
                    <Stack spacing={24} align="center">
                        <Text
                            align="center"
                            component="h1"
                            m={0}
                            fw={700}
                            fz={18}
                        >
                            Would you like to continue <br />
                            where you left off
                        </Text>
                        <Group sx={{ width: "100%" }}>
                            <Button
                                onClick={() => setDraftPopup({})}
                                sx={{ flexGrow: 1 }}
                                variant="outline"
                            >
                                No
                            </Button>
                            <Button
                                onClick={() => importDraft()}
                                sx={{ flexGrow: 1 }}
                            >
                                Yes
                            </Button>
                        </Group>
                    </Stack>
                </Card>
            </Modal>
            <Flex
                sx={{
                    minHeight: "100vh",
                    width: "100vw",
                    flexDirection: isMobile ? "column" : "row",
                }}
                m={0}
                align="stretch"
            >
                <Box sx={sidebarStyles}>
                    <Flex align="center" sx={stepperStyle}>
                        <Image
                            src="/logo.png"
                            width={isMobile ? 105 : 142}
                            height={isMobile ? 24 : 32}
                            onClick={() => navigate("/")}
                            sx={{ cursor: "pointer" }}
                        />
                        <Stepper
                            active={activeTab}
                            onStepClick={setActiveTab}
                            orientation={isMobile ? "horizontal" : "vertical"}
                            size="md"
                            iconSize={32}
                        >
                            <Stepper.Step
                                label={isMobile ? null : "Step 1"}
                                description={
                                    isMobile ? null : (
                                        <Text pr={8}>
                                            What are you looking for?
                                        </Text>
                                    )
                                }
                                allowStepSelect={false}
                            />
                            <Stepper.Step
                                label={isMobile ? null : "Step 2"}
                                description={
                                    isMobile ? null : (
                                        <Text pr={8}>Advanced Details</Text>
                                    )
                                }
                                allowStepSelect={false}
                            />
                            <Stepper.Step
                                label={isMobile ? null : "Step 3"}
                                description={
                                    isMobile ? null : <Text pr={8}>Review</Text>
                                }
                                allowStepSelect={false}
                            />
                            {!user.jwt && (
                                <Stepper.Step
                                    label={isMobile ? null : "Step 4"}
                                    description={
                                        isMobile ? null : <Text pr={8}>Register</Text>
                                    }
                                    allowStepSelect={false}
                                />
                            )}
                        </Stepper>
                        {!isMobile && (
                            <Stack
                                // p={16}
                                sx={{ flex: 1, width: "100%" }}
                                justify="flex-end"
                                align="flex-start"
                            >
                                <Button
                                    sx={{ width: '100%' }}
                                    color="red"
                                    onClick={() => handleExit()}
                                >
                                    Exit
                                </Button>
                            </Stack>
                        )}
                    </Flex>
                </Box>
                <Box sx={{ backgroundColor: "#F5F5F5", flex: 1 }}>
                    <Container size="800px" px="md" py="lg">
                        <Stack spacing={16}>
                            {activeTab !== 3 && (
                                <Stack spacing={8}>
                                    <Text fz={32} fw={700}>
                                        {id
                                            ? "Update request"
                                            : "What are you looking for?"}
                                    </Text>
                                    <Text fz={12}>
                                        Provide us with detailed information to
                                        connect with specialized agents with ease
                                    </Text>
                                </Stack>
                            )}

                            {activeTab === 0 && (
                                <Stack
                                    sx={{ height: "100%", paddingBottom: 68 }}
                                >
                                    <TransactionTypeSelect />
                                    <Stack>
                                        {!!form.values.transactionType && (
                                            <>
                                                <Checkbox
                                                    label={`I am ok with sub sales`}
                                                    checked={
                                                        form.values.subSalesOk
                                                    }
                                                    onChange={(event) =>
                                                        form.setFieldValue(
                                                            "subSalesOk",
                                                            event.currentTarget
                                                                .checked
                                                        )
                                                    }
                                                />
                                                {form.values.transactionType !== "RENT" && (
                                                    <Checkbox
                                                        label={`I am ok with new projects`}
                                                        checked={
                                                            form.values.newProjectsOnly
                                                        }
                                                        onChange={(event) =>
                                                            form.setFieldValue(
                                                                "newProjectsOnly",
                                                                event.currentTarget
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                                )}
                                                <Checkbox
                                                    label={`This is my first time ${formatFirstTimeText()}`}
                                                    checked={
                                                        form.values.isFirstTime
                                                    }
                                                    onChange={(event) =>
                                                        form.setFieldValue(
                                                            "isFirstTime",
                                                            event.currentTarget
                                                                .checked
                                                        )
                                                    }
                                                />
                                                <Checkbox
                                                    label={
                                                        <Stack spacing={2}>
                                                            <Text>Don't share my number with agents</Text>
                                                        </Stack>
                                                    }
                                                    checked={
                                                        !form.values.showPhoneNumber
                                                    }
                                                    onChange={(event) =>
                                                        form.setFieldValue(
                                                            "showPhoneNumber",
                                                            !event.currentTarget
                                                                .checked
                                                        )
                                                    }
                                                />
                                                <Checkbox
                                                    label={
                                                        <Stack spacing={2}>
                                                            <Text>Is this request urgent?</Text>
                                                            {/* <Text fz={'xs'} color="red">This reveals your phone number and email to agents</Text> */}
                                                        </Stack>
                                                    }
                                                    checked={
                                                        form.values.isUrgent
                                                    }
                                                    onChange={(event) =>
                                                        form.setFieldValue(
                                                            "isUrgent",
                                                            event.currentTarget
                                                                .checked
                                                        )
                                                    }
                                                />
                                            </>
                                        )}
                                        <Select
                                            label="Which of this statements best describes your request?"
                                            placeholder="Pick one"
                                            data={seriousnessRatingOptions}
                                            {...form.getInputProps(
                                                "seriousnessRating"
                                            )}
                                        />
                                        <Select
                                            label="Property Type"
                                            placeholder="Pick one"
                                            data={propertyTypes}
                                            // disabled
                                            disabled={propertyTypes.length <= 1}
                                            searchable
                                            nothingFound="No options"
                                            filter={selectFilter}
                                            {...form.getInputProps(
                                                "propertyType"
                                            )}
                                        />
                                        {form.values.transactionType !== "RENT" && (
                                            <>
                                                <Select
                                                    label="Tenure"
                                                    placeholder="Pick one"
                                                    data={propRequestTenureOptions}
                                                    {...form.getInputProps("tenure")}
                                                />
                                                <Select
                                                    label="Property Title Type"
                                                    placeholder="Pick one"
                                                    data={
                                                        propRequestPropertyTitleOptions
                                                    }
                                                    {...form.getInputProps(
                                                        "propertyTitleType"
                                                    )}
                                                />
                                            </>
                                        )}
                                    </Stack>
                                    <Group
                                        // position="apart"
                                        sx={{
                                            width: isMobile
                                                ? "100%"
                                                : "calc(100% - 250px)",
                                            justifyContent: isMobile ? 'space-between' : 'flex-end',
                                            position: "fixed",
                                            bottom: 0,
                                            right: 0,
                                            background: "white",
                                        }}
                                        p={16}
                                    >
                                        {!!isMobile && (
                                            <Button
                                                color="red"
                                                onClick={() => handleExit()}
                                                size="xs"
                                            >
                                                Exit
                                            </Button>
                                        )}
                                        <Button
                                            disabled={!formCheck.detail}
                                            onClick={() => {
                                                onFirstStepDone();
                                            }}
                                            size="xs"
                                        >
                                            Continue
                                        </Button>
                                    </Group>
                                </Stack>
                            )}
                            {activeTab === 1 && (
                                <Stack sx={{ position: "relative" }} pb={84}>
                                    <Stack>
                                        <Card
                                            sx={{ border: "1px solid #BFC8C3" }}
                                        >
                                            <Stack>
                                                <EditSectionHeader
                                                    title={
                                                        "Property Request Type"
                                                    }
                                                    onClick={() => {
                                                        setActiveTab(0);
                                                    }}
                                                />
                                                <Stack>
                                                    <Group>
                                                        <Badge
                                                            sx={transactionTypeBadgeSx}
                                                        >
                                                            {form.values.transactionType}
                                                        </Badge>
                                                    </Group>
                                                    <Group spacing={4}>
                                                        <Text fw={700}>
                                                            Property Type:{" "}
                                                        </Text>
                                                        <Text fw={400}>
                                                            {getReadableValueFromSelect(
                                                                propertyTypes,
                                                                form.values
                                                                    .propertyType
                                                            )}
                                                        </Text>
                                                    </Group>
                                                    {form.values.transactionType !== "RENT" && (
                                                        <>
                                                            <Group spacing={4}>
                                                                <Text fw={700}>
                                                                    Tenure:{" "}
                                                                </Text>
                                                                <Text fw={400}>
                                                                    {getReadableValueFromSelect(
                                                                        propRequestTenureOptions,
                                                                        form.values
                                                                            .tenure
                                                                    )}
                                                                </Text>
                                                            </Group>
                                                            <Group spacing={4}>
                                                                <Text fw={700}>
                                                                    Property Title Type:
                                                                </Text>
                                                                <Text fw={400}>
                                                                    {getReadableValueFromSelect(
                                                                        propRequestPropertyTitleOptions,
                                                                        form.values
                                                                            .propertyTitleType
                                                                    )}
                                                                </Text>
                                                            </Group>
                                                        </>
                                                    )}
                                                </Stack>
                                            </Stack>
                                        </Card>
                                    </Stack>
                                    <Accordion
                                        value={accordionStep}
                                        chevron={<></>}
                                        disableChevronRotation
                                    >
                                        <Accordion.Item
                                            value={`${CREATE_REQUEST_STEPS.DETAILS}`}
                                            sx={{ borderBottom: "none" }}
                                        >
                                            <Accordion.Control
                                                sx={{
                                                    background: "transparent",
                                                    "&:hover": {
                                                        background:
                                                            "transparent",
                                                    },
                                                }}
                                                px={0}
                                                chevron={
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .DETAILS
                                                    ] ? (
                                                        <CircleCheck
                                                            size={48}
                                                            strokeWidth={2}
                                                            color={"green"}
                                                        />
                                                    ) : null
                                                }
                                                onClick={() => {
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .DETAILS
                                                    ] &&
                                                        setAccordionStep(
                                                            CREATE_REQUEST_STEPS.DETAILS.toString()
                                                        );
                                                }}
                                            // disabled={parseInt(accordionStep) < i + 1}
                                            >
                                                1. Let us know the details!
                                            </Accordion.Control>
                                            <Accordion.Panel px={0}>
                                                <Stack>
                                                    {/* {!!form.values.} */}
                                                    {generateTagsForm}
                                                </Stack>
                                                <NextButtonRow
                                                    hasNext
                                                    currStep={`${CREATE_REQUEST_STEPS.DETAILS}`}
                                                    disabled={!allTagsReady()}
                                                />
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            value={`${CREATE_REQUEST_STEPS.LOCATION}`}
                                            sx={{ borderBottom: "none" }}
                                        >
                                            <Accordion.Control
                                                sx={{
                                                    background: "transparent",
                                                    "&:hover": {
                                                        background:
                                                            "transparent",
                                                    },
                                                }}
                                                px={0}
                                                chevron={
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .LOCATION
                                                    ] ? (
                                                        <CircleCheck
                                                            size={48}
                                                            strokeWidth={2}
                                                            color={"green"}
                                                        />
                                                    ) : null
                                                }
                                                onClick={() => {
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .LOCATION
                                                    ] &&
                                                        setAccordionStep(
                                                            CREATE_REQUEST_STEPS.LOCATION.toString()
                                                        );
                                                }}
                                            >
                                                2. Where are you looking to{" "}
                                                {form.values.transactionType.toLowerCase()}
                                                ?
                                            </Accordion.Control>
                                            <Accordion.Panel px={0}>
                                                <Stack>
                                                    <Select
                                                        label="State"
                                                        placeholder="Pick one"
                                                        data={states}
                                                        searchable
                                                        nothingFound="No options"
                                                        filter={selectFilter}
                                                        {...form.getInputProps(
                                                            "state"
                                                        )}
                                                        onChange={(e) => {
                                                            form.setFieldValue(
                                                                "state",
                                                                e || ""
                                                            );
                                                            form.setFieldValue(
                                                                "area",
                                                                ""
                                                            );
                                                        }}
                                                    />
                                                    <Select
                                                        label="Area"
                                                        placeholder="Pick one"
                                                        data={
                                                            !form.values.state
                                                                ? []
                                                                : areas
                                                        }
                                                        disabled={
                                                            !form.values.state
                                                        }
                                                        searchable
                                                        nothingFound="No options"
                                                        filter={selectFilter}
                                                        {...form.getInputProps(
                                                            "area"
                                                        )}
                                                    />
                                                    <TextInput
                                                        label="Postcode(optional)"
                                                        {...form.getInputProps(
                                                            "postcode"
                                                        )}
                                                    />
                                                </Stack>
                                                <NextButtonRow
                                                    hasNext
                                                    hasBack={true}
                                                    currStep={`${CREATE_REQUEST_STEPS.LOCATION}`}
                                                    disabled={
                                                        !form.values.state ||
                                                        !form.values.area
                                                    }
                                                />
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            value={`${CREATE_REQUEST_STEPS.SIZE_AND_AMMENITIES}`}
                                            sx={{ borderBottom: "none" }}
                                        >
                                            <Accordion.Control
                                                sx={{
                                                    background: "transparent",
                                                    "&:hover": {
                                                        background:
                                                            "transparent",
                                                    },
                                                }}
                                                px={0}
                                                chevron={
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .SIZE_AND_AMMENITIES
                                                    ] ? (
                                                        <CircleCheck
                                                            size={48}
                                                            strokeWidth={2}
                                                            color={"green"}
                                                        />
                                                    ) : null
                                                }
                                                onClick={() => {
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .SIZE_AND_AMMENITIES
                                                    ] &&
                                                        setAccordionStep(
                                                            CREATE_REQUEST_STEPS.SIZE_AND_AMMENITIES.toString()
                                                        );
                                                }}
                                            >
                                                3. What is your desired size?
                                            </Accordion.Control>
                                            <Accordion.Panel px={0}>
                                                <SizeSection form={form} showBedrooms={!noBedroomsOption.includes(
                                                    form.values.propertyType
                                                )} />

                                                <NextButtonRow
                                                    hasNext
                                                    hasBack={true}
                                                    currStep={`${CREATE_REQUEST_STEPS.SIZE_AND_AMMENITIES}`}
                                                    disabled={
                                                        !formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .SIZE_AND_AMMENITIES
                                                        ]
                                                    }
                                                />
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            value={`${CREATE_REQUEST_STEPS.BUDGET}`}
                                            sx={{ borderBottom: "none" }}
                                        >
                                            <Accordion.Control
                                                sx={{
                                                    background: "transparent",
                                                    "&:hover": {
                                                        background:
                                                            "transparent",
                                                    },
                                                }}
                                                px={0}
                                                chevron={
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .BUDGET
                                                    ] ? (
                                                        <CircleCheck
                                                            size={48}
                                                            strokeWidth={2}
                                                            color={"green"}
                                                        />
                                                    ) : null
                                                }
                                                onClick={() => {
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .BUDGET
                                                    ] &&
                                                        setAccordionStep(
                                                            CREATE_REQUEST_STEPS.BUDGET.toString()
                                                        );
                                                }}
                                            >
                                                4. Tell us your {form.values.transactionType === "RENT" ? "budget" : "income range"}
                                            </Accordion.Control>
                                            <Accordion.Panel px={0}>
                                                <BudgetInput form={form} />
                                                <NextButtonRow
                                                    hasNext
                                                    hasBack={true}
                                                    currStep={`${CREATE_REQUEST_STEPS.BUDGET}`}
                                                    disabled={shouldBudgetGoNext}
                                                />
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            value={`${CREATE_REQUEST_STEPS.ADDITIONAL_NOTES}`}
                                            sx={{ borderBottom: "none" }}
                                        >
                                            <Accordion.Control
                                                sx={{
                                                    background: "transparent",
                                                    "&:hover": {
                                                        background:
                                                            "transparent",
                                                    },
                                                }}
                                                px={0}
                                                onClick={() => {
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .BUDGET
                                                    ] &&
                                                        setAccordionStep(
                                                            CREATE_REQUEST_STEPS.ADDITIONAL_NOTES.toString()
                                                        );
                                                }}
                                            >
                                                5. Describe your dream property further! We'd
                                                love to hear more from you
                                            </Accordion.Control>
                                            <Accordion.Panel px={0}>
                                                <Stack spacing={4} sx={{ width: '100%' }}>
                                                    <Textarea
                                                        label="Additional Notes"
                                                        {...form.getInputProps(
                                                            "additionalNotes"
                                                        )}
                                                        placeholder={generateAdditionalInputPlaceholder()}
                                                        error={form.values.additionalNotes.length < 20 ?? "Please input at least 20 characters"}
                                                    />
                                                    <Group sx={{ width: '100%' }} position="right">
                                                        <Text size={'xs'}>
                                                            {form.values.additionalNotes.length}/500
                                                        </Text>
                                                    </Group>
                                                </Stack>
                                                <NextButtonRow
                                                    hasNext
                                                    nextFn={() => {
                                                        setActiveTab(2);
                                                    }}
                                                    hasBack={true}
                                                    currStep={`${CREATE_REQUEST_STEPS.ADDITIONAL_NOTES}`}
                                                    isFinal={true}
                                                    disabled={!form.values.additionalNotes || form.values.additionalNotes.length < 20}
                                                />
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                    </Accordion>

                                    <Group
                                        sx={{
                                            width: isMobile
                                                ? "100%"
                                                : "calc(100% - 250px)",
                                            justifyContent: isMobile ? 'space-between' : 'flex-end',
                                            position: "fixed",
                                            bottom: 0,
                                            right: 0,
                                            background: "white",
                                        }}
                                        p={16}
                                    >

                                        {!!isMobile && (
                                            <Group>
                                                <Button
                                                    color="red"
                                                    onClick={() => handleExit()}
                                                    size="xs"
                                                >
                                                    Exit
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setActiveTab(0);
                                                    }}
                                                    variant="subtle"
                                                    size="xs"
                                                >
                                                    Back
                                                </Button>
                                            </Group>
                                        )}
                                        <Group
                                            position="right"
                                            p={0}
                                        // sx={{ flexGrow: 1 }}
                                        >
                                            {!!formCheck.accordion.allReady && (
                                                <Button
                                                    onClick={() => {
                                                        setActiveTab(2);
                                                    }}
                                                    size="xs"
                                                >
                                                    Next
                                                </Button>
                                            )}
                                        </Group>
                                    </Group>
                                </Stack>
                            )}
                            {activeTab === 2 && (
                                <Stack>
                                    <Card sx={{ border: "1px solid #BFC8C3" }}>
                                        <Stack>
                                            <EditSectionHeader
                                                title={"Property Request Type"}
                                                onClick={() => {
                                                    setActiveTab(0);
                                                }}
                                            />
                                            <Group>
                                                <Badge
                                                    sx={transactionTypeBadgeSx}
                                                >{form.values.transactionType}
                                                </Badge>
                                            </Group>
                                        </Stack>
                                    </Card>
                                    <Card sx={{ border: "1px solid #BFC8C3" }}>
                                        <Stack>
                                            <Stack spacing={0}>
                                                <Text fz={20} fw={700} pb={8}>
                                                    Advanced Details
                                                </Text>
                                                <Divider my={8} />
                                            </Stack>
                                            <EditSectionHeader
                                                title={"Request Details"}
                                                onClick={() => {
                                                    setActiveTab(1);
                                                    setAccordionStep(
                                                        CREATE_REQUEST_STEPS.DETAILS.toString()
                                                    );
                                                }}
                                            />
                                            <Stack>
                                                <Group spacing={4}>
                                                    <Text fw={700}>
                                                        Property Type:{" "}
                                                    </Text>
                                                    <Text fw={400}>
                                                        {getReadableValueFromSelect(
                                                            propertyTypes,
                                                            form.values
                                                                .propertyType
                                                        )}
                                                    </Text>
                                                </Group>
                                                <Group spacing={4}>
                                                    <Text fw={700}>
                                                        Tenure:{" "}
                                                    </Text>
                                                    <Text fw={400}>
                                                        {getReadableValueFromSelect(
                                                            propRequestTenureOptions,
                                                            form.values.tenure
                                                        )}
                                                    </Text>
                                                </Group>
                                                <Group spacing={4}>
                                                    <Text fw={700}>
                                                        Property Title Type:
                                                    </Text>
                                                    <Text fw={400}>
                                                        {getReadableValueFromSelect(
                                                            propRequestPropertyTitleOptions,
                                                            form.values
                                                                .propertyTitleType
                                                        )}
                                                    </Text>
                                                </Group>
                                            </Stack>
                                            <Group>
                                                <Text fw={700}>Tags: </Text>
                                                {translateToTags()}
                                            </Group>
                                            <Divider my={8} />
                                            <EditSectionHeader
                                                title={"Location"}
                                                onClick={() => {
                                                    setActiveTab(1);
                                                    setAccordionStep(
                                                        CREATE_REQUEST_STEPS.LOCATION.toString()
                                                    );
                                                }}
                                            />
                                            <Stack>
                                                <Text>
                                                    {form.values.postcode ?? ""}
                                                    {form.values.area},{" "}
                                                    {form.values.state}
                                                </Text>
                                            </Stack>
                                            <Divider my={8} />
                                            <EditSectionHeader
                                                title={
                                                    "Property Size and Amenities"
                                                }
                                                onClick={() => {
                                                    setActiveTab(1);
                                                    setAccordionStep(
                                                        CREATE_REQUEST_STEPS.SIZE_AND_AMMENITIES.toString()
                                                    );
                                                }}
                                            />
                                            <Group>
                                                <Group spacing={8}>
                                                    <Text>
                                                        {
                                                            form.values
                                                                .minSquareFt
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                            form.values
                                                                .maxSquareFt
                                                        }{" "}
                                                        sqft
                                                    </Text>
                                                </Group>
                                                <Group spacing={8}>
                                                    <Bed />
                                                    <Text>
                                                        {
                                                            form.values
                                                                .noOfBedrooms
                                                        }{" "}
                                                        Bedroom
                                                    </Text>
                                                </Group>
                                                <Group spacing={8}>
                                                    <Bath />
                                                    <Text>
                                                        {
                                                            form.values
                                                                .noOfBathrooms
                                                        }{" "}
                                                        Bathroom
                                                    </Text>
                                                </Group>
                                            </Group>
                                            <Divider my={8} />
                                            <EditSectionHeader
                                                title={form.values.transactionType === "RENT" ? "Budget" : "Income Range"}
                                                onClick={() => {
                                                    setActiveTab(1);
                                                    setAccordionStep(
                                                        CREATE_REQUEST_STEPS.BUDGET.toString()
                                                    );
                                                }}
                                            />
                                            <Group spacing={8}>
                                                {form.values.transactionType === "RENT" ? (
                                                    <Text>
                                                        RM
                                                        {form.values.budgetMin.toLocaleString()}{" "}
                                                        - RM
                                                        {form.values.budgetMax.toLocaleString()}
                                                    </Text>
                                                ) : (
                                                    <Text>{form.values.incomeRange} /month</Text>

                                                )}
                                            </Group>
                                            <Divider my={8} />
                                            <EditSectionHeader
                                                title={"Additional Notes"}
                                                onClick={() => {
                                                    setActiveTab(1);
                                                    setAccordionStep(
                                                        CREATE_REQUEST_STEPS.ADDITIONAL_NOTES.toString()
                                                    );
                                                }}
                                            />
                                            <Group spacing={8}>
                                                <Text>
                                                    {!!form.values
                                                        .additionalNotes
                                                        ? form.values
                                                            .additionalNotes
                                                        : "-"}
                                                </Text>
                                            </Group>
                                        </Stack>
                                    </Card>

                                    <Group
                                        position="right"
                                        sx={{ width: "100%" }}
                                        mt={16}
                                    >
                                        <Button
                                            onClick={() => {
                                                setActiveTab(1);
                                            }}
                                            variant="subtle"
                                        >
                                            Back
                                        </Button>
                                        {!!id ? (
                                            <Button
                                                onClick={() =>
                                                    onUpdateListing()
                                                }
                                                loading={createLoading}
                                            >
                                                Update Request
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={() =>
                                                    handleCreate()
                                                }
                                                loading={createLoading}
                                            >
                                                Create Request
                                            </Button>
                                        )}
                                    </Group>
                                </Stack>
                            )}
                            {activeTab === 3 && (
                                <MiniRegisterForm from="request" />
                            )}
                        </Stack>
                    </Container>
                </Box>
            </Flex>
        </>
    );
}
