import { BackgroundImage, Badge, Box, Button, Card, Flex, Group, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';

const DeveloperCard = ({
    developer,
    onLearnMore,
    onApply,
    disableLearnMore,
    disableApply
}: {
    developer: any,
    onLearnMore: any,
    onApply: any,
    disableLearnMore?: boolean,
    disableApply?: boolean,
}) => {
    const theme = useMantineTheme()
    const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);


    return (
        <Card withBorder shadow="md" radius={16}>
            <Stack>
                <Flex
                    gap={16}
                    sx={{
                        flexDirection: isMd ? "column" : "row"
                    }}
                >
                    <Box p={8} sx={{ border: "1px solid #ddd", borderRadius: 8 }}>
                        <BackgroundImage src={developer.logo?.url} h="128px" w="128px" sx={{ backgroundSize: "contain", backgroundRepeat: "no-repeat" }} />
                    </Box>
                    <Stack sx={{ flex: 1 }}>
                        <Stack spacing={8} sx={{ flex: 1 }}>
                            <Title order={3}>{developer.name}</Title>
                            <Text>{developer.size}</Text>
                            {developer.state && (<Text c="#2e523c" fw="bold">{developer.state}</Text>)}
                        </Stack>
                        {developer.tags && (
                            <Group
                                sx={{ flex: 1, width: "100%", alignItems: "flex-start", justifyContent: "flex-start" }}
                            >{developer.tags.map((t: string) => (<Badge variant='outline'>{t}</Badge>))}</Group>
                        )}
                    </Stack>
                </Flex>
                <Stack>
                    <Text>{developer.description}</Text>
                    <Button onClick={() => onLearnMore()}>Learn More</Button>
                </Stack>
            </Stack>
        </Card>
    );
};

export default DeveloperCard;