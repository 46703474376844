import {
    Accordion,
    Badge,
    Box,
    Button,
    Card,
    Center,
    Checkbox,
    Chip,
    Container,
    Divider,
    Drawer,
    Grid,
    Group,
    Indicator,
    Loader,
    LoadingOverlay,
    MultiSelect,
    NumberInput,
    Pagination,
    RangeSlider,
    ScrollArea,
    Slider,
    Spoiler,
    Stack,
    Sx,
    Tabs,
    Text,
    TextInput,
    useMantineTheme,
} from "@mantine/core";
import { GET_CONFIGS, useGetConfigs } from "api/configAPI";
import PropRequestCard from "components/PropRequest/PropRequestCard";
import {
    CONFIG_KEYS,
    PROPERTY_REQUEST_COLOR_SETTINGS,
    PROPERTY_REQUEST_TYPE,
    USER_TYPE,
} from "helpers/constants";
import { Permission, useAuth } from "hooks/useAuth";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bath, Bed, Edit, Filter, Home2, Search } from "tabler-icons-react";
import { QueryCache } from "@tanstack/react-query";
import { useDebouncedState, useDebouncedValue, useMediaQuery, useViewportSize } from "@mantine/hooks";
import { useWallet } from "hooks/useWallet";
import { useListingAPI } from "api/useListingAPI";
import ListingCard from "components/PropRequest/ListingsCard";
import ListingFilters from "components/ListingFilters";
import LocationFilter from "components/LocationFilter";
import RequestTutorialModal from "components/Modals/RequestTutorialModal";

const defaultFilters = {
    propertyType: [],
    transactionType: [],
    tags: [],
    state: [],
    area: [],

};

const addUrl = (state: any) => {
    if (state.url) {
        delete state.url;
    }
    let andArr = [
        {
            isDeveloperListing: {
                $eq: true
            }
        },
        {
            showAgents: {
                $eq: true
            }
        },
    ] as any[];

    Object.entries(state).forEach(([key, value]) => {
        if (!!value) {
            switch (key) {
                case "noOfBedrooms": {
                    // let price = urlObj[0].$and.
                    if (parseInt(value as string) === 1) {
                        andArr.push({
                            [`noOfBedrooms`]: {
                                $gte: value,
                            },
                        });
                    } else {
                        andArr.push({
                            [`noOfBedrooms`]: {
                                $lte: value,
                            },
                        });
                    }
                    break;
                }
                case "noOfBathrooms": {
                    if (value === 1) {
                        andArr.push({
                            [`noOfBathrooms`]: {
                                $gte: value,
                            },
                        });
                    } else {
                        andArr.push({
                            [`noOfBathrooms`]: {
                                $lte: value,
                            },
                        });
                    }
                    break;
                }
                case "maxSquareFt": {
                    // let price = urlObj[0].$and.
                    andArr.push({
                        [`floorSize`]: {
                            $lte: value,
                        },
                    });
                    break;
                }
                case "minSquareFt": {
                    // let price = urlObj[0].$and.
                    andArr.push({
                        [`floorSize`]: {
                            $gte: value,
                        },
                    });
                    break;
                }
                case "maxPrice": {
                    // let price = urlObj[0].$and.
                    andArr.push({
                        [`price`]: {
                            $lte: value,
                        },
                    });
                    break;
                }
                case "minPrice": {
                    // let price = urlObj[0].$and.
                    andArr.push({
                        [`price`]: {
                            $gte: value,
                        },
                    });
                    break;
                }
                case "propertyType": {
                    let _value = value as any[];
                    // let price = urlObj[0].$and.
                    if (_value.length > 0) {
                        andArr.push({
                            [`${key}`]: {
                                $in: value,
                            },
                        });
                    }
                    break;
                }
                case "transactionType": {
                    let _value = value as any[];
                    // let price = urlObj[0].$and.
                    if (_value.length > 0) {
                        andArr.push({
                            [`${key}`]: {
                                $in: value,
                            },
                        });
                    }
                    break;
                }
                case "tags": {
                    let tagArr = value as any[];
                    // console.log('tags value',value)
                    tagArr.forEach((tag: any) => {
                        andArr.push({
                            tags: tag,
                        });
                    });
                    break;
                }
                default:
                    andArr.push({
                        [`${key}`]: {
                            $in: value,
                        },
                    });
                    break;
            }
        }
    });

    return { $and: andArr };
};

function reducer(state: any, action: any) {
    try {
        let newState = {} as any;
        if (!!state.url) {
            delete state.url;
        }

        switch (action.type) {
            case "LOCATION_SELECT_CHANGE": {
                newState = {
                    ...state,
                    ["state"]: action.payload.state,
                    area: action.payload.area,
                };
                break;
            }
            case "STATE_SELECT_CHANGE": {
                newState = {
                    ...state,
                    ["state"]: action.payload,
                };
                break;
            }
            case "AREA_SELECT_CHANGE": {
                newState = {
                    ...state,
                    area: action.payload,
                };
                break;
            }
            case "BUDGET_MIN_CHANGE": {
                newState = {
                    ...state,
                    minPrice: action.payload,
                };
                break;
            }
            case "BUDGET_MAX_CHANGE": {
                newState = {
                    ...state,
                    maxPrice: action.payload,
                };
                break;
            }
            case "PROPERTY_TYPE_CHANGE": {
                let statePropertyType = state.propertyType ?? [];
                if (!action.include) {
                    statePropertyType = statePropertyType.filter(
                        (f: any) => f !== action.payload
                    );
                } else {
                    statePropertyType = [...statePropertyType, action.payload];
                }

                newState = {
                    ...state,
                    propertyType: statePropertyType,
                };
                break;
            }
            case "PROPERTY_TRANSACTION_TYPE_CHANGE": {
                let stateTransactionType = state.transactionType ?? [];
                if (!action.include) {
                    stateTransactionType = stateTransactionType.filter(
                        (f: any) => f !== action.payload
                    );
                } else {
                    stateTransactionType = [
                        ...stateTransactionType,
                        action.payload,
                    ];
                }

                newState = {
                    ...state,
                    transactionType: stateTransactionType,
                };
                break;
            }
            case "BATHROOM_CHANGE": {
                newState = {
                    ...state,
                    noOfBathrooms: action.payload,
                };
                break;
            }
            case "BEDROOM_CHANGE": {
                newState = {
                    ...state,
                    noOfBedrooms: action.payload,
                };
                break;
            }
            case "MIN_SQFT_CHANGE": {
                newState = {
                    ...state,
                    minSquareFt: action.payload,
                };
                break;
            }
            case "MAX_SQFT_CHANGE": {
                newState = {
                    ...state,
                    maxSquareFt: action.payload,
                };
                break;
            }
            case "TAG_CHANGE": {
                let selectArr = state.tags ?? [];
                if (!action.include) {
                    selectArr = state.tags.filter(
                        (f: any) => f.value !== action.payload
                    );

                    newState = {
                        ...state,
                        tags: selectArr,
                    };
                    break;
                }

                newState = {
                    ...state,
                    tags: [
                        ...state.tags,
                        {
                            value: action.payload,
                        },
                    ],
                };
                break;
            }
            case "RESET": {
                newState = defaultFilters;
                break;
            }
        }

        return newState;
    } catch (e) {
        throw Error("Unknown action: " + action.type);
    }
}

export default function DashboardDeveloperListings({ filterType }: { filterType?: string }) {

    const [state, dispatch] = useReducer(reducer, defaultFilters);
    const navigate = useNavigate();
    const theme = useMantineTheme();

    const isTabletAndAbove = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);
    const { height, width } = useViewportSize();

    const { useGetSearchListings, useGetListingInsights, useGetTags } =
        useListingAPI();

    const { user } = useAuth(Permission.USER_ONLY);
    const { walletData, walletIsLoading, walletError } = useWallet(
        user.userType === USER_TYPE.AGENT ? user.id.toString() : null
    );

    const [searchFilter, setSearchFilter] = useState("");
    const [filterOpen, setFilterOpen] = useState(false);
    const [selectTags, setSelectTags] = useState<any[]>([]);
    const [booleanTags, setBooleanTags] = useState<any[]>([]);
    const [filters, setFilters] = useState<any>({});
    const [debouncedState] = useDebouncedValue(state, 500);

    const [presetDropdown, setPresetDropdown] = useState({
        sqftMin: false,
        sqftMax: false,
    });
    const [total, setTotal] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)


    const generateUrlObj = useCallback(
        (state: any) => {
            // console.log("addurl", addUrl(state));
            return addUrl(state);
        },
        [state]
    );

    const { data, isLoading, error, refetch } = useGetSearchListings({
        filters,
        pagination: {
            page: currentPage,
            pageSize: 10
        }
    });

    const results = useMemo(() => {
        let result = data as any
        if (!!data && !!result.data && !isLoading) {
            setTotal(result.meta.pagination.pageCount)
            setCurrentPage(result.meta.pagination.page)
            return result.data
        }

        return []
    }, [data, isLoading, filters])

    const propertyRequestOptions = [
        PROPERTY_REQUEST_TYPE.BUY,
        PROPERTY_REQUEST_TYPE.RENT,
        PROPERTY_REQUEST_TYPE.INVEST,
    ];

    const {
        data: tagsData,
        isLoading: tagsIsLoading,
        error: tagsError,
    } = useGetTags();

    const {
        data: insightsData,
        isLoading: insightsIsLoading,
        error: insightsError,
    } = useGetListingInsights();

    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    useEffect(() => {
        if (!!tagsData && !tagsIsLoading) {
            const selects = tagsData.filter(
                (tag: any) => tag.values.type === "select"
            );
            const booleans = tagsData.filter(
                (tag: any) => tag.values.type === "boolean"
            );

            setSelectTags(selects);
            setBooleanTags(booleans);
            // setTags([...selects, ...booleans]);
        }
    }, [tagsData, tagsIsLoading]);

    useEffect(() => {
        if (!!insightsData && !insightsIsLoading) {
            // setBudgetFilters([insightsData.rangeMin, insightsData.rangeMax]);
        }
    }, [insightsData]);

    const statesAndAreas = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.STATES_AND_AREAS
            ).value;
        }

        return [];
    }, [configData]);

    function mergeArraysAndRemoveDuplicates<T>(arrays: T[][]): T[] {
        // Merge all arrays into one using the concat method
        const mergedArray: T[] = arrays.reduce(
            (acc, currentArray) => acc.concat(currentArray),
            []
        );

        // Use the Set object to remove duplicates
        const uniqueArray: T[] = Array.from(new Set(mergedArray));

        return uniqueArray;
    }

    const filterCount = useMemo(() => {
        let count = 0;

        Object.entries(state).forEach(([k, s]) => {
            if (k === "tags") {
                count += state.tags.length || 0;
                return;
            }

            if (!s) {
                return;
            }

            if (typeof s === "object") {
                if (Object.keys(s).length === 0) {
                    return;
                } else {
                    count += Object.keys(s).length;
                }
                return;
            }

            count += 1;
            return;
        });
        return count;
    }, [state]);

    const defaultOpenKeys = useMemo(() => {
        let arr = [
            "location",
            "budget",
            "property type",
            "property transaction types",
            "amenities",
            "sqft",
        ] as string[];

        if (selectTags.length > 0) {
            const keys = selectTags.map((s: any) => s.title);
            arr = [...arr, ...keys];
        }

        if (booleanTags.length > 0) {
            arr.push("other");
        }

        return arr;
    }, [selectTags]);

    const handleSearch = (isReset?: boolean) => {
        let urlState = state
        if (!!isReset) {
            urlState = defaultFilters
        }

        setFilters(generateUrlObj(urlState));
    };

    useEffect(() => {
        if (Object.keys(debouncedState).length > 0 && !!isTabletAndAbove) {
            handleSearch();
        }
    }, [debouncedState, isTabletAndAbove]);

    useEffect(() => {
        if (state.state.length > 0 || state.area.length > 0) {
            handleSearch();
        }
    }, [state.state, state.area]);

    const handleClearFilters = () => {
        dispatch({
            type: "RESET",
        });
        handleSearch(true);
    }

    useEffect(() => {
        console.log("filterType", filterType)
    },[filterType])

    return (
        <>
            <Drawer
                opened={filterOpen}
                onClose={() => {
                    setFilterOpen(false);
                    handleSearch();
                }}
                position="right"
            >
                <ListingFilters
                    dispatch={dispatch}
                    state={state}
                    handleClearFilters={handleClearFilters}
                    onClose={() => setFilterOpen(false)}
                    filterType={filterType}
                />
            </Drawer>
            <Container size="1200px" py={32} px={16} m={0}>

                <Stack spacing={24}>
                    <Group position="apart">
                        <Text fz={32} fw={600}>
                            Browse Developer Listings
                        </Text>
                    </Group>
                    <Divider my={8} />
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSearch();
                        }}
                    >
                        <Grid>
                            <Grid.Col sm={12}>
                                {/* <Grid>
                                    <Grid.Col sm={6}>
                                        <MultiSelect
                                            data={statesAndAreas?.map(
                                                (e: any) => e.state
                                            )}
                                            searchable
                                            nothingFound="Nothing found"
                                            value={state.state}
                                            onChange={(e) => {
                                                dispatch({
                                                    type: "STATE_SELECT_CHANGE",
                                                    payload: e,
                                                });
                                            }}
                                            placeholder="filter states"
                                        />
                                    </Grid.Col>
                                    {state.state.length > 0 && (
                                        <Grid.Col sm={6}>
                                            <MultiSelect
                                                data={areasFromStates}
                                                searchable
                                                nothingFound="Nothing found"
                                                value={state.area}
                                                onChange={(e) => {
                                                    dispatch({
                                                        type: "AREA_SELECT_CHANGE",
                                                        payload: e,
                                                    });
                                                    // handleSearch();
                                                }}
                                                placeholder="filter areas"
                                            />
                                        </Grid.Col>
                                    )}
                                </Grid> */}
                                <LocationFilter dispatch={dispatch} state={state} />
                            </Grid.Col>
                            {!isTabletAndAbove && (
                                <Grid.Col sm={12}>
                                    <Group
                                        sx={{
                                            flexWrap: "nowrap",
                                            alignItems: "flex-start",
                                            width: "100%",
                                        }}
                                        spacing={4}
                                    >
                                        <Indicator
                                            label={filterCount}
                                            disabled={filterCount === 0}
                                            size={16}
                                            sx={{ flexGrow: 1 }}
                                            position="top-start"
                                        >
                                            <Button
                                                variant="outline"
                                                leftIcon={<Filter />}
                                                onClick={() => setFilterOpen(true)}
                                                px={8}
                                                sx={{ width: "100%" }}
                                            >
                                                Filters
                                            </Button>
                                        </Indicator>
                                        {filterCount > 0 && (
                                            <Button
                                                variant="subtle"
                                                onClick={() => handleClearFilters()}
                                            >
                                                Clear Filters
                                            </Button>
                                        )}
                                    </Group>
                                </Grid.Col>
                            )}
                        </Grid>
                    </form>
                    <Grid>
                        {!!isTabletAndAbove && (
                            <Grid.Col xs={0} sm={4}>
                                <Stack>
                                    <ListingFilters
                                        dispatch={dispatch}
                                        state={state}
                                        handleClearFilters={handleClearFilters}
                                        filterType={filterType}
                                    />
                                </Stack>
                            </Grid.Col>
                        )}
                        <Grid.Col xs={12} sm={8}>
                            <Stack spacing={16}>
                                {!!isLoading && (
                                    <Center p={24}>
                                        <Loader />
                                    </Center>
                                )}
                                {/* <LoadingOverlay visible={!!isLoading} /> */}
                                {!!results &&
                                    results.length > 0 &&
                                    results.map((propReq: any) => (
                                        <ListingCard data={propReq} vertical={width <= 768} />
                                    ))}
                                {!!results && results.length === 0 && (
                                    <Stack align="center" spacing={8} py={32}>
                                        <Home2 size={32} />
                                        <Text fz={24}>No Data</Text>
                                    </Stack>
                                )}
                                <Group sx={{ width: '100%', justifyContent: 'center' }}>
                                    <Pagination total={total} onChange={setCurrentPage} />
                                </Group>
                            </Stack>
                        </Grid.Col>
                    </Grid>
                </Stack>
            </Container>
        </>
    );
}
