import { ActionIcon, AspectRatio, Badge, Box, Button, Card, Center, Checkbox, Container, Divider, Flex, Grid, Group, Image, Loader, LoadingOverlay, Modal, ScrollArea, Select, Stack, Switch, Text, Textarea, TextInput } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { useScrollIntoView } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useGetConfigs } from 'api/configAPI';
import { useListingAPI } from 'api/useListingAPI';
import axios from 'axios';
import { CONFIG_KEYS, PROPERTY_TITLE_TYPE_OPTIONS, TENURE_OPTIONS, USER_STATUS } from 'helpers/constants';
import { convertFileToPreview, formatEmbedObj, getTagDisplayText, selectFilter } from 'helpers/utility';
import { useUpload } from 'hooks/useUpload';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Files, Plus, Trash } from 'tabler-icons-react';
import ImageInput from './Components/ImageInput';
import VideoEmbedInput from './Components/VideoEmbedInput';
import { Permission, useAuth } from 'hooks/useAuth';
import AccountPendingModal from 'components/Modals/AccountPendingModal';
import { useCanOffer } from 'hooks/useCanOffer';
import BathroomsInput from 'pages/PropRequestForm/components/BathroomsInput';
import BedroomsInput from 'pages/PropRequestForm/components/BedroomsInput';
import SaveAsDraft from './Components/SaveAsDraft';
import { useProfileController } from 'hooks/useProfile';
import { DateInput } from '@mantine/dates';

const titleWordMax = 50
const titleWordMin = 10

const ListingForm = () => {
    const { canOffer, canOfferisLoading } = useCanOffer()
    const { user } = useAuth(Permission.USER_ONLY);
    const { profileData } = useProfileController();
    let { id } = useParams();
    const {
        useGetOneListing,
        useUpdateListing,
        useGetTags,
        useUpdateListingTags,
        useCreateListing,
        useScrape
    } = useListingAPI()

    const { uploadMutation } = useUpload();

    const { scrollIntoView, targetRef } =
        useScrollIntoView<HTMLDivElement, HTMLDivElement>();

    const createListing = useCreateListing()
    const updateListing = useUpdateListing()
    const updateListingTags = useUpdateListingTags()
    const scrape = useScrape()
    const navigate = useNavigate()
    const [states, setStates] = useState([]);

    const [tagsHasError, setTagsHasError] = useState(false)
    const [tagForm, setTagForm] = useState<any>({});
    const [uploadIsLoading, setUploadIsLoading] = useState(false);

    const [formHasError, setFormHasError] = useState(false)

    const [existingImages, setExistingImages] = useState<any[]>([])
    const [removedImages, setRemovedImages] = useState([])
    const [imagesError, setImageError] = useState(false)
    const [files, setFiles] = useState<File[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState({
        done: 0,
        failed: 0,
        started: 0
    })
    const [importImages, setImportImages] = useState<string[]>([]);
    const [base64Preview, setBase64Preview] = useState<any[]>([]);

    const [saveLoading, setSaveLoading] = useState(false)

    const [submitConfirm, setSubmitConfirm] = useState(false)
    const [backConfirm, setBackConfirm] = useState(false)

    const [existingUrlArr, setExistingUrlArr] = useState<any[]>([]);
    const [removedUrl, setRemovedUrl] = useState([])
    const [urlArr, setUrlArr] = useState<any[]>([]);

    const [importPopup, setImportPopup] = useState(false);
    const [importLink, setImportLink] = useState("");
    const [importError, setImportError] = useState<string>("");
    const [importIsLoading, setImportIsLoading] = useState(false);

    const [isAuction, setIsAuction] = useState(false)
    const [showLightbox, setShowLightbox] = useState(false);
    const [isPrivate, setIsPrivate] = useState(true)

    const [showAgents, setShowAgents] = useState(false)
    const [isDeveloperListing, setIsDeveloperListing] = useState(false)

    const form = useForm({
        initialValues: {
            title: "",
            propertyType: "",
            price: "",
            noOfBedrooms: "",
            noOfBathrooms: "",
            floorSize: "",
            landSize: "",
            propertyTitleType: "",
            tenure: "",
            developer: "",
            address: "",
            state: "",
            area: "",
            transactionType: "",
            auctionStart: "",
            auctionEnd: "",
        },
        validate: {
            title: (value) => {
                if (!value) {
                    return "Please fill in this field"
                }

                if (value.length < titleWordMin) {
                    return `At least ${titleWordMin} characters are required`
                }

                if (value.length > titleWordMax) {
                    return `A maximum of ${titleWordMax} characters is allowed`
                }
                return null
            },
            state: (value) => (!!value ? null : "Please fill in this field"),
            area: (value) => (!!value ? null : "Please fill in this field"),
            propertyType: (value) =>
                !!value ? null : "Please fill in this field",
            price: (value) => (!!value ? null : "Please fill in this field"),
            noOfBedrooms: (value) =>
                !!value ? null : "Please fill in this field",
            noOfBathrooms: (value) =>
                !!value ? null : "Please fill in this field",
            floorSize: (value) =>
                !!value ? null : "Please fill in this field",
            // propertyTitleType: (value) =>
            //     !!value ? null : "Please fill in this field",
            // tenure: (value) => (!!value ? null : "Please fill in this field"),
            // developer: (value) =>
            //     !!value ? null : "Please fill in this field",
            address: (value) => (!!value ? null : "Please fill in this field"),
        },
    });

    const {
        data: listingData,
        error: listingError,
        isLoading: listingIsLoading,
        refetch
    } = useGetOneListing(id ?? "");

    const {
        data: tags,
        isLoading: tagsIsLoading,
        error: tagsError,
    } = useGetTags();

    const filterRelevantTags = (transactionType: string, propertyType: string, savedTags?: any[]) => {
        console.log("tags", tags)
        const releventTags = tags.filter((t: any) => {
            const hasTransactionType = t.values.transactionType.includes(
                transactionType
            )

            const hasPropertyType = t.values.propertyTypes.includes(
                propertyType
            )

            return !!hasTransactionType && !!hasPropertyType
        });

        if (releventTags.length > 0) {
            let tagsInput = {} as any;

            releventTags.forEach((tag: any) => {
                let defaultValue = "" as string | boolean;

                if (!!savedTags && savedTags.length > 0) {
                    let savedTag = savedTags.find(
                        (rt: any) => rt.tag.title === tag.title
                    );
                    if (!!savedTag) {
                        if (savedTag.tag.values.type === "boolean") {
                            defaultValue = true;
                        }
                        if (savedTag.tag.values.type === "select") {
                            const option = savedTag.tag.values.options.find(
                                (o: any) => {
                                    return o.label === savedTag.value;
                                }
                            );
                            defaultValue = option?.value ?? "";
                        }
                    } else {
                        defaultValue =
                            tag.values.type !== "boolean" ? "" : false;
                    }
                }

                tagsInput[tag.title] = { ...tag, value: defaultValue };
            });
            setTagForm(tagsInput);
        }
    }

    useEffect(() => {
        if (!!id) {
            refetch()
        }
    }, [])

    const isDeveloperPIC = useMemo(() => {
        return profileData?.developer
    }, [profileData])


    useEffect(() => {
        if(profileData?.developer && !form.values.developer){
            form.setValues({
                developer: profileData?.developer.name
            })
        }
    },[profileData, form.values])


    useEffect(() => {
        if (!!id && !!listingData && !listingIsLoading) {
            form.setValues({
                title: listingData.title,
                propertyType: listingData.propertyType,
                price: listingData.price,
                noOfBedrooms: listingData.noOfBedrooms,
                noOfBathrooms: listingData.noOfBathrooms,
                floorSize: listingData.floorSize,
                landSize: listingData.landSize,
                propertyTitleType: listingData.propertyTitleType,
                tenure: listingData.tenure,
                developer: listingData.developer,
                address: listingData.address,
                state: listingData.state,
                area: listingData.area,
                transactionType: listingData.transactionType,
                auctionEnd: listingData.auctionEnd ? new Date(listingData.auctionEnd) : "",
                auctionStart: listingData.auctionStart ? new Date(listingData.auctionStart) : ""
            } as any)

            setIsAuction(listingData.isAuction ?? false)

            console.log("listingData.images", listingData.images)

            setIsPrivate(listingData.status === "hidden")
            setImportImages(listingData.imageLinks ?? [])
            setExistingImages(listingData.images)
            filterRelevantTags(listingData.transactionType, listingData.propertyType, listingData.tags)
            setUrlArr(formatEmbedObj(listingData.listingEmbeds))
        }
    }, [listingData, listingIsLoading])

    const tagsOptionOnChange = (
        title: string,
        value: string | null | boolean
    ) => {
        if (value === null) {
            return;
        }

        const editing = tagForm[`${title}`];
        setTagForm((prev: any) => ({
            ...tagForm,
            [`${title}`]: {
                ...editing,
                value,
            },
        }));
    };

    const generateTagsForm = useCallback(() => {
        const selects = [] as any[];
        const booleans = [] as any[];

        Object.values(tagForm).forEach((tag: any) => {
            if (tag.values.type === "boolean") {
                booleans.push(tag);
            }

            if (tag.values.type === "select") {
                selects.push(tag);
            }
        });

        return {
            booleans: booleans.map((tag: any) => (
                <Grid.Col
                    md={3}
                    sm={6}
                    xs={6}
                >
                    <Flex sx={{ flexDirection: "column" }}>
                        <Text
                            sx={{
                                display: "inline-block",
                                fontSize: "0.875rem",
                                fontWeight: 500,
                                color: "#212529",
                                wordBreak: "break-word",
                                cursor: "default",
                            }}
                        >
                            {tag.title}
                        </Text>
                        <Switch
                            pt={2}
                            checked={tag.value}
                            onChange={(event) =>
                                tagsOptionOnChange(
                                    tag.title,
                                    event.currentTarget.checked
                                )
                            }
                        />
                    </Flex>
                </Grid.Col>
            )),
            selects: selects.map((tag: any) => (
                <React.Fragment key={`tag-${tag.title}`}>
                    <Select
                        label={tag.title}
                        placeholder="Pick one"
                        data={tag.values.options}
                        value={tag.value}
                        onChange={(v) =>
                            tagsOptionOnChange(tag.title, v)
                        }
                        error={tagsHasError && !tag.value ? "Please fill in this field" : null}
                    // error={formErrors.tags[tag.title] ? "Please fill in this field" : null}
                    />
                </React.Fragment>
            ))
        };
    }, [tagForm, tagsHasError]);

    const allTagsReady = () => {
        const fillableInputs = Object.values(tagForm).filter(
            (t: any) => t.values.type !== "boolean"
        );

        const total = fillableInputs.length;
        const notEmptyCount = fillableInputs.filter(
            (t: any) => !!t.value
        ).length;

        return total === notEmptyCount;
    };

    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    const statesAndAreas = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.STATES_AND_AREAS
            ).value;
        }

        return [];
    }, [configData]);

    const propertyTypesData = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.PROPERTY_TYPES
            ).value;
        }

        return [];
    }, [configData]);

    const propertyTypes = useMemo(() => {
        if (!!listingData?.transactionType) {
            const propertyTypeObj = propertyTypesData.find(
                (p: any) => p.type === listingData.transactionType
            );

            if (!!propertyTypeObj) {
                return propertyTypeObj.values.map((a: any) => ({
                    value: a,
                    label: a,
                }));
            }
        }

        if (!!form.values.transactionType) {
            const propertyTypeObj = propertyTypesData.find(
                (p: any) => p.type === form.values.transactionType
            );

            if (!!propertyTypeObj) {
                return propertyTypeObj.values.map((a: any) => ({
                    value: a,
                    label: a,
                }));
            }
        }

        return [];
    }, [listingData, form.values]);


    useEffect(() => {
        let statesData = statesAndAreas
            .map((s: any) => ({
                value: s.state,
                label: s.state,
            }))
            .filter((e: any) => !!e);

        setStates(statesData);
    }, [configData]);

    const areas = useMemo(() => {
        if (!!form.values.state) {
            const stateObj = statesAndAreas.find(
                (s: any) => s.state === form.values.state
            );
            if (!!stateObj) {
                return stateObj.areas
                    .map((a: any) => ({
                        value: a,
                        label: a,
                    }))
                    .filter((e: any) => !!e);
            }
            return [];
        }

        return [
            {
                value: "",
                label: "Please select a state first",
            },
        ];
    }, [form.values]);

    const handleEditListing = async (isDraft?: boolean) => {
        try {
            let status = isDraft ? "draft" : isPrivate ? 'hidden' : 'pending'

            if(!!isDeveloperPIC){
                status = "pending"
            }

            let mediaArr = [] as any[];
            if (urlArr.length > 0) {
                mediaArr = formatEmbedObj(urlArr)
            }

            let updatedImageArr = []

            if (!!existingImages) {
                updatedImageArr = existingImages.filter((e) => {
                    return !removedImages.find(r => parseInt(r) === e.id)
                }).map(e => e.id)
            }

            await updateListing.mutateAsync({
                ...form.values,
                imageLinks: importImages,
                images: updatedImageArr,
                removedMedia: formatEmbedObj(removedUrl),
                medias: mediaArr,
                id,
                status,
                isAuction,
                auctionStart: form.values.auctionStart ? new Date(form.values.auctionStart) : null,
                auctionEnd: form.values.auctionEnd ? new Date(form.values.auctionEnd) : null,
            })

            await updateListingTags.mutateAsync({
                listingId: id as string,
                tags: tagForm
            })

            if (files.length > 0) {
                setUploadedFiles((prevState) => ({
                    done: 0,
                    failed: 0,
                    started: 1
                }))
                for (const img of files) {
                    try {
                        const uploadId = await uploadMutation.mutateAsync({
                            file: img,
                            path: `listings/${id}`,
                            ref: "api::listing.listing",
                            refId: id,
                            field: "images",
                        });
                        setUploadedFiles((prevState) => ({
                            done: prevState.done + 1,
                            failed: prevState.failed,
                            started: prevState.started
                        }))
                    } catch (e) {
                        setUploadedFiles((prevState) => ({
                            done: prevState.done,
                            failed: prevState.failed + 1,
                            started: prevState.started
                        }))
                        console.log("upload err", e)
                    }
                }
            }

            if (!!isDraft) {
                notifications.show({
                    title: "Success!",
                    message: "Successfully updated draft listing",
                    autoClose: 2000,
                });
                setUploadedFiles((prevState) => ({
                    done: 0,
                    failed: 0,
                    started: 1
                }))
            } else {
                notifications.show({
                    title: "Success!",
                    message: "Successfully updated listing",
                    autoClose: 2000,
                });
                setUploadedFiles((prevState) => ({
                    done: 0,
                    failed: 0,
                    started: 1
                }))
            }

            navigate("/profile");
        } catch (e) {
            console.log("edit fail", e)
            setSaveLoading(false)
            notifications.show({
                title: "Error updating listing",
                message: "If this issue persist, please contact our support",
                autoClose: 2000,
            });
        }
    }

    const handleCreateListing = async (isDraft?: boolean) => {
        try {
            let mediaArr = [] as any[];
            let tagArr = [];

            let status = isDraft ? "draft" : isPrivate ? 'hidden' : 'pending'

            if(!!isDeveloperPIC){
                status = "pending"
            }
            
            for (const tag of Object.values(tagForm)) {
                const tagValue = tag as any;
                let tagObj = {
                    tagId: tagValue.id,
                } as any;

                if (tagValue.values.type === "boolean" && !!tagValue.value) {
                    tagObj["value"] = tagValue.title;
                }

                if (tagValue.values.type === "select") {
                    tagObj["value"] = getTagDisplayText(tag, tagValue.value);
                }

                if (!!tagObj.value) {
                    tagArr.push(tagObj);
                }
            }

            if (urlArr.length > 0) {
                mediaArr = formatEmbedObj(urlArr)
            }

            const listing = await createListing.mutateAsync({
                ...form.values,
                floorSize: parseInt(form.values.floorSize) ?? 0,
                landSize: parseInt(form.values.landSize) ?? 0,
                tags: tagArr,
                media: mediaArr,
                imageLinks: importImages,
                importLink,
                status,
                isDeveloperListing: !!isDeveloperPIC,
                showAgents,
                isAuction,
                auctionStart: form.values.auctionStart ? new Date(form.values.auctionStart) : null,
                auctionEnd: form.values.auctionEnd ? new Date(form.values.auctionEnd) : null,
            })

            if (files.length > 0) {
                setUploadedFiles((prevState) => ({
                    done: 0,
                    failed: 0,
                    started: 1
                }))
                for (const img of files) {
                    try {
                        const uploadId = await uploadMutation.mutateAsync({
                            file: img,
                            path: `listings/${listing.id}`,
                            ref: "api::listing.listing",
                            refId: listing.id,
                            field: "images",
                        });
                        setUploadedFiles((prevState) => ({
                            done: prevState.done + 1,
                            failed: prevState.failed,
                            started: prevState.started
                        }))
                    } catch (e) {
                        console.log("upload err", e)
                        setUploadedFiles((prevState) => ({
                            done: prevState.done,
                            failed: prevState.failed + 1,
                            started: prevState.started
                        }))
                    }
                }
            }

            setBase64Preview([])
            if (!!isDraft) {
                notifications.show({
                    title: "Success!",
                    message: "Successfully created draft listing",
                    autoClose: 2000,
                });
                setUploadedFiles((prevState) => ({
                    done: 0,
                    failed: 0,
                    started: 1
                }))
            } else {
                notifications.show({
                    title: "Success!",
                    message: "Successfully created listing",
                    autoClose: 2000,
                });
                setUploadedFiles((prevState) => ({
                    done: 0,
                    failed: 0,
                    started: 1
                }))
            }

            navigate("/profile");
        } catch (e) {
            setSaveLoading(false)
            notifications.show({
                title: "Error creating listing",
                message: "If this issue persist, please contact our support",
                autoClose: 2000,
            });
        }
    }

    const validateForm = useCallback(() => {
        form.validate()
        const isValid = form.isValid();
        let scrollToTags = false

        if (!!allTagsReady() && isValid && (base64Preview.length > 0 || importImages.length > 0 || existingImages.length > 0)) {
            setFormHasError(false)
            setTagsHasError(false)
            setImageError(false)
            handleNextButton()
            return
        }

        setFormHasError(true)

        if (!allTagsReady()) {
            setTagsHasError(true)
            scrollToTags = true
        }

        if (base64Preview.length === 0 && importImages.length === 0) {
            setImageError(true)
        } else {
            setImageError(false)
        }

        if (!!scrollToTags) {
            scrollIntoView()
        }
    }, [
        base64Preview,
        importImages,
        tagForm,
        form.values
    ])

    const validateDraft = useCallback(() => {
        // console.log("start", form.values.auctionStart)
        setFormHasError(false)

        return !!form.values.transactionType && !!form.values.transactionType && !!form.values.propertyType
    }, [form.values])

    const onScrape = async () => {
        setImportIsLoading(true);
        try {
            if (importError.length > 0) {
                return;
            }

            const { data } = await scrape.mutateAsync({
                link: importLink,
            });

            setImportError("");
            form.setValues({
                title: data?.title ?? "",
                developer: data?.developer ?? "",
                noOfBathrooms: data?.noOfBathrooms ?? "",
                noOfBedrooms: data?.noOfBedrooms ?? "",
                price: data?.price.replace(",", "") ?? "",
                floorSize: data?.sqft.replace(",", "") ?? "",
                address: data?.address ?? "",
                propertyType: data.propertyType ?? "",
                tenure: !!data.tenure ? data.tenure.toLowerCase() : "",
                propertyTitleType: !!data.propertyTitle
                    ? data.propertyTitle.toLowerCase()
                    : "",
                state: data.addressState ?? "",
                area: data.addressArea ?? "",
                transactionType: data.transactionType ?? ""
            });

            if (!!data.transactionType && !!data.propertyType) {
                filterRelevantTags(data.transactionType, data.propertyType)
            }

            if (!!data.images && data.images.length > 0) {
                setImportImages(data.images);
            }

            // validateForm()
            setImportIsLoading(false);
        } catch (e) {
            setImportIsLoading(false);
        }
    };

    const importLinkOnChange = (link: string) => {
        setImportLink(link);

        const propertyGuruRegex =
            /(https:\/\/www\.propertyguru\.com\.my\/property-listing\/).+/;
        const propertyGuruProjectRegex =
            /(https:\/\/www\.propertyguru\.com\.my\/property-listing\/project\/).+/;
        const iPropertyRegex =
            /^https:\/\/www\.iproperty\.com\.my\/property\/[^/]+\/[^/]+\/(sale|rent)-\d+\/$/;

        if (!!propertyGuruProjectRegex.test(link)) {
            setImportError("This link is not supported yet");
            return
        }

        if (!propertyGuruRegex && !iPropertyRegex) {
            setImportError("Please enter valid property guru or iproperty link");
        } else {
            setImportError("");
        }
        return
    };

    const handleSubmit = async () => {
        setSaveLoading(true)
        if (!!id) {
            await handleEditListing()
        } else {
            await handleCreateListing()
        }

        setSaveLoading(false)
    }

    const handleDraftSubmit = async () => {
        setSaveLoading(true)
        if (!!id) {
            await handleEditListing(true)
        } else {
            await handleCreateListing(true)
        }

        setSaveLoading(false)
    }

    const handleBack = () => {
        setBackConfirm(true)
    }

    const handleNextButton = () => {
        if (!id) {
            handleSubmit()
        } else {
            setSubmitConfirm(true)
        }
    }

    return (
        <>
            <Modal
                opened={!!uploadedFiles.started}
                onClose={() => { }}
                centered
                withCloseButton={false}
                closeOnClickOutside={false}
            >
                <Text align='center' fw="bold" fz={24}>Uploading Images</Text>
                <Text align='center' fw="bold" fz={16}>Please hold on...</Text>
                <Group position='center'>
                    <Loader />
                </Group>
                <Text align='center' fw="bold" fz={32}>{uploadedFiles.done}/{files.length}</Text>
                <Text align='center' fz={14}>failed: {uploadedFiles.failed}</Text>
                <Text align='center' color='red'>This process may take up to 5 minutes</Text>
                <Text align='center' color='red'>Please do not close or refresh this tab </Text>
            </Modal>
            <Box
            // sx={{ height: "calc(100vh - 128px)", position: "relative", }} 
            // className="hide-scrollbar"
            >
                {/* <AccountPendingModal
                    opened={!canOffer && !canOfferisLoading}
                    onClose={() => navigate("/profile")}
                    title="Restricted Action"
                /> */}
                <Modal
                    centered
                    opened={submitConfirm}
                    onClose={() => setSubmitConfirm(false)}
                    title={id ? "Confirm Edit?" : "Confirm Create Listing?"}
                >
                    <Stack>
                        <Text align="center">{id ? "Your listing will be updated" : "This listing will be public"}</Text>
                        <Group sx={{ width: "100%" }}>
                            <Button
                                onClick={() => setSubmitConfirm(false)}
                                sx={{ flexGrow: 1 }}
                                variant="outline"
                            >
                                No
                            </Button>
                            <Button
                                onClick={() => handleSubmit()}
                                sx={{ flexGrow: 1 }}
                            >
                                Yes
                            </Button>
                        </Group>
                    </Stack>
                </Modal>
                <Modal
                    centered
                    opened={backConfirm}
                    onClose={() => setBackConfirm(false)}
                    title="Are you sure you want to leave this page?"
                >
                    <Group sx={{ width: "100%" }}>
                        <Button
                            onClick={() => setBackConfirm(false)}
                            sx={{ flexGrow: 1 }}
                            variant="outline"
                        >
                            No
                        </Button>
                        <Button
                            onClick={() => navigate(-1)}
                            sx={{ flexGrow: 1 }}
                        >
                            Yes
                        </Button>
                    </Group>
                </Modal>
                <Container
                    size="1200px"
                    pt={32}
                    px={16}
                >
                    <Stack spacing={24}>
                        <Group>
                            <Button variant="subtle" leftIcon={<ArrowLeft />} onClick={() => handleBack()}>
                                Back
                            </Button>
                        </Group>
                        <Group
                            position="apart"
                            sx={{ flexWrap: "nowrap" }}
                        >
                            <Text
                                fz={32}
                                fw={600}
                                sx={{ flexGrow: 1 }}
                            >
                                {!!id ? "Update Listing" : "Create Listing"}
                            </Text>
                        </Group>
                        <Divider my={8} />
                    </Stack>
                </Container>
                <Container
                    size="1200px"
                    pb={32}
                    px={16}
                >
                    <Stack>
                        {/* {!id && (
                            <Stack>
                                <Group spacing={8}>
                                    <Text
                                        fz={18}
                                        fw={700}
                                    >
                                        Property Guru / iProperty Link <Text component="span" fz="sm" fw={500}>(optional)</Text>
                                    </Text>
                                    <Badge>BETA</Badge>
                                </Group>
                                <Stack spacing={8}>
                                    <Group spacing={4}>
                                        <Text size="sm">
                                            paste property
                                            guru or iproperty link here
                                            to speed up the
                                            offer creation
                                            process, users
                                            will be able to
                                            see this link on
                                            the offer
                                        </Text>
                                        <Text size="sm">
                                            Click "import"
                                            to import data
                                            from property
                                            guru, newer
                                            listings may not
                                            work
                                        </Text>
                                    </Group>
                                    <TextInput
                                        // label="Property Guru Link"
                                        radius="md"
                                        value={importLink}
                                        onChange={(e) =>
                                            importLinkOnChange(
                                                e.target
                                                    .value
                                            )
                                        }
                                        sx={{
                                            flexGrow: 1,
                                            flex: 1,
                                        }}
                                        placeholder="https://www.propertyguru.com.my/property-listing/...."
                                    />
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: 19,
                                        }}
                                    >
                                        {importError.length >
                                            0 && (
                                                <Text
                                                    color="red"
                                                    align="left"
                                                    size="xs"
                                                >
                                                    {
                                                        importError
                                                    }
                                                </Text>
                                            )}
                                    </Box>
                                </Stack>
                                <Group position="right">
                                    <Button
                                        disabled={
                                            importError.length >
                                            0 ||
                                            importLink.length ===
                                            0
                                        }
                                        type="submit"
                                        onClick={() =>
                                            onScrape()
                                        }
                                        loading={
                                            importIsLoading
                                        }
                                    >
                                        Import
                                    </Button>
                                </Group>
                            </Stack>
                        )} */}
                        <Stack pos="relative">
                            <LoadingOverlay
                                visible={
                                    !!importIsLoading
                                }
                                overlayBlur={2}
                            />
                            <Stack>
                                <Text
                                    fz={18}
                                    fw={700}
                                >
                                    Offer Details
                                </Text>
                                <Stack>
                                    {!!id && !!listingData?.importLink && (
                                        <TextInput
                                            withAsterisk
                                            label="Import Link"
                                            radius="md"
                                            value={listingData?.importLink}
                                        />
                                    )}
                                    {!!isDeveloperPIC ? (
                                        <Switch
                                            label="Show listing to Agents?"
                                            pt={2}
                                            checked={showAgents}
                                            onChange={(event) =>
                                                setShowAgents(event.currentTarget.checked)
                                            }
                                        />
                                    ) : (
                                        <Stack spacing={4}>
                                            <Switch
                                                label="Set listing as private?"
                                                pt={2}
                                                checked={isPrivate}
                                                onChange={(event) =>
                                                    setIsPrivate(event.currentTarget.checked)
                                                }
                                            />
                                            <Text color="#417256" size="sm">*This will make your listing only visible to the user you are offering to</Text>
                                        </Stack>
                                    )}
                                    <Stack>
                                        <Switch
                                            label="Is this listing an auction?"
                                            pt={2}
                                            checked={isAuction}
                                            onChange={(event) =>
                                                setIsAuction(event.currentTarget.checked)
                                            }
                                        />
                                        {!!isAuction && (
                                            <Group>
                                                <DateInput
                                                    valueFormat="DD/MM/YYYY HH:mm"
                                                    label="Auction Start Date"
                                                    placeholder="Start Date"
                                                    sx={{ flex: 1 }}
                                                    {...form.getInputProps("auctionStart")}
                                                />
                                                <DateInput
                                                    valueFormat="DD/MM/YYYY HH:mm"
                                                    label="Auction End Date"
                                                    placeholder="End Date"
                                                    sx={{ flex: 1 }}
                                                    {...form.getInputProps("auctionEnd")}
                                                />
                                            </Group>
                                        )}
                                    </Stack>
                                    <Select
                                        withAsterisk
                                        label="Transaction Type"
                                        // placeholder="Pick one"
                                        data={[
                                            { label: "SALE", value: "BUY" },
                                            { label: "RENT", value: "RENT" },
                                            { label: "INVEST", value: "INVEST" },
                                        ]}
                                        {...form.getInputProps(
                                            "transactionType"
                                        )}
                                        onChange={(e) => {
                                            form.setFieldValue("transactionType", e as string)
                                            filterRelevantTags(e as string, form.values.propertyType)
                                        }}
                                        disabled={!!id}
                                    />
                                    <Select
                                        withAsterisk
                                        label="Property Type"
                                        placeholder="Pick one"
                                        data={
                                            propertyTypes
                                        }
                                        searchable
                                        filter={
                                            selectFilter
                                        }
                                        nothingFound="No options"
                                        {...form.getInputProps(
                                            "propertyType"
                                        )}
                                        onChange={(e) => {
                                            form.setFieldValue("propertyType", e as string)
                                            filterRelevantTags(form.values.transactionType, e as string)
                                        }}
                                    />
                                    <Stack spacing={2}>
                                        <TextInput
                                            withAsterisk
                                            label="Title"
                                            radius="md"
                                            {...form.getInputProps(
                                                "title"
                                            )}
                                        />
                                        <Group position="right">
                                            <Text color={form.values.title.length >= 30 ? "red" : "black"}>{form.values.title.length}/{titleWordMax}</Text>
                                        </Group>
                                    </Stack>
                                    <TextInput
                                        withAsterisk
                                        label="Price"
                                        type="number"
                                        onWheel={(evt) => evt.currentTarget.blur()}
                                        radius="md"
                                        {...form.getInputProps(
                                            "price"
                                        )}
                                        icon={
                                            <Text>
                                                RM
                                            </Text>
                                        }
                                    />
                                    <TextInput
                                        label="Developer"
                                        radius="md"
                                        {...form.getInputProps(
                                            "developer"
                                        )}
                                        disabled={!!isDeveloperPIC}
                                    />
                                    <Group>
                                        <BedroomsInput form={form} />
                                        <BathroomsInput form={form} />
                                    </Group>
                                    <Group>
                                        <TextInput
                                            withAsterisk
                                            label="Floor size"
                                            type="number"
                                            radius="md"
                                            sx={{
                                                flex: 1,
                                            }}
                                            {...form.getInputProps(
                                                "floorSize"
                                            )}
                                            rightSection={
                                                <Text
                                                    sx={{
                                                        color: "#adb5bd",
                                                    }}
                                                >
                                                    Sqft
                                                </Text>
                                            }
                                            rightSectionWidth={
                                                45
                                            }
                                        />
                                        <TextInput
                                            label="Land size"
                                            type="number"
                                            radius="md"
                                            sx={{
                                                flex: 1,
                                            }}
                                            {...form.getInputProps(
                                                "landSize"
                                            )}
                                            rightSection={
                                                <Text
                                                    sx={{
                                                        color: "#adb5bd",
                                                    }}
                                                >
                                                    Sqft
                                                </Text>
                                            }
                                            rightSectionWidth={
                                                45
                                            }
                                        />
                                    </Group>
                                    <Select
                                        label="Property Title Type"
                                        placeholder="Pick one"
                                        radius="md"
                                        data={
                                            PROPERTY_TITLE_TYPE_OPTIONS
                                        }
                                        {...form.getInputProps(
                                            "propertyTitleType"
                                        )}
                                    />
                                    <Select
                                        label="Tenure"
                                        placeholder="Pick one"
                                        radius="md"
                                        data={
                                            TENURE_OPTIONS
                                        }
                                        {...form.getInputProps(
                                            "tenure"
                                        )}
                                    />
                                    <Group>
                                        <Select
                                            sx={{
                                                flex: 1,
                                            }}
                                            label="State"
                                            placeholder="Pick one"
                                            data={
                                                states
                                            }
                                            searchable
                                            nothingFound="No options"
                                            filter={
                                                selectFilter
                                            }
                                            {...form.getInputProps(
                                                "state"
                                            )}
                                            onChange={(
                                                e
                                            ) => {
                                                form.setFieldValue(
                                                    "state",
                                                    e ||
                                                    ""
                                                );
                                                form.setFieldValue(
                                                    "area",
                                                    ""
                                                );
                                            }}
                                        />
                                        <Select
                                            sx={{
                                                flex: 1,
                                            }}
                                            label="Area"
                                            placeholder="Pick one"
                                            data={
                                                !form
                                                    .values
                                                    .state
                                                    ? []
                                                    : areas
                                            }
                                            disabled={
                                                !form
                                                    .values
                                                    .state
                                            }
                                            searchable
                                            nothingFound="No options"
                                            filter={
                                                selectFilter
                                            }
                                            {...form.getInputProps(
                                                "area"
                                            )}
                                        />
                                    </Group>
                                    <Textarea
                                        withAsterisk
                                        label="Address"
                                        {...form.getInputProps(
                                            "address"
                                        )}
                                    />
                                    <ImageInput
                                        importImages={importImages}
                                        setImportImages={setImportImages}
                                        files={files}
                                        setFiles={setFiles}
                                        base64Preview={base64Preview}
                                        setBase64Preview={setBase64Preview}
                                        existingImages={existingImages}
                                        removedImages={removedImages}
                                        setRemovedImages={setRemovedImages}
                                        imagesError={imagesError}
                                    />
                                    <VideoEmbedInput
                                        urlArr={urlArr}
                                        setUrlArr={setUrlArr}
                                        removedUrl={removedUrl}
                                        setRemovedUrl={setRemovedUrl}
                                        existingUrlArr={existingUrlArr}
                                    />
                                </Stack>
                            </Stack>
                            <Divider my={8} />
                            <Stack ref={targetRef}>
                                <Text
                                    fz={18}
                                    fw={700}
                                >
                                    Advanced Details
                                </Text>
                                {!!form.values.transactionType && !!form.values.propertyType ? (

                                    <Stack>
                                        {generateTagsForm().selects}
                                        <Grid>
                                            {generateTagsForm().booleans}
                                        </Grid>
                                    </Stack>
                                ) : (
                                    <Stack>
                                        <Text>Please choose a transaction type and property type first</Text>
                                    </Stack>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
                <Box
                    sx={{
                        width: "100%",
                        background: "white",
                        // position: "sticky",
                        // bottom: 0,
                        // left: 0,
                        // boxShadow: `0px -8px 16px 0px rgba(0,0,0,0.2)`,
                        zIndex: 100,
                    }}
                    p={16}
                >
                    <Container
                        size="1200px"
                        p={0}
                    >
                        <Group
                            position="apart"
                            p={0}
                        >
                            {/* {step === OFFER_STEPS.FORM && (
                                <Button
                                    onClick={() => setImportPopup(true)}
                                    variant="subtle"
                                >
                                    Import
                                </Button>
                            )} */}
                            <Group
                                position="apart"
                                p={0}
                                align='center'
                                w="100%"
                            >
                                {/* <Button variant="outline">Cancel</Button> */}
                                <Group>
                                    <Button
                                        // disabled={!isReadyForPreview}
                                        onClick={() => handleBack()}
                                        variant={'outline'}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        // disabled={!isReadyForPreview}
                                        onClick={() => validateForm()}
                                        loading={saveLoading}
                                        disabled={saveLoading}
                                    >
                                        Save
                                    </Button>
                                </Group>
                                <Group>
                                    {!!formHasError && (
                                        <Text color="red" mt={4} fz="sm">Please check the form for errors.</Text>
                                    )}
                                    <SaveAsDraft
                                        draftValidation={() => validateDraft()}
                                        onSave={() => handleDraftSubmit()}
                                        loading={saveLoading}
                                    />
                                </Group>
                            </Group>
                        </Group>
                    </Container>
                </Box>
            </Box>
        </>
    );
};

export default ListingForm;