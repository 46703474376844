import { ActionIcon, BackgroundImage, Badge, Box, Button, Card, Center, Checkbox, Container, Divider, FileButton, Flex, Grid, Group, Image, Loader, Modal, Select, Stack, Text, Textarea, TextInput, Title, useMantineTheme } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { Files, Trash } from '@phosphor-icons/react';
import { useAgencyAPI } from 'api/useAgencyAPI';
import { convertFileToPreview, convertSingleFileToPreview, mergeArraysAndRemoveDuplicates } from 'helpers/utility';
import { useUpload } from 'hooks/useUpload';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileLayoutTemplate from './ProfileLayoutTemplate';
import { useConfigData } from 'hooks/useConfigData';
import { useMediaQuery } from '@mantine/hooks';
import AgencyCard from './AgencyCard';
import { useDeveloperAPI } from 'api/useDeveloperAPI';
import DeveloperCard from './DeveloperCard';

type imageFormKeys = "imageRow1" | "imageRow2" | "imageRow3"

const DeveloperForm = () => {
    const { id } = useParams()
    const theme = useMantineTheme()
    const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const ref = useRef<any>()
    const navigate = useNavigate()
    const { useUpdateDeveloper, useGetOneDeveloper } = useDeveloperAPI()
    const { statesAndAreas, developerTags } = useConfigData()
    const { data, isLoading, error } = useGetOneDeveloper(id ?? "")
    const { uploadMutation } = useUpload();
    const updateDeveloper = useUpdateDeveloper()
    const [logoData, setLogoData] = useState({
        file: null,
        preview: "",
        loading: false
    } as any)
    const [states, setStates] = useState([])
    const [confirm, setConfirm] = useState(false)
    const [uploadedFiles, setUploadedFiles] = useState({
        done: 0,
        failed: 0,
        started: 0
    })

    const [selectedDeveloperTags, setSelectedDeveloperTags] = useState<string[]>([])

    const form = useForm({
        initialValues: {
            name: "",
            state: "",
            slug: "",
            description: ""
        }
    })

    const handleUpload = async (e: any) => {
        // setValue(e);
        setLogoData({
            ...logoData,
            loading: true
        })
        try {
            const { preview, file } = await convertSingleFileToPreview(e);

            console.log("data", { preview, file })

            setLogoData({
                file,
                preview: preview.base64,
                loading: false
            })
        } catch (e) {
            console.log("error", e)
            setLogoData({
                ...logoData,
                loading: false
            })
        }
    };

    const clearFileUpload = () => {
        setLogoData({
            file: null,
            preview: "",
            loading: false
        })
    }

    const handleSubmit = useCallback(async () => {
        setUploadedFiles((prevState) => ({
            done: 0,
            failed: 0,
            started: 1
        }))

        try {
            await updateDeveloper.mutateAsync({
                id,
                ...form.values,
                tags: selectedDeveloperTags
            })

            if(!!logoData.file){
                const uploadId = await uploadMutation.mutateAsync({
                    file: logoData.file,
                    path: `developers/${id}`,
                    ref: "api::developer.developer",
                    refId: id,
                    field: "logo",
                });

                setUploadedFiles((prevState) => ({
                    done: 1,
                    failed: 0,
                    started: 0
                }))
            }


            setConfirm(false)
        } catch (e) {
            console.log("error", e)
        }
    }, [form.values, selectedDeveloperTags, logoData])

    const handleTagCheck = (tag: string, check: boolean) => {
        if (!check) {
            setSelectedDeveloperTags(developerTags => developerTags.filter((at) => at !== tag))
        } else {
            setSelectedDeveloperTags(developerTags => ([...developerTags, tag]))
        }
    }

    useEffect(() => {
        console.log("selectedDeveloperTags", selectedDeveloperTags)
    }, [selectedDeveloperTags]);

    useEffect(() => {
        if (!!data) {
            form.setValues({
                name: data?.name ?? "",
                state: data?.state ?? "",
                slug: data?.slug ?? "",
                description: data?.description ?? ""
            })

            setLogoData({
                ...logoData,
                preview: data?.logo?.url ?? ""
            })

            setSelectedDeveloperTags(data?.tags ?? [])
        }
    }, [data])

    const tags = useMemo(() => {
        if (!!developerTags) {
            return developerTags
        }
        return []
    }, [developerTags])


    useEffect(() => {
        let statesData = statesAndAreas
            .map((s: any) => ({
                value: s.state,
                label: s.state,
            }))
            .filter((e: any) => !!e);

        setStates(statesData);
    }, [statesAndAreas]);

    const scroll = useCallback(() => {
        if (!!ref.current) {
            const currentRef = ref as any
            console.log(ref)
            currentRef.current.scrollIntoView()
        }
    }, [ref])


    return (
        <Box pos="relative">
            <Modal
                opened={confirm}
                onClose={() => setConfirm(false)}
                centered
                title="Save Developer Details"
            >
                <Stack>
                    <Text align='center' fz={"md"}>confirm save details?</Text>
                    <Group>
                        <Button sx={{ flex: 1 }} variant='outline' color="red" onClick={() => setConfirm(false)}>Cancel</Button>
                        <Button sx={{ flex: 1 }} onClick={() => handleSubmit()}>Confirm</Button>
                    </Group>
                </Stack>
            </Modal>
            <Modal
                opened={!!uploadedFiles.started}
                onClose={() => { }}
                centered
                withCloseButton={false}
                closeOnClickOutside={false}
            >
                <Text align='center' fw="bold" fz={24}>Uploading Images</Text>
                <Text align='center' fw="bold" fz={16}>Please hold on...</Text>
                <Group position='center'>
                    <Loader />
                </Group>
                {/* <Text align='center' fw="bold" fz={32}>{uploadedFiles.done}/{totalUploadsNeeded}</Text> */}
                <Text align='center' fz={14}>failed: {uploadedFiles.failed}</Text>
                <Text align='center' color='red'>This process may take up to 5 minutes</Text>
                <Text align='center' color='red'>Please do not close or refresh this tab </Text>
            </Modal>
            <Container py={32} mih={"100vh"}>
                <Grid>
                    <Grid.Col xs={12}>
                        <Group>
                            <Title order={2}>Developer Card Preview</Title>
                            <Button onClick={() => scroll()}>Edit</Button>
                        </Group>
                    </Grid.Col>
                    <Grid.Col xs={12}>
                        <DeveloperCard
                            developer={{
                                logo: {
                                    url: logoData.preview ?? null
                                },
                                tags: mergeArraysAndRemoveDuplicates([...data?.tags ?? [], ...selectedDeveloperTags]),
                                ...form.values
                            }}
                            onLearnMore={() => {}}
                            onApply={() => {}}
                        />
                    </Grid.Col>
                    <Grid.Col xs={12}>
                        <Divider my={8} />
                    </Grid.Col>
                    <Grid.Col xs={12} ref={ref} >
                        <Title order={2}>Edit Developer Details</Title>
                    </Grid.Col>
                    <Grid.Col xs={12} md={3}>
                        <Stack spacing={16} sx={{ height: '100%', }}>
                            <Stack spacing={2}>
                                <Text size="md">Logo</Text>
                                <Text size="xs">128px x 128px</Text>
                            </Stack>
                            <Group position='center'>
                                <FileButton
                                    onChange={file => handleUpload(file)}
                                    accept="image/*"
                                >
                                    {(props) => (
                                        <Box pos="relative">
                                            {logoData.loading ? (
                                                <Center h="100%">
                                                    <Loader />
                                                </Center>
                                            ) : (
                                                <>
                                                    {logoData.preview ? (
                                                        <>
                                                            <BackgroundImage src={logoData?.preview} h="128px" w="128px" sx={{ backgroundSize: "contain", backgroundRepeat: "no-repeat" }} />
                                                            <ActionIcon
                                                                sx={{
                                                                    borderRadius: "50%",
                                                                    backgroundColor: "white",
                                                                    border: "1px solid #BFC8C3",
                                                                    position: "absolute",
                                                                    top: 0,
                                                                    right: 0,
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    clearFileUpload();
                                                                }}
                                                            >
                                                                <Trash
                                                                    size={16}
                                                                    color="red"
                                                                />
                                                            </ActionIcon>
                                                        </>
                                                    ) : (
                                                        <Button  {...props} h="100%" w="100%" size="lg" px={16} py={8}>Upload image</Button>
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    )}
                                </FileButton>
                            </Group>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col xs={12} md={9}>
                        <Stack>
                            <TextInput
                                label="Developer Name"
                                {...form.getInputProps("name")}
                            />
                            <Select
                                data={states}
                                label="State"
                                {...form.getInputProps("state")}
                            />
                        </Stack>
                    </Grid.Col>
                    <Grid.Col xs={12} md={12}>

                        <Stack spacing={2}>
                            <Text size="sm">Description</Text>
                            <Textarea
                                autosize
                                minRows={3}
                                placeholder='Description'
                                sx={{ width: "100%" }}
                                {...form.getInputProps("description")}
                            ></Textarea>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Divider my={8} />
                    </Grid.Col>
                    <Grid.Col xs={12}>
                        <Card withBorder>
                            <Grid>
                                <Grid.Col span={12} mb={16}>
                                    <Text size="lg">Developer tags</Text>
                                </Grid.Col>
                                {tags.map((t: string) => (
                                    <Grid.Col xs={6} sm={3}>
                                        <Checkbox
                                            label={t}
                                            value={t}
                                            onChange={(e) => {
                                                handleTagCheck(t, e.target.checked)
                                            }}
                                            checked={selectedDeveloperTags.includes(t)}
                                        />
                                    </Grid.Col>
                                ))}
                            </Grid>
                        </Card>
                    </Grid.Col>
                </Grid>
            </Container>
            <Card pos="sticky" bottom={0} withBorder shadow="md">
                <Group position='apart'>
                    <Button variant='outline' color='red' onClick={() => navigate("/profile")}>Back</Button>
                    <Group position='right' sx={{ flex: 1 }}>
                        <Button variant='outline' onClick={() => navigate(`/developer/edit/profile/${id}`)}>Edit Profile Page</Button>
                        <Button
                            onClick={() => setConfirm(true)}
                            loading={isLoading}
                        >Save</Button>
                    </Group>
                </Group>
            </Card>
        </Box>
    );
};

export default DeveloperForm;