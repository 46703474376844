import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Box,
    Container,
    Stack,
    Group,
    Text,
    Select,
    Button,
    Modal,
    TextInput,
    Textarea,
    Divider,
    Overlay,
    ScrollArea,
    Flex,
    Loader,
    Center,
    useMantineTheme,
    Image,
    Stepper,
    Sx,
    Accordion,
    Card,
    Badge,
    Indicator,
    Tooltip,
} from "@mantine/core";
import { ArrowLeft } from "tabler-icons-react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useAgentRequestAPI } from "api/useAgentRequestApi";
import { CONFIG_KEYS, CREATE_REQUEST_STEPS, PROPERTY_REQUEST_COLOR_SETTINGS, PROPERTY_TITLE_TYPE_OPTIONS, propRequestPropertyTitleOptions, propRequestTenureOptions, TENURE_OPTIONS, TRANSACTION_TYPE_OPTIONS } from "helpers/constants";
import { useGetConfigs } from "api/configAPI";
import { getReadableValueFromSelect, getTagDisplayText, selectFilter } from "helpers/utility";
import { notifications } from "@mantine/notifications";
import { Permission, useAuth } from "hooks/useAuth";
import { AgentRequestTnc } from "./components/AgentRequestTnc";
import BathroomsInput from "pages/PropRequestForm/components/BathroomsInput";
import BedroomsInput from "pages/PropRequestForm/components/BedroomsInput";
import { useProfileController } from "hooks/useProfile";
import MiniRegisterForm from "pages/PropRequestForm/components/MiniRegisterForm";
import ImageInput from "pages/OfferForm/Components/ImageInput";
import { useUpload } from "hooks/useUpload";
import { useConfigData } from "hooks/useConfigData";
import { useListingAPI } from "api/useListingAPI";
import TagForm from "components/Inputs/TagForm";
import { useMediaQuery } from "@mantine/hooks";
import NextButtonRow from "pages/PropRequestForm/Sections/NextButtonRow";
import { Bathtub, Bed, CircleWavyCheck } from "@phosphor-icons/react";
import { IconCircleCheck } from "@tabler/icons-react";
import EditSectionHeader from "pages/PropRequestForm/Sections/EditSectionHeader";
import TranslateToTags from "pages/PropRequestForm/Sections/TranslateToTags";
import TransactionTypeBadge from "components/TransactionTypeBadge";
import { LightBox } from "components/LightBox";

type TransactionType = "BUY" | "RENT" | "INVEST"

const AgentRequestForm = () => {
    let { id } = useParams();
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

    const sidebarStyles = isMobile
        ? ({
            position: "initial",
            width: "100%",
            padding: "16px",
        } as Sx)
        : ({
            position: "relative",
            minWidth: "250px",
            paddingTop: "16px",
        } as Sx);

    const stepperStyle = isMobile
        ? ({
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
        } as Sx)
        : ({
            position: "fixed",
            left: 0,
            top: 0,
            minWidth: "220px",
            height: "100vh",
            flexDirection: "column",
            padding: "16px",
            gap: "24px",
        } as Sx);

    const { user, agentRequestTncMutation } = useAuth(Permission.USER_ONLY);
    const { profileData } = useProfileController();
    const navigate = useNavigate();
    const [submitConfirm, setSubmitConfirm] = useState(false)
    const [backConfirm, setBackConfirm] = useState(false)
    const [showTnc, setShowTnc] = useState(false)
    const [states, setStates] = useState([]);
    const [activeTab, setActiveTab] = useState(0)
    const { propertyTypes, propertyTypesRaw } = useConfigData()

    const [importImages, setImportImages] = useState<string[]>([]);
    const [removedImages, setRemovedImages] = useState([])
    const [existingImages, setExistingImages] = useState<any[]>([])
    const [showLightbox, setShowLightbox] = useState(false);

    const [base64Preview, setBase64Preview] = useState<any[]>([]);
    const [imagesError, setImageError] = useState(false)
    const [files, setFiles] = useState<File[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState({
        done: 0,
        failed: 0,
        started: 0
    })

    const [tagForm, setTagForm] = useState<any>({});
    const [createLoading, setCreateLoading] = useState(false);


    const [accordionStep, setAccordionStep] = useState(
        `${CREATE_REQUEST_STEPS.DETAILS}`
    );

    const { useCreateAgentRequest, useGetOneAgentRequest, useUpdateAgentRequest, useUpdateOwnerListingTags } = useAgentRequestAPI();
    const { useGetTags } = useListingAPI()
    const {
        data: agentRequestData,
        error: agentRequestError,
        isLoading: agentRequestIsLoading,
    } = useGetOneAgentRequest(id ?? '')
    const {
        data: tags,
        isLoading: tagsIsLoading,
        error: tagsError,
    } = useGetTags();
    const createAgentRequest = useCreateAgentRequest()
    const updateAgentRequest = useUpdateAgentRequest()
    const updateOwnerListingTags = useUpdateOwnerListingTags()
    const { uploadMutation } = useUpload();

    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();
    const statesAndAreas = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.STATES_AND_AREAS
            ).value;
        }

        return [];
    }, [configData]);
    const form = useForm<any>({
        // initialValues: {
        //     transactionType: "RENT",
        //     propertyTitleType: "individual",
        //     propertyType: "",
        //     state: "Negeri Sembilan",
        //     area: "Bandar Universiti Teknologi Legenda",
        //     address: "aapapapapap",
        //     floorSize: 100,
        //     tenure: "freehold",
        //     askingPrice: 100,
        //     description: "oaoaoaoaoaoao",
        //     noOfBedrooms: "1",
        //     noOfBathrooms: "2"
        // },
        initialValues: {
            transactionType: "",
            propertyTitleType: "",
            propertyType: "",
            state: '',
            area: '',
            address: '',
            floorSize: '',
            tenure: '',
            askingPrice: '',
            description: '',
            noOfBedrooms: '',
            noOfBathrooms: '',
        },
        validate: {
            transactionType: (value) => (!!value ? null : "Please fill in this field"),
            propertyTitleType: (value) => (!!value ? null : "Please fill in this field"),
            state: (value) => (!!value ? null : "Please fill in this field"),
            area: (value) => (!!value ? null : "Please fill in this field"),
            floorSize: (value) =>
                !!value ? null : "Please fill in this field",
            tenure: (value) => (!!value ? null : "Please fill in this field"),
            address: (value) => (!!value ? null : "Please fill in this field"),
            description: (value) => (!!value ? null : "Please fill in this field"),
            askingPrice: (value) => (!!value ? null : "Please fill in this field"),
            noOfBedrooms: value => !Number.isNaN(value) ? null : 'Please fill in this field',
            noOfBathrooms: value => !Number.isNaN(value) ? null : 'Please fill in this field',
        },
    });

    const propertyTypesArr = useMemo(() => {
        if (!!form.values.transactionType) {
            const propertyTypeObj = propertyTypesRaw?.find(
                (p: any) => p.type === form.values.transactionType
            ) ?? { values: [] };
            // console.log(propertyTypeObj);

            return propertyTypeObj.values
                .map((a: any) => ({
                    value: a,
                    label: a,
                }))
                .filter((e: any) => !!e);
            // if (!!propertyTypeObj) {
            // }
        }

        return [
            {
                value: "",
                label: "",
            },
        ];
    }, [form.values, propertyTypesRaw]);

    const areas = useMemo(() => {
        if (!!form.values.state) {
            const stateObj = statesAndAreas.find(
                (s: any) => s.state === form.values.state
            );
            if (!!stateObj) {
                return stateObj.areas
                    .map((a: any) => ({
                        value: a,
                        label: a,
                    }))
                    .filter((e: any) => !!e);
            }
            return [];
        }

        return [
            {
                value: "",
                label: "Please select a state first",
            },
        ];
    }, [form.values]);

    const handleAcceptTnc = () => {
        agentRequestTncMutation.mutate({
            id: profileData.id,
            payload: {
                agentRequestTnc: true,
            }
        }, {
            onSuccess: () => {
                setShowTnc(false)
            },
            onError: () => {
                notifications.show({
                    title: "Error accepting terms and conditions",
                    message: "If this issue persist, please contact our support",
                    autoClose: 2000,
                })
            }
        })
    }

    const onCreateAgentRequest = async () => {
        setCreateLoading(true);
        let tagArr = [];

        for (const tag of Object.values(tagForm)) {
            const tagValue = tag as any;
            let tagObj = {
                tagId: tagValue.id,
            } as any;

            if (tagValue.values.type === "boolean" && !!tagValue.value) {
                tagObj["value"] = tagValue.title;
            }

            if (tagValue.values.type === "select") {
                tagObj["value"] = getTagDisplayText(tag, tagValue.value);
            }

            if (!!tagObj.value) {
                tagArr.push(tagObj);
            }
        }

        const ownerListing = await createAgentRequest.mutate({
            ...form.values,
            lister: user.id,
            tags: tagArr,
        }, {
            onSuccess: async ({ data }) => {
                sessionStorage.removeItem("continue_agent_request");
                setSubmitConfirm(false)
                if (files.length > 0) {
                    setUploadedFiles((prevState) => ({
                        done: 0,
                        failed: 0,
                        started: 1
                    }))
                    for (const img of files) {
                        try {
                            const uploadId = await uploadMutation.mutateAsync({
                                file: img,
                                path: `owner-listings/${data.id}`,
                                ref: "api::agent-request.agent-request",
                                refId: data.id,
                                field: "images",
                            });
                            setUploadedFiles((prevState) => ({
                                done: prevState.done + 1,
                                failed: prevState.failed,
                                started: prevState.started
                            }))
                        } catch (e) {
                            console.log("upload err", e)
                            setUploadedFiles((prevState) => ({
                                done: prevState.done,
                                failed: prevState.failed + 1,
                                started: prevState.started
                            }))
                        }
                    }
                }

                setCreateLoading(false);

                setBase64Preview([])
                notifications.show({
                    title: "Success!",
                    message: "Successfully created agent request",
                    autoClose: 2000,
                });
                navigate("/profile");
            },
            onError: () => {
                notifications.show({
                    title: "Error creating agent request",
                    message: "If this issue persist, please contact our support",
                    autoClose: 2000,
                });
            }
        })
    }

    const handleCreateAgentRequest = useCallback(async () => {
        if (!!user.jwt) {
            return await onCreateAgentRequest()
        }

        let filesArr = []
        if (files.length > 0) {
            setUploadedFiles((prevState) => ({
                done: 0,
                failed: 0,
                started: 1
            }))
            for (const img of files) {
                try {
                    const uploadId = await uploadMutation.mutateAsync({
                        file: img,
                        path: `owner-listings`,
                    });
                    filesArr.push(uploadId[0])

                    setUploadedFiles((prevState) => ({
                        done: prevState.done + 1,
                        failed: prevState.failed,
                        started: prevState.started
                    }))
                } catch (e) {
                    console.log("upload err", e)
                    setUploadedFiles((prevState) => ({
                        done: prevState.done,
                        failed: prevState.failed + 1,
                        started: prevState.started
                    }))
                }
            }

            setUploadedFiles((prevState) => ({
                ...prevState,
                started: 0
            }))
        }

        const cache = {
            action: "CREATE_AGENT_REQUEST",
            data: {
                form: form.values,
                filesArr,
                tagForm
            }
        };

        sessionStorage.setItem("pre_login_draft", JSON.stringify(cache));
        setActiveTab(3)
        setSubmitConfirm(false)
        // return navigate("/register/lister")
    }, [user, form.values, files, tagForm])

    const handleUpdateAgentRequest = () => {

        let updatedImageArr = [] as any[]

        if (!!existingImages) {
            updatedImageArr = existingImages.filter((e) => {
                return !removedImages.find(r => parseInt(r) === e.id)
            }).map(e => e.id)
        }


        updateAgentRequest.mutate({
            ...form.values,
            id,
            images: updatedImageArr,
        }, {
            onSuccess: async ({ data }) => {
                await updateOwnerListingTags.mutateAsync({
                    ownerListingId: data.id,
                    tags: tagForm,
                })

                if (files.length > 0) {
                    setUploadedFiles((prevState) => ({
                        done: 0,
                        failed: 0,
                        started: 1
                    }))
                    for (const img of files) {
                        try {
                            const uploadId = await uploadMutation.mutateAsync({
                                file: img,
                                path: `owner-listings/${data.id}`,
                                ref: "api::agent-request.agent-request",
                                refId: data.id,
                                field: "images",
                            });
                            setUploadedFiles((prevState) => ({
                                done: prevState.done + 1,
                                failed: prevState.failed,
                                started: prevState.started
                            }))
                        } catch (e) {
                            console.log("upload err", e)
                            setUploadedFiles((prevState) => ({
                                done: prevState.done,
                                failed: prevState.failed + 1,
                                started: prevState.started
                            }))
                        }
                    }
                }

                navigate("/profile");
                notifications.show({
                    title: "Success!",
                    message: "Successfully updated agent request",
                    autoClose: 2000,
                });
            },
            onError: () => {
                notifications.show({
                    title: "Error updating agent request",
                    message: "If this issue persist, please contact our support",
                    autoClose: 2000,
                });
            }
        })
    }

    const handleBack = () => {
        setBackConfirm(true)
    }

    const validateForm = useCallback(() => {
        form.validate()
        const isValid = form.isValid();

        if (files.length === 0) {
            setImageError(true)
            return
        }

        // if(!!user.jwt){
        //     if(!!isValid){
        //         setSubmitConfirm(true)
        //     }
        // }

        if (!!isValid && !!allTagsReady()) {
            return handleCreateAgentRequest()
        }
    }, [form.values, files, user, tagForm])

    const handleSubmit = async () => {
        if (!!id) {
            handleUpdateAgentRequest()
        } else {
            await handleCreateAgentRequest()
        }
    }

    useEffect(() => {
        let statesData = statesAndAreas
            .map((s: any) => ({
                value: s.state,
                label: s.state,
            }))
            .filter((e: any) => !!e);

        setStates(statesData);
    }, [configData]);

    // useEffect(() => {
    //     document.body.style.overflow = showTnc ? 'hidden' : 'auto'
    //     return () => { document.body.style.overflow = 'auto' }
    // }, [showTnc])

    useEffect(() => {
        if (!!tags && !!form.values.transactionType && !!form.values.propertyType && !id) {
            const releventTags = tags.filter((t: any) => {
                return (
                    t.values.transactionType.includes(
                        form.values.transactionType
                    ) && t.values.propertyTypes.includes(form.values.propertyType)
                );
            });

            if (releventTags.length > 0) {
                let tagsInput = {} as any;
                // console.log("releventTags", releventTags);
                releventTags.forEach((tag: any) => {
                    let defaultValue = tag.values.type !== "boolean" ? "" : false;

                    tagsInput[tag.title] = {
                        ...tag,
                        value: defaultValue,
                        tootip: tag.tooltip,
                    };
                });
                setTagForm(tagsInput);
            }
        }
    }, [form.values.transactionType, form.values.propertyType, tags, id])

    useEffect(() => {
        if (profileData) {
            if (!profileData.agentRequestTnc) {
                setShowTnc(true)
            }
        }
    }, [profileData])

    useEffect(() => {
        if (agentRequestData) {
            form.setValues({
                transactionType: agentRequestData?.transactionType,
                propertyTitleType: agentRequestData?.propertyTitleType,
                propertyType: agentRequestData?.propertyType,
                state: agentRequestData?.state,
                area: agentRequestData?.area,
                address: agentRequestData?.address,
                floorSize: agentRequestData?.floorSize,
                tenure: agentRequestData?.tenure,
                askingPrice: agentRequestData?.askingPrice,
                description: agentRequestData?.description,
                noOfBedrooms: `${agentRequestData?.noOfBedrooms}`,
                noOfBathrooms: `${agentRequestData?.noOfBathrooms}`,
            })

            setExistingImages(agentRequestData?.images)

            const releventTags = tags.filter((t: any) => {
                return t.values.transactionType.includes(
                    agentRequestData.transactionType
                );
            });

            if (releventTags.length > 0) {
                let tagsInput = {} as any;

                releventTags.forEach((tag: any) => {
                    let defaultValue = "" as string | boolean;
                    let savedTag = agentRequestData.tags.find(
                        (rt: any) => rt.tag.title === tag.title
                    );

                    if (!!savedTag) {
                        if (savedTag.tag.values.type === "boolean") {
                            defaultValue = true;
                        }
                        if (savedTag.tag.values.type === "select") {
                            const option = savedTag.tag.values.options.find(
                                (o: any) => {
                                    return o.label === savedTag.value;
                                }
                            );
                            defaultValue = option.value;
                        }
                    } else {
                        defaultValue =
                            tag.values.type !== "boolean" ? "" : false;
                    }

                    tagsInput[tag.title] = { ...tag, value: defaultValue };
                });

                setTagForm(tagsInput);
            }

            setActiveTab(2);
        }
    }, [agentRequestData, id])

    useEffect(() => {
        const hasPostLoginAction = sessionStorage.getItem("continue_agent_request");

        if (!!hasPostLoginAction && !!user.jwt) {
            let { data, action } = JSON.parse(hasPostLoginAction)

            form.setValues({
                ...data.form
            })
        }
    }, [user])

    const allTagsReady = () => {
        const fillableInputs = Object.values(tagForm).filter(
            (t: any) => t.values.type !== "boolean"
        );

        const total = fillableInputs.length;
        const notEmptyCount = fillableInputs.filter(
            (t: any) => !!t.value
        ).length;

        return (
            total === notEmptyCount
        );
    };

    const formCheck = useMemo(() => {
        let detailReady = !!form.values.propertyType &&
            !!form.values.tenure &&
            !!form.values.propertyTitleType &&
            !!form.values.transactionType

        return {
            detail: detailReady,
            accordion: {
                [`${CREATE_REQUEST_STEPS.DETAILS}`]: !!allTagsReady() && files.length > 0,
                [`${CREATE_REQUEST_STEPS.LOCATION}`]:
                    !!form.values.state && !!form.values.area && !!form.values.address,
                [`${CREATE_REQUEST_STEPS.SIZE_AND_AMMENITIES}`]:
                    !Number.isNaN(form.values.noOfBathrooms) &&
                    !Number.isNaN(form.values.noOfBedrooms) && !!form.values.floorSize,
                [`${CREATE_REQUEST_STEPS.BUDGET}`]:
                    form.values.askingPrice,
                allReady:
                    !!allTagsReady() &&
                    !!form.values.state &&
                    !!form.values.area &&
                    !!form.values.noOfBathrooms &&
                    !!form.values.noOfBedrooms &&
                    !!form.values.floorSize &&
                    !!form.values.askingPrice &&
                    !!form.values.description
            },
        }
    }, [form.values, tagForm])

    const badgeArr = useMemo(() => {
        const result = Object.values(tagForm)
            .map((tag: any) => {
                if (tag.values.type === "boolean" && !!tag.value) {
                    return {
                        title: tag.title,
                        color: tag.badgeColor,
                        background: tag.badgeBackground,
                    };
                }

                if (tag.values.type === "select") {
                    return {
                        title: getTagDisplayText(tag, tag.value),
                        color: tag.badgeColor,
                        background: tag.badgeBackground,
                    };
                }

                return null;
            })
            .filter((t: any) => !!t);

        return result
    }, [tagForm])




    const handleExit = useCallback(() => {
        if (!!user.jwt) {
            return navigate("/profile")
        }
        return navigate("/")
    }, [user])

    const mergePreviews = useCallback(() => {
        let formattedExisting = [] as any[]

        if (!!existingImages && !!removedImages) {
            formattedExisting = existingImages.filter((e) => {
                return !removedImages.find(r => parseInt(r) === e.id)
            }).map((e, i) => ({
                url: e.url,
                id: `exsistingImages-${e.id}`,
            }))
        }

        const formattedImport = importImages.map((e, i) => ({
            url: e,
            id: `importImages-${i}`
        }))

        const formattedBase64 = base64Preview.map((b, i) => ({
            url: b.base64,
            id: `base64Preview-${i}`
        }))

        return [...formattedBase64, ...formattedImport, ...formattedExisting]
    }, [base64Preview, importImages, existingImages, removedImages])

    return (
        <Box
            sx={{
                position: "relative",
            }}
        >
            <Modal
                opened={!!uploadedFiles.started}
                onClose={() => { }}
                centered
                withCloseButton={false}
                closeOnClickOutside={false}
            >
                <Text align='center' fw="bold" fz={24}>Uploading Images</Text>
                <Text align='center' fw="bold" fz={16}>Please hold on...</Text>
                <Group position='center'>
                    <Loader />
                </Group>
                <Text align='center' fw="bold" fz={32}>{uploadedFiles.done}/{files.length}</Text>
                <Text align='center' fz={14}>failed: {uploadedFiles.failed}</Text>
                <Text align='center' color='red'>This process may take up to 5 minutes</Text>
                <Text align='center' color='red'>Please do not close or refresh this tab </Text>
            </Modal>
            <LightBox
                showLightBox={showLightbox}
                onClose={() => setShowLightbox(false)}
                // image={formattedData.offerImages[0]?.src || ""}
                images={mergePreviews()}
            />
            {/* <Modal
                fullScreen
                opened={showTnc}
                onClose={() => setBackConfirm(true)}
                closeOnClickOutside={false}
                closeOnEscape={false}
                withCloseButton={false}
                styles={{
                    'body': {
                        backgroundColor: 'whitesmoke',
                        padding: 0,
                        minHeight: '100svh'
                    }
                }}
            >
                <Stack spacing={8}>
                    <AgentRequestTnc />
                    <Divider />
                    <Group position="right" sx={{ width: '100%', padding: '16px' }}>
                        <Button onClick={() => setBackConfirm(true)} variant="outline">
                            Back
                        </Button>
                        <Button onClick={handleAcceptTnc}>
                            Accept
                        </Button>
                    </Group>
                </Stack>
            </Modal> */}
            {/* <Modal
                centered
                opened={submitConfirm}
                onClose={() => setSubmitConfirm(false)}
                title={id ? "Confirm Update Owner Listing?" : "Confirm Create Owner Listing?"}
            >
                <Stack>
                    <Group sx={{ width: "100%" }}>
                        <Button
                            onClick={() => setSubmitConfirm(false)}
                            sx={{ flexGrow: 1 }}
                            variant="outline"
                        >
                            No
                        </Button>
                        <Button
                            onClick={() => handleSubmit()}
                            sx={{ flexGrow: 1 }}
                        >
                            Yes
                        </Button>
                    </Group>
                </Stack>
            </Modal> */}
            <Modal
                centered
                opened={backConfirm}
                onClose={() => setBackConfirm(false)}
                title="Are you sure you want to leave this page?"
            >
                <Group sx={{ width: "100%" }}>
                    <Button
                        onClick={() => setBackConfirm(false)}
                        sx={{ flexGrow: 1 }}
                        variant="outline"
                    >
                        No
                    </Button>
                    <Button
                        onClick={() => navigate(-1)}
                        sx={{ flexGrow: 1 }}
                    >
                        Yes
                    </Button>
                </Group>
            </Modal>
            <Flex
                sx={{
                    minHeight: "100vh",
                    width: "100vw",
                    flexDirection: isMobile ? "column" : "row",
                }}
                m={0}
                align="stretch"
            >
                <Box sx={sidebarStyles}>
                    <Flex align="center" sx={stepperStyle}>
                        <Image
                            src="/logo.png"
                            width={isMobile ? 105 : 142}
                            height={isMobile ? 24 : 32}
                            onClick={() => navigate("/")}
                            sx={{ cursor: "pointer" }}
                        />
                        <Stepper
                            active={activeTab}
                            onStepClick={setActiveTab}
                            orientation={isMobile ? "horizontal" : "vertical"}
                            size="md"
                            iconSize={32}
                        >
                            <Stepper.Step
                                label={isMobile ? null : "Step 1"}
                                description={
                                    isMobile ? null : (
                                        <Text pr={8}>
                                            Property Details
                                        </Text>
                                    )
                                }
                                allowStepSelect={false}
                            />
                            <Stepper.Step
                                label={isMobile ? null : "Step 2"}
                                description={
                                    isMobile ? null : (
                                        <Text pr={8}>Advanced Details</Text>
                                    )
                                }
                                allowStepSelect={false}
                            />
                            <Stepper.Step
                                label={isMobile ? null : "Step 3"}
                                description={
                                    isMobile ? null : <Text pr={8}>Review</Text>
                                }
                                allowStepSelect={false}
                            />
                            {!user.jwt && (
                                <Stepper.Step
                                    label={isMobile ? null : "Step 4"}
                                    description={
                                        isMobile ? null : <Text pr={8}>Register</Text>
                                    }
                                    allowStepSelect={false}
                                />
                            )}
                        </Stepper>
                        {!isMobile && (
                            <Stack
                                // p={16}
                                sx={{ flex: 1, width: "100%" }}
                                justify="flex-end"
                                align="flex-start"
                            >
                                <Button
                                    sx={{ width: '100%' }}
                                    color="red"
                                    onClick={() => handleExit()}
                                >
                                    Exit
                                </Button>
                            </Stack>
                        )}
                    </Flex>
                </Box>
                <Box sx={{ backgroundColor: "#F5F5F5", flex: 1 }}>
                    <Container size="800px" px="md" py="lg">
                        <Stack spacing={16}>
                            {activeTab !== 3 && (
                                <Stack spacing={8}>
                                    <Text fz={32} fw={700}>
                                        {id
                                            ? "Update owner listing"
                                            : "Let us know more about your property"}
                                    </Text>
                                    <Text fz={12}>
                                        Provide us with detailed information to
                                        connect with specialized agents with ease
                                    </Text>
                                </Stack>
                            )}
                            {activeTab === 0 && (
                                <>
                                    <Stack pos="relative">
                                        <Select
                                            withAsterisk
                                            label="Transaction Type"
                                            placeholder="Pick one"
                                            data={TRANSACTION_TYPE_OPTIONS}
                                            {...form.getInputProps(
                                                "transactionType"
                                            )}
                                            onChange={(e) => {
                                                form.setFieldValue("transactionType", e as string)
                                            }}
                                            disabled={!!id}
                                        />
                                        <Select
                                            label="Property Type"
                                            placeholder="Pick one"
                                            data={propertyTypesArr}
                                            // disabled
                                            disabled={propertyTypesArr.length <= 1}
                                            searchable
                                            nothingFound="No options"
                                            filter={selectFilter}
                                            {...form.getInputProps(
                                                "propertyType"
                                            )}
                                        />
                                        <Flex
                                            sx={(theme) => ({
                                                justifyContent: 'center',
                                                gap: 8,
                                                [theme.fn.smallerThan("sm")]: {
                                                    flexDirection: 'column'
                                                }
                                            })}
                                        >
                                            <Select
                                                withAsterisk
                                                label="Property Title Type"
                                                placeholder="Pick one"
                                                radius="md"
                                                data={propRequestPropertyTitleOptions}
                                                {...form.getInputProps(
                                                    "propertyTitleType"
                                                )}
                                                sx={{ flex: 1 }}
                                            />
                                            <Select
                                                withAsterisk
                                                label="Tenure"
                                                placeholder="Pick one"
                                                radius="md"
                                                data={propRequestTenureOptions}
                                                {...form.getInputProps(
                                                    "tenure"
                                                )}
                                                sx={{ flex: 1 }}
                                            />
                                        </Flex>


                                        <Group position="right">
                                            <Button
                                                onClick={() => setActiveTab(1)}
                                                disabled={!formCheck.detail}
                                            // loading={createAgentRequest.isLoading || updateAgentRequest.isLoading}
                                            >
                                                {'Next'}
                                            </Button>
                                        </Group>
                                    </Stack>
                                </>
                            )}

                            {activeTab === 1 && (
                                <Stack sx={{ position: "relative" }} pb={84}>
                                    <Accordion
                                        value={accordionStep}
                                        chevron={<></>}
                                        disableChevronRotation
                                    >
                                        <Accordion.Item
                                            value={`${CREATE_REQUEST_STEPS.DETAILS}`}
                                            sx={{ borderBottom: "none" }}
                                        >
                                            <Accordion.Control
                                                sx={{
                                                    background: "transparent",
                                                    "&:hover": {
                                                        background:
                                                            "transparent",
                                                    },
                                                }}
                                                px={0}
                                                chevron={
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .DETAILS
                                                    ] ? (
                                                        <CircleWavyCheck
                                                            size={48}
                                                            strokeWidth={2}
                                                            color={"green"}
                                                        />
                                                    ) : null
                                                }
                                                onClick={() => {
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .DETAILS
                                                    ] &&
                                                        setAccordionStep(
                                                            CREATE_REQUEST_STEPS.DETAILS.toString()
                                                        );
                                                }}
                                            // disabled={parseInt(accordionStep) < i + 1}
                                            >
                                                1. Let us know the details!
                                            </Accordion.Control>
                                            <Accordion.Panel px={0}>
                                                <Stack>
                                                    <Box pos={"relative"}>
                                                        <ImageInput
                                                            files={files}
                                                            setFiles={setFiles}
                                                            base64Preview={base64Preview}
                                                            setBase64Preview={setBase64Preview}
                                                            imagesError={imagesError}
                                                            existingImages={existingImages}
                                                            customTextTop="Listed by owner"
                                                            customTextBottom="on www.propmoth.com"
                                                            removedImages={removedImages}
                                                            setRemovedImages={setRemovedImages}
                                                        />
                                                    </Box>

                                                    <TagForm setTagForm={setTagForm} tagForm={tagForm} />
                                                </Stack>
                                                <NextButtonRow
                                                    hasNext
                                                    currStep={`${CREATE_REQUEST_STEPS.DETAILS}`}
                                                    disabled={!formCheck.accordion[CREATE_REQUEST_STEPS.DETAILS]}
                                                    setAccordionStep={setAccordionStep}
                                                />
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            value={`${CREATE_REQUEST_STEPS.LOCATION}`}
                                            sx={{ borderBottom: "none" }}
                                        >
                                            <Accordion.Control
                                                sx={{
                                                    background: "transparent",
                                                    "&:hover": {
                                                        background:
                                                            "transparent",
                                                    },
                                                }}
                                                px={0}
                                                chevron={
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .LOCATION
                                                    ] ? (
                                                        <IconCircleCheck
                                                            size={48}
                                                            strokeWidth={2}
                                                            color={"green"}
                                                        />
                                                    ) : null
                                                }
                                                onClick={() => {
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .LOCATION
                                                    ] &&
                                                        setAccordionStep(
                                                            CREATE_REQUEST_STEPS.LOCATION.toString()
                                                        );
                                                }}
                                            >
                                                2. Where are you looking to{" "}
                                                {form.values.transactionType.toLowerCase()}
                                                ?
                                            </Accordion.Control>
                                            <Accordion.Panel px={0}>
                                                <Stack pos="relative">

                                                    <Flex
                                                        sx={(theme) => ({
                                                            justifyContent: 'center',
                                                            gap: 8,
                                                            [theme.fn.smallerThan("sm")]: {
                                                                flexDirection: 'column'
                                                            }
                                                        })}
                                                    >

                                                        <Select
                                                            sx={{
                                                                flex: 1,
                                                            }}
                                                            withAsterisk
                                                            label="State"
                                                            placeholder="Pick one"
                                                            data={
                                                                states
                                                            }
                                                            searchable
                                                            nothingFound="No options"
                                                            filter={
                                                                selectFilter
                                                            }
                                                            {...form.getInputProps(
                                                                "state"
                                                            )}
                                                            onChange={(
                                                                e
                                                            ) => {
                                                                form.setFieldValue(
                                                                    "state",
                                                                    e ||
                                                                    ""
                                                                );
                                                                form.setFieldValue(
                                                                    "area",
                                                                    ""
                                                                );
                                                            }}
                                                        />
                                                        <Select
                                                            sx={{
                                                                flex: 1,
                                                            }}
                                                            withAsterisk
                                                            label="Area"
                                                            placeholder="Pick one"
                                                            data={
                                                                !form
                                                                    .values
                                                                    .state
                                                                    ? []
                                                                    : areas
                                                            }
                                                            disabled={
                                                                !form
                                                                    .values
                                                                    .state
                                                            }
                                                            searchable
                                                            nothingFound="No options"
                                                            filter={
                                                                selectFilter
                                                            }
                                                            {...form.getInputProps(
                                                                "area"
                                                            )}
                                                        />
                                                    </Flex>

                                                    <Textarea
                                                        withAsterisk
                                                        label="Address"
                                                        {...form.getInputProps(
                                                            "address"
                                                        )}
                                                    />

                                                    <NextButtonRow
                                                        hasNext
                                                        hasBack={true}
                                                        currStep={`${CREATE_REQUEST_STEPS.LOCATION}`}
                                                        disabled={!formCheck.accordion[CREATE_REQUEST_STEPS.LOCATION]}
                                                        setAccordionStep={setAccordionStep}
                                                    />
                                                    {/* <Group position="right">
                                                        <Button
                                                            onClick={() => validateForm()}
                                                            loading={createAgentRequest.isLoading || updateAgentRequest.isLoading}
                                                        >
                                                            {!!id ? 'Update' : 'Create'}
                                                        </Button>
                                                    </Group> */}
                                                </Stack>
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            value={`${CREATE_REQUEST_STEPS.SIZE_AND_AMMENITIES}`}
                                            sx={{ borderBottom: "none" }}
                                        >
                                            <Accordion.Control
                                                sx={{
                                                    background: "transparent",
                                                    "&:hover": {
                                                        background:
                                                            "transparent",
                                                    },
                                                }}
                                                px={0}
                                                chevron={
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .SIZE_AND_AMMENITIES
                                                    ] ? (
                                                        <IconCircleCheck
                                                            size={48}
                                                            strokeWidth={2}
                                                            color={"green"}
                                                        />
                                                    ) : null
                                                }
                                                onClick={() => {
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .SIZE_AND_AMMENITIES
                                                    ] &&
                                                        setAccordionStep(
                                                            CREATE_REQUEST_STEPS.SIZE_AND_AMMENITIES.toString()
                                                        );
                                                }}
                                            >
                                                3. What is your desired size?
                                            </Accordion.Control>
                                            <Accordion.Panel px={0}>
                                                <TextInput
                                                    withAsterisk
                                                    label="Floor size"
                                                    type="number"
                                                    radius="md"
                                                    {...form.getInputProps(
                                                        "floorSize"
                                                    )}
                                                    rightSection={
                                                        <Text
                                                            sx={{
                                                                color: "#adb5bd",
                                                            }}
                                                        >
                                                            Sqft
                                                        </Text>
                                                    }
                                                    rightSectionWidth={
                                                        45
                                                    }
                                                />
                                                <Flex
                                                    sx={(theme) => ({
                                                        justifyContent: 'center',
                                                        gap: 8,
                                                        [theme.fn.smallerThan("sm")]: {
                                                            flexDirection: 'column'
                                                        }
                                                    })}
                                                >
                                                    <BathroomsInput form={form} />
                                                    <BedroomsInput form={form} />
                                                </Flex>

                                                <NextButtonRow
                                                    hasNext
                                                    hasBack={true}
                                                    currStep={`${CREATE_REQUEST_STEPS.SIZE_AND_AMMENITIES}`}
                                                    disabled={!formCheck.accordion[CREATE_REQUEST_STEPS.SIZE_AND_AMMENITIES]}
                                                    setAccordionStep={setAccordionStep}
                                                />
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            value={`${CREATE_REQUEST_STEPS.BUDGET}`}
                                            sx={{ borderBottom: "none" }}
                                        >
                                            <Accordion.Control
                                                sx={{
                                                    background: "transparent",
                                                    "&:hover": {
                                                        background:
                                                            "transparent",
                                                    },
                                                }}
                                                px={0}
                                                chevron={
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .BUDGET
                                                    ] ? (
                                                        <IconCircleCheck
                                                            size={48}
                                                            strokeWidth={2}
                                                            color={"green"}
                                                        />
                                                    ) : null
                                                }
                                                onClick={() => {
                                                    formCheck.accordion[CREATE_REQUEST_STEPS.BUDGET] && setAccordionStep(CREATE_REQUEST_STEPS.BUDGET.toString());
                                                }}
                                            >
                                                4. Tell us your asking price
                                            </Accordion.Control>
                                            <Accordion.Panel px={0}>
                                                <TextInput
                                                    withAsterisk
                                                    label="Asking price"
                                                    type="number"
                                                    onWheel={(evt) => evt.currentTarget.blur()}
                                                    radius="md"
                                                    {...form.getInputProps(
                                                        "askingPrice"
                                                    )}
                                                    icon={
                                                        <Text sx={{ lineHeight: 1, }}>
                                                            RM
                                                        </Text>
                                                    }
                                                />
                                                <NextButtonRow
                                                    hasNext
                                                    hasBack={true}
                                                    currStep={`${CREATE_REQUEST_STEPS.BUDGET}`}
                                                    disabled={!formCheck.accordion[CREATE_REQUEST_STEPS.BUDGET]}
                                                    setAccordionStep={setAccordionStep}
                                                />
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            value={`${CREATE_REQUEST_STEPS.ADDITIONAL_NOTES}`}
                                            sx={{ borderBottom: "none" }}
                                        >
                                            <Accordion.Control
                                                sx={{
                                                    background: "transparent",
                                                    "&:hover": {
                                                        background:
                                                            "transparent",
                                                    },
                                                }}
                                                px={0}
                                                onClick={() => {
                                                    formCheck.accordion[
                                                        CREATE_REQUEST_STEPS
                                                            .BUDGET
                                                    ] &&
                                                        setAccordionStep(
                                                            CREATE_REQUEST_STEPS.ADDITIONAL_NOTES.toString()
                                                        );
                                                }}
                                            >
                                                5. Provide more details about your listing
                                            </Accordion.Control>
                                            <Accordion.Panel px={0}>
                                                <Textarea
                                                    withAsterisk
                                                    label="Description"
                                                    {...form.getInputProps(
                                                        "description"
                                                    )}
                                                />
                                                <NextButtonRow
                                                    hasNext
                                                    nextFn={() => {
                                                        setActiveTab(2);
                                                    }}
                                                    hasBack={true}
                                                    currStep={`${CREATE_REQUEST_STEPS.ADDITIONAL_NOTES}`}
                                                    isFinal={true}
                                                    disabled={!form.values.description || form.values.description.length < 20}
                                                    setAccordionStep={setAccordionStep}
                                                />
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                    </Accordion>
                                    <Group
                                        sx={{
                                            width: isMobile
                                                ? "100%"
                                                : "calc(100% - 250px)",
                                            justifyContent: isMobile ? 'space-between' : 'flex-end',
                                            position: "fixed",
                                            bottom: 0,
                                            right: 0,
                                            background: "white",
                                        }}
                                        p={16}
                                    >

                                        {!!isMobile && (
                                            <Group>
                                                <Button
                                                    color="red"
                                                    onClick={() => handleExit()}
                                                    size="xs"
                                                >
                                                    Exit
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setActiveTab(0);
                                                    }}
                                                    variant="subtle"
                                                    size="xs"
                                                >
                                                    Back
                                                </Button>
                                            </Group>
                                        )}
                                        <Group
                                            position="right"
                                            p={0}
                                        // sx={{ flexGrow: 1 }}
                                        >
                                            {!!formCheck.accordion.allReady && (
                                                <Button
                                                    onClick={() => {
                                                        setActiveTab(2);
                                                    }}
                                                    size="xs"
                                                >
                                                    Next
                                                </Button>
                                            )}
                                        </Group>
                                    </Group>
                                </Stack>
                            )}

                            {activeTab === 2 && (
                                <Stack>
                                    <Card sx={{ border: "1px solid #BFC8C3" }}>
                                        <Stack>
                                            <EditSectionHeader
                                                title={"Property Request Type"}
                                                onClick={() => {
                                                    setActiveTab(0);
                                                }}
                                            />
                                            <Group>
                                                <TransactionTypeBadge transactionType={form.values.transactionType} />
                                            </Group>
                                            <Group spacing={4}>
                                                <Text fw={700}>
                                                    Property Title Type:
                                                </Text>
                                                <Text fw={400}>
                                                    {getReadableValueFromSelect(
                                                        propRequestPropertyTitleOptions,
                                                        form.values
                                                            .propertyTitleType
                                                    )}
                                                </Text>
                                            </Group>
                                            <Group spacing={4}>
                                                <Text fw={700}>
                                                    Property Type:{" "}
                                                </Text>
                                                <Text fw={400}>
                                                    {getReadableValueFromSelect(
                                                        propertyTypesArr,
                                                        form.values
                                                            .propertyType
                                                    )}
                                                </Text>
                                            </Group>
                                            <Group spacing={4}>
                                                <Text fw={700}>
                                                    Tenure:{" "}
                                                </Text>
                                                <Text fw={400}>
                                                    {getReadableValueFromSelect(
                                                        propRequestTenureOptions,
                                                        form.values.tenure
                                                    )}
                                                </Text>
                                            </Group>
                                        </Stack>
                                    </Card>
                                    <Card sx={{ border: "1px solid #BFC8C3" }}>
                                        <Stack>
                                            <Stack spacing={0}>
                                                <Text fz={20} fw={700} pb={8}>
                                                    Advanced Details
                                                </Text>
                                                <Divider my={8} />
                                            </Stack>
                                            <EditSectionHeader
                                                title={"Request Details"}
                                                onClick={() => {
                                                    setActiveTab(1);
                                                    setAccordionStep(
                                                        CREATE_REQUEST_STEPS.DETAILS.toString()
                                                    );
                                                }}
                                            />
                                            <Stack spacing={2}>
                                                <Text fw={700}>Images</Text>
                                                <Group>
                                                    <Indicator
                                                        inline
                                                        label={`+ ${mergePreviews().length -
                                                            1
                                                            }`}
                                                        disabled={
                                                            mergePreviews().length <=
                                                            1
                                                        }
                                                        size={16}
                                                    >
                                                        <Tooltip
                                                            label="Click to view"
                                                            position="right"
                                                        >
                                                            <Center
                                                                sx={{
                                                                    position:
                                                                        "relative",
                                                                    cursor: "pointer",
                                                                    border: "1px #dbdbdb solid",
                                                                    borderRadius: 8,
                                                                    width: "auto",
                                                                }}
                                                                w={120}
                                                                h={120}
                                                                onClick={() => setShowLightbox(true)}
                                                            >
                                                                <Image
                                                                    width={"100%"}
                                                                    height={"100%"}
                                                                    fit="contain"
                                                                    src={mergePreviews()[0]?.url}
                                                                />
                                                            </Center>
                                                        </Tooltip>
                                                    </Indicator>
                                                </Group>
                                            </Stack>
                                            <Group>
                                                <Text fw={700}>Tags: </Text>
                                                <Group>
                                                    {badgeArr.map((b: any, i: number) => (
                                                        <Badge
                                                            key={`badge-${i}`}
                                                            sx={{ backgroundColor: b.background, color: b.color }}
                                                        >
                                                            {b?.title}
                                                        </Badge>
                                                    ))}
                                                </Group>
                                            </Group>
                                            <Divider my={8} />
                                            <EditSectionHeader
                                                title={"Location"}
                                                onClick={() => {
                                                    setActiveTab(1);
                                                    setAccordionStep(
                                                        CREATE_REQUEST_STEPS.LOCATION.toString()
                                                    );
                                                }}
                                            />
                                            <Stack>
                                                <Text>
                                                    {form.values.area},{" "}
                                                    {form.values.state}
                                                </Text>
                                                <Text>
                                                    Address: {form.values.address}
                                                </Text>
                                            </Stack>
                                            <Divider my={8} />
                                            <EditSectionHeader
                                                title={
                                                    "Property Size and Amenities"
                                                }
                                                onClick={() => {
                                                    setActiveTab(1);
                                                    setAccordionStep(
                                                        CREATE_REQUEST_STEPS.SIZE_AND_AMMENITIES.toString()
                                                    );
                                                }}
                                            />
                                            <Group>
                                                <Group spacing={8}>
                                                    <Text>
                                                        {form.values.floorSize}sqft
                                                    </Text>
                                                </Group>
                                                <Group spacing={8}>
                                                    <Bed />
                                                    <Text>{form.values.noOfBedrooms}{" "}Bedroom</Text>
                                                </Group>
                                                <Group spacing={8}>
                                                    <Bathtub />
                                                    <Text>{form.values.noOfBathrooms}{" "}Bathroom</Text>
                                                </Group>
                                            </Group>
                                            <Divider my={8} />
                                            <EditSectionHeader
                                                title={form.values.transactionType === "RENT" ? "Budget" : "Income Range"}
                                                onClick={() => {
                                                    setActiveTab(1);
                                                    setAccordionStep(
                                                        CREATE_REQUEST_STEPS.BUDGET.toString()
                                                    );
                                                }}
                                            />
                                            <Group spacing={8}>
                                                {form.values.transactionType === "RENT" ? (
                                                    <Text>RM{form.values.askingPrice} /month</Text>
                                                ) : (
                                                    <Text>
                                                        RM{form.values.askingPrice}
                                                    </Text>
                                                )}
                                            </Group>
                                            <Divider my={8} />
                                            <EditSectionHeader
                                                title={"Additional Notes"}
                                                onClick={() => {
                                                    setActiveTab(1);
                                                    setAccordionStep(
                                                        CREATE_REQUEST_STEPS.ADDITIONAL_NOTES.toString()
                                                    );
                                                }}
                                            />
                                            <Group spacing={8}>
                                                <Text>
                                                    {!!form.values
                                                        .description
                                                        ? form.values
                                                            .description
                                                        : "-"}
                                                </Text>
                                            </Group>
                                        </Stack>
                                    </Card>

                                    <Group
                                        position="right"
                                        sx={{ width: "100%" }}
                                        mt={16}
                                    >
                                        <Button
                                            onClick={() => {
                                                setActiveTab(1);
                                            }}
                                            variant="subtle"
                                        >
                                            Back
                                        </Button>
                                        {!!id ? (
                                            <Button
                                                onClick={() => handleUpdateAgentRequest()}
                                            // loading={createLoading}
                                            >
                                                Update Owner Listing
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={() => handleCreateAgentRequest()}
                                            // loading={createLoading}
                                            >
                                                Create Owner Listing
                                            </Button>
                                        )}
                                    </Group>
                                </Stack>
                            )}
                            {activeTab === 3 && (
                                <MiniRegisterForm from="owner" />
                            )}
                        </Stack>
                    </Container>
                </Box>
            </Flex>

        </Box>
    )
}

export default AgentRequestForm