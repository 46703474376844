import API from "../api/API";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { flattenObj } from "helpers/strapi";
import { Permission, useAuth } from "hooks/useAuth";
const qs = require("qs");

export const usePotentialAgentAPI = () => {
    const { user } = useAuth(Permission.USER_ONLY);

    const useGetAgencies = () =>
        useQuery(["GET_AGENCIES"], async () => {
            try {
                const response = await API({
                    requireAuth: true,
                    token: user.jwt,
                }).get<any, any>(`agencies?populate=*`);

                return flattenObj(response.data);
            } catch (e) {
                return e;
            }
        });

    const useCreatePotentialAgent = () =>
        useMutation(async (payload: any) => {
            const resp = await API({ requireAuth: true, token: user.jwt }).post<
                any,
                any
            >(`potential-agents`, {
                data: { ...payload },
            });

            return flattenObj(resp);
        });

    const useUpdatePotentialAgent = () =>
        useMutation(async (payload: any) => {
            const resp = await API({ requireAuth: true, token: user.jwt }).put<
                any,
                any
            >(`potential-agents/${payload.id}`, {
                data: { ...payload.data },
            });

            return flattenObj(resp);
        });

    const getPotentialAgentData = async (agentProfileId: number) => {
        const query = qs.stringify(
            {
                populate: "*",
                filters: {
                    user: {
                        id: {
                            $eq: agentProfileId,
                        }
                    },
                },
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );

        const response = await API({
            requireAuth: true,
            token: user.jwt,
        }).get<any, any>(`potential-agents?${query}`);

        return flattenObj(response.data);
    };

    const useGetOnePotentialAgent = (userId: number) =>
        useQuery(
            ["GET_ONE_POTENTIAL_AGENT", userId],
            () => getPotentialAgentData(userId),
            {
                enabled: !!userId,
            }
        );

    return {
        useGetAgencies,
        useCreatePotentialAgent,
        useGetOnePotentialAgent,
        useUpdatePotentialAgent
    };
};
