import { ActionIcon, BackgroundImage, Badge, Box, Button, Card, Center, Container, Divider, Flex, Grid, Group, Image, Loader, Modal, Stack, Text, Textarea, Title, useMantineTheme } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { Files, Trash } from '@phosphor-icons/react';
import { useAgencyAPI } from 'api/useAgencyAPI';
import PageLoader from 'components/LoadingStates/PageLoader';
import { convertFileToPreview } from 'helpers/utility';
import { useUpload } from 'hooks/useUpload';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProfileLayoutTemplate from './AgentDashboard/components/ProfileLayoutTemplate';
import { useMediaQuery } from '@mantine/hooks';
import AgencyCard from './AgentDashboard/components/AgencyCard';

type imageFormKeys = "imageRow1" | "imageRow2" | "imageRow3"

const AgencyProfile = () => {
    const { slug } = useParams()
    const { useGetOneAgencyBySlug } = useAgencyAPI()
    const { data, isLoading, error } = useGetOneAgencyBySlug(slug ?? "")
    const theme = useMantineTheme()
    const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

    const agencyData = useMemo(() => {
        if (!!data) {
            return data[0]
        }
        return {}
    }, [data])

    const layoutData = useMemo(() => {

        if (!!data && data.length > 0) {
            return data[0].profile
        }
        return {
            "text": {
                "title": "",
                "description": "",
                "textRow1": "",
                "textRow2": "",
                "textRow3": "3"
            },
            "images": {
                "imageRow1": {
                    "file": null
                },
                "imageRow2": {
                    "file": null
                },
                "imageRow3": {
                    "file": null
                }
            }
        }
    }, [data])

    if (!!isLoading) {
        <PageLoader title="Agency Profile" />
    }

    return (
        <Box pos="relative">
            <Container py={32}>
                <Stack>
                    {!!agencyData && (
                        <AgencyCard
                            agency={agencyData}
                            onLearnMore={() => { }}
                            onApply={() => { }}
                        />
                    )}
                    <ProfileLayoutTemplate data={layoutData} />
                </Stack>
            </Container>
        </Box>
    );
};

export default AgencyProfile;