//@ts-ignore
import ReCAPTCHA from "react-google-recaptcha";

import { Anchor, Box, Button, Card, Center, Checkbox, Container, Flex, Grid, Group, Image, PasswordInput, Stack, Stepper, Sx, Text, TextInput, Title, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { BaseFormInput } from 'components/Inputs/BaseFormInput';
import { strictNumberInput, validateEmail } from 'helpers/validations';
import { Permission, useAuth } from 'hooks/useAuth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "@mantine/form";
import { RegisterOtp } from "pages/RegisterOtp";
import { accountTypes } from "types/accountTypes";
import { sanitizePhoneNumber } from "helpers/utility";
import { formStep, validateUserStatus } from "helpers/constants";
import { Envelope, Phone, WhatsappLogo } from "@phosphor-icons/react";
import AgencySelection from "./AgencySelection";
import { usePotentialAgentAPI } from "api/usePotentialAgentAPI";
import { notifications } from "@mantine/notifications";

const PotentialAgentForm = () => {
    const contactMethodSelection = [
        {
            title: "Whatsapp",
            value: "whatsapp",
            logo: <WhatsappLogo size={24} />
        },
        {
            title: "Phone Call",
            value: "phoneCall",
            logo: <Phone size={24} />
        },
        {
            title: "Email",
            value: "email",
            logo: <Envelope size={24} />
        },
    ]
    const [captchaPassed, setCaptchaPassed] = useState(false)
    const [contactMethod, setContactMethod] = useState("")
    const [checkboxes, setCheckboxes] = useState({
        tnc: false,
        privacy: false
    })
    const navigate = useNavigate()
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const [activeTab, setActiveTab] = useState(0)
    const [verifyData, setVerifyData] = useState<any>(null);
    const { useCreatePotentialAgent, useGetOnePotentialAgent, useUpdatePotentialAgent } = usePotentialAgentAPI()
    const createPotentialAgent = useCreatePotentialAgent()
    const updatePotentialAgent = useUpdatePotentialAgent()
    const [contactMethodError, setContactMethodError] = useState<null | string>(null)

    const {
        user,
        registerMutation,
        userDispatch,
        validateMutation,
        requestOtpMutation,
        profileData
    } = useAuth(Permission.ANYONE);

    const { data, isLoading, error, refetch } = useGetOnePotentialAgent(user?.id)

    useEffect(() => {
        console.log("data", data)
        if (data?.length > 0) {
            let potentialAgentData = data[0]
            setContactMethod(potentialAgentData.contactMethod)
        }
    }, [data])

    const appliedAgencies = useMemo(() => {
        if (data?.length > 0) {
            let potentialAgentData = data[0]

            return potentialAgentData.selectedAgencies.map((a: any) => a.id)
        }

        return []
    }, [data])


    const form = useForm({
        initialValues: {
            fullname: "",
            email: "",
            phoneNumber: "",
            password: "",
            passwordConfirm: "",
        },
        // initialValues: {
        //     fullname: "testing",
        //     email: "testing@ian.com",
        //     phoneNumber: "",
        //     password: "password",
        //     passwordConfirm: "password",
        // },
        validateInputOnChange: true,
        validate: (values) => ({
            fullname:
                values.fullname.length === 0 ? "Full name is required" : null,
            email: validateEmail(values.email),
            phoneNumber:
                values.phoneNumber.length === 0
                    ? "Phone number is required"
                    : null,
            passwordConfirm:
                values.passwordConfirm !== values.password
                    ? "Passwords do not match"
                    : null,
        }),
    });

    const allowProceed = useMemo(
        () => Object.values(checkboxes).every((value) => !!value === true) && !!contactMethod,
        [checkboxes, contactMethod]
    );


    const sidebarStyles = isMobile
        ? ({
            position: "initial",
            width: "100%",
            padding: "16px",
        } as Sx)
        : ({
            position: "relative",
            minWidth: "250px",
            paddingTop: "16px",
        } as Sx);

    const stepperStyle = isMobile
        ? ({
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
        } as Sx)
        : ({
            position: "fixed",
            left: 0,
            top: 0,
            minWidth: "220px",
            height: "100vh",
            flexDirection: "column",
            padding: "16px",
            gap: "24px",
        } as Sx);

    const handleExit = useCallback(() => {
        if (!!user.jwt) {
            return navigate("/profile")
        }
        return navigate("/")
    }, [user])

    useEffect(() => {
        if (!!user.jwt) {
            setActiveTab(2)
        }
    }, [user])

    const handleCheckboxChange = (key: string) => {
        setCheckboxes((prevState: any) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const handleRegisterCheck = useCallback(() => {
        let formattedIdentifier = sanitizePhoneNumber(`${form.values.phoneNumber}`);
        const { hasErrors } = form.validate()

        if (!!hasErrors) {
            return
        }

        setVerifyData({
            ...form.values,
            userType: accountTypes.LISTER,
            requestId: "dev",
            phoneNumber: `60${formattedIdentifier}`,
            username: `60${formattedIdentifier}`,
            from: 'prospect'
        });

        if (!!process.env.REACT_APP_IS_DEV) {
            setActiveTab(formStep.OTP);
            setVerifyData({
                ...form.values,
                userType: accountTypes.LISTER,
                requestId: "dev",
                phoneNumber: `60${formattedIdentifier}`,
                username: `60${formattedIdentifier}`,
                from: 'prospect'
            });
        } else {
            validateMutation.mutate(
                {
                    ...form.values,
                    phoneNumber: `60${formattedIdentifier}`,
                },
                {
                    onSuccess: (validateData) => {
                        switch (validateData.status) {
                            case validateUserStatus.VALID: {
                                requestOtpMutation.mutate(
                                    { phoneNumber: `60${form.values.phoneNumber}` },
                                    {
                                        onSuccess: (data) => {

                                            setVerifyData({
                                                ...validateData.verifyUser,
                                                userType: accountTypes.LISTER,
                                                requestId: data.data.requestId,
                                            });
                                            setActiveTab(formStep.OTP);
                                        },
                                    }
                                );
                                break;
                            }
                            case validateUserStatus.INVALID: {
                                for (const [field, error] of Object.entries(
                                    validateData.formErrors
                                )) {
                                    form.setFieldError(field, error as string);
                                }
                                break;
                            }
                        }
                    },
                }
            );
        }
    }, [form.values])

    const handleApply = useCallback(async (agency: any) => {
        if (!contactMethod) {
            setContactMethodError("please choose a contact method")
            return
        }
        const [potentialAgentData] = data

        try {
            if (!!potentialAgentData) {
                await updatePotentialAgent.mutateAsync({
                    data:{
                        selectedAgencies: {
                            connect: [agency.id]
                        },
                    },
                    id: potentialAgentData.id
                })
            } else {
                await createPotentialAgent.mutateAsync({
                    contactMethod: contactMethod,
                    selectedAgencies: {
                        set: [agency.id]
                    },
                    user
                })
            }
            notifications.show({
                title: "You have successfully applied",
                message: "The agency will contact you soon for more details",
            });

            refetch()
        } catch (e) {
            console.log(e)
            notifications.show({
                title: "Error applying",
                message: "If this issue persist, please contact our support",
                autoClose: 2000,
            });
        }
    }, [data, contactMethod, user])

    return (
        <>
            <Flex
                sx={{
                    minHeight: "100vh",
                    width: "100vw",
                    flexDirection: isMobile ? "column" : "row",
                }}
                m={0}
                align="stretch"
            >
                <Box sx={sidebarStyles}>
                    <Flex align="center" sx={stepperStyle}>
                        <Image
                            src="/logo.png"
                            width={isMobile ? 105 : 142}
                            height={isMobile ? 24 : 32}
                            onClick={() => navigate("/")}
                            sx={{ cursor: "pointer" }}
                        />
                        <Stepper
                            active={activeTab}
                            onStepClick={setActiveTab}
                            orientation={isMobile ? "horizontal" : "vertical"}
                            size="md"
                            iconSize={32}
                        >
                            <Stepper.Step
                                label={isMobile ? null : "Step 1"}
                                description={
                                    isMobile ? null : (
                                        <Text pr={8}>
                                            Contact Info
                                        </Text>
                                    )
                                }
                                allowStepSelect={false}
                            />
                            <Stepper.Step
                                label={isMobile ? null : "Step 2"}
                                description={
                                    isMobile ? null : <Text pr={8}>Verification</Text>
                                }
                                allowStepSelect={false}
                            />
                            <Stepper.Step
                                label={isMobile ? null : "Step 3"}
                                description={
                                    isMobile ? null : <Text pr={8}>Browse and connect</Text>
                                }
                                allowStepSelect={false}
                            />
                        </Stepper>
                        {!isMobile && (
                            <Stack
                                // p={16}
                                sx={{ flex: 1, width: "100%" }}
                                justify="flex-end"
                                align="flex-start"
                            >
                                <Button
                                    sx={{ width: '100%' }}
                                    color="red"
                                    onClick={() => handleExit()}
                                >
                                    Exit
                                </Button>
                            </Stack>
                        )}
                    </Flex>
                </Box>
                <Box sx={{ backgroundColor: "#F5F5F5", flex: 1 }}>
                    <Container size="800px" px="md" py="lg" mih={"100vh"}>
                        <Stack spacing={16} h={"100%"}>
                            {activeTab === 0 && (
                                <>
                                    <Title order={2}>Tell us who you are</Title>
                                    <TextInput
                                        label="Full name"
                                        {...form.getInputProps("fullname")}
                                    />
                                    <TextInput
                                        label="Email Address"
                                        {...form.getInputProps("email")}
                                    />
                                    <Stack spacing={4}>
                                        <BaseFormInput
                                            type="number"
                                            label="Phone Number"
                                            icon={
                                                <Text
                                                    color="black"
                                                    sx={{ whiteSpace: "nowrap" }}
                                                >
                                                    {`MY (+60)`}
                                                </Text>
                                            }
                                            iconWidth={90}
                                            required
                                            onKeyDown={(evt: any) => strictNumberInput(evt)}
                                            {...form.getInputProps("phoneNumber")}
                                        />
                                        {!!form.errors?.phoneNumber && (
                                            <Text
                                                mt={5}
                                                size={14}
                                                color="red"
                                                sx={{ lineHeight: 1.2 }}
                                            >
                                                Please double-check this number
                                            </Text>
                                        )}
                                    </Stack>
                                    <PasswordInput
                                        label="Password"
                                        {...form.getInputProps("password")}
                                    />
                                    <PasswordInput
                                        label="Confirm Password"
                                        {...form.getInputProps("passwordConfirm")}
                                    />

                                    <Grid>
                                        <Grid.Col span={12}>
                                            <Title order={2}>How would you like to be contacted?</Title>
                                        </Grid.Col>
                                        {contactMethodSelection.map(c => (
                                            <Grid.Col sm={4} md={4}>
                                                <Card
                                                    shadow="sm"
                                                    onClick={() => {
                                                        console.log(c.value)
                                                        setContactMethod(c.value)
                                                    }}
                                                    sx={{
                                                        border: contactMethod === c.value ? "3px solid #417256" : "2px solid #ddd",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <Group>
                                                        <Checkbox checked={contactMethod === c.value} />
                                                        <Group spacing={8}>
                                                            {c.logo}
                                                            <Text>{c.title}</Text>
                                                        </Group>
                                                    </Group>
                                                </Card>
                                            </Grid.Col>
                                        ))}
                                    </Grid>
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                                        onChange={(e: any) => {
                                            setCaptchaPassed(!!e)
                                        }}
                                    />
                                    <Checkbox
                                        mt={8}
                                        label={
                                            <Text>
                                                I have read and agree to the{" "}
                                                <Anchor
                                                    href="/termsandconditions"
                                                    target="_blank"
                                                    sx={{ textDecoration: "underline" }}
                                                >
                                                    Terms of Service
                                                </Anchor>
                                            </Text>
                                        }
                                        sx={{
                                            ".mantine-Checkbox-body": {
                                                display: "flex",
                                                alignItems: "center",
                                            },
                                        }}
                                        checked={checkboxes.tnc}
                                        onChange={() => handleCheckboxChange("tnc")}
                                        mb={16}
                                    />
                                    <Checkbox
                                        label={
                                            <Text>
                                                I have read and agree to the{" "}
                                                <Anchor
                                                    href="/privacypolicy"
                                                    target="_blank"
                                                    sx={{ textDecoration: "underline" }}
                                                >
                                                    Privacy Policy
                                                </Anchor>
                                            </Text>
                                        }
                                        sx={{
                                            ".mantine-Checkbox-body": {
                                                display: "flex",
                                                alignItems: "center",
                                            },
                                        }}
                                        checked={checkboxes.privacy}
                                        onChange={() => handleCheckboxChange("privacy")}
                                    />
                                    <Button disabled={!allowProceed} onClick={() => handleRegisterCheck()}>Next</Button>
                                </>
                            )}
                            {activeTab === 1 && (
                                <>
                                    <RegisterOtp
                                        setView={setActiveTab}
                                        verifyData={verifyData}
                                        nextStep={() => setActiveTab(2)}
                                    />
                                </>
                            )}
                            {activeTab === 2 && (
                                <Stack>
                                    {!contactMethod ? (
                                        <Center w="100%" h="100%" >
                                            <Grid>
                                                <Grid.Col span={12}>
                                                    <Title order={2}>How would you like to be contacted?</Title>
                                                </Grid.Col>
                                                {contactMethodSelection.map(c => (
                                                    <Grid.Col sm={4} md={4}>
                                                        <Card
                                                            shadow="sm"
                                                            onClick={() => {
                                                                console.log(c.value)
                                                                setContactMethod(c.value)
                                                            }}
                                                            sx={{
                                                                border: contactMethod === c.value ? "3px solid #417256" : "2px solid #ddd",
                                                                cursor: "pointer"
                                                            }}
                                                        >
                                                            <Group>
                                                                <Checkbox checked={contactMethod === c.value} />
                                                                <Group spacing={8}>
                                                                    {c.logo}
                                                                    <Text>{c.title}</Text>
                                                                </Group>
                                                            </Group>
                                                        </Card>
                                                    </Grid.Col>
                                                ))}
                                                {!!contactMethodError && (
                                                    <Grid.Col span={12}>
                                                        <Text color="red">{contactMethodError}</Text>
                                                    </Grid.Col>
                                                )}
                                            </Grid>
                                        </Center>
                                    ) : (
                                        <AgencySelection
                                            handleApply={handleApply}
                                            applied={appliedAgencies}
                                        />
                                    )}
                                </Stack>
                            )}
                        </Stack>
                    </Container>
                </Box>
            </Flex>

        </>
    );
};

export default PotentialAgentForm;