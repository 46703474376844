import { ActionIcon, BackgroundImage, Box, Button, Card, Center, Container, Divider, Grid, Group, Image, Loader, Modal, Stack, Text, Textarea, Title } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { Files, Trash } from '@phosphor-icons/react';
import { useAgencyAPI } from 'api/useAgencyAPI';
import PageLoader from 'components/LoadingStates/PageLoader';
import { convertFileToPreview } from 'helpers/utility';
import { useUpload } from 'hooks/useUpload';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

type imageFormKeys = "imageRow1" | "imageRow2" | "imageRow3"

const ProfileLayoutTemplate = ({ data }: { data: any }) => {
    const layoutData = useMemo(() => {

        if (!!data) {
            return data
        }

        return {
            "text": {
                "title": "",
                "description": "",
                "titleRow1": "",
                "textRow1": "",
                "titleRow2": "",
                "textRow2": "",
                "titleRow3": "",
                "textRow3": ""
            },
            "images": {
                "banner": {
                    "file": null
                },
                "imageRow1": {
                    "file": null
                },
                "imageRow2": {
                    "file": null
                },
                "imageRow3": {
                    "file": null
                }
            }
        }
    }, [data])

    return (
        <Stack>
            <Grid>
                {/* <Grid.Col sm={12} md={12}>
                    <Stack>
                        {layoutData.text.title && (
                            <Title order={1}>{layoutData.text.title}</Title>
                        )}
                        {layoutData.text.description && (
                            <Title order={3}>{layoutData.text.description}</Title>
                        )}
                    </Stack>
                </Grid.Col> */}
                <Grid.Col sm={12} md={12}>
                    {layoutData.images.banner?.file && (
                        <BackgroundImage src={layoutData.images.banner?.file.url} w="100%" h={150} sx={{ borderRadius: 8 }} />
                    )}
                    {/* <Divider my={16} /> */}
                </Grid.Col>
            </Grid>
            <Stack spacing={24}>
                <Grid>
                    <Grid.Col sm={12} md={7}>
                        <Stack sx={{ justifyContent: "center" }} h="100%">
                            {layoutData.text.titleRow1 && (
                                <Text fw="bold" fz="lg">{layoutData.text.titleRow1}</Text>
                            )}
                            {layoutData.text.textRow1 && (
                                <Text>{layoutData.text.textRow1}</Text>
                            )}
                        </Stack>
                    </Grid.Col>
                    <Grid.Col sm={12} md={5}>
                        {layoutData.images.imageRow1.file && (
                            <BackgroundImage src={layoutData.images.imageRow1.file.url} w="100%" h={400} sx={{ borderRadius: 8 }} />
                        )}
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={12} md={5} order={2} orderMd={1}>
                        {layoutData.images.imageRow2.file && (
                            <BackgroundImage src={layoutData.images.imageRow2.file.url} w="100%" h={400} sx={{ borderRadius: 8 }} />
                        )}
                    </Grid.Col>
                    <Grid.Col sm={12} md={7} order={1} orderMd={2}>
                        <Stack sx={{ justifyContent: "center" }} h="100%">
                            {layoutData.text.titleRow2 && (
                                <Text fw="bold" fz="lg">{layoutData.text.titleRow2}</Text>
                            )}
                            {layoutData.text.textRow2 && (
                                <Text>{layoutData.text.textRow2}</Text>
                            )}
                        </Stack>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={12} md={7}>
                        <Stack sx={{ justifyContent: "center" }} h="100%">
                            {layoutData.text.titleRow3 && (
                                <Text fw="bold" fz="lg">{layoutData.text.titleRow3}</Text>
                            )}
                            {layoutData.text.textRow3 && (
                                <Text>{layoutData.text.textRow3}</Text>
                            )}
                        </Stack>
                    </Grid.Col>
                    <Grid.Col sm={12} md={5}>
                        {layoutData.images.imageRow3.file && (
                            <BackgroundImage src={layoutData.images.imageRow3.file.url} w="100%" h={400} sx={{ borderRadius: 8 }} />
                        )}
                    </Grid.Col>
                </Grid>
            </Stack>
        </Stack>
    );
};

export default ProfileLayoutTemplate;