import { ActionIcon, BackgroundImage, Box, Button, Card, Center, Container, Divider, FileButton, Grid, Group, Image, Loader, Modal, Stack, Text, Textarea, TextInput } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { Files, Trash } from '@phosphor-icons/react';
import { useAgencyAPI } from 'api/useAgencyAPI';
import { convertFileToPreview, convertSingleFileToPreview } from 'helpers/utility';
import { useUpload } from 'hooks/useUpload';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileLayoutTemplate from './ProfileLayoutTemplate';
import AgencyCard from './AgencyCard';
import DeveloperCard from './DeveloperCard';
import { useDeveloperAPI } from 'api/useDeveloperAPI';

type imageFormKeys = "imageRow1" | "imageRow2" | "imageRow3"

const DeveloperProfileForm = () => {
    const filepath = '/public-profile/developer'
    const navigate = useNavigate()
    const { id, type } = useParams()
    const [imageForm, setImageForm] = useState({
        banner: {
            file: null,
            preview: "",
            existingData: null,
            loading: false
        },
        imageRow1: {
            file: null,
            preview: "",
            existingData: null,
            loading: false
        },
        imageRow2: {
            file: null,
            preview: "",
            existingData: null,
            loading: false
        },
        imageRow3: {
            file: null,
            preview: "",
            existingData: null,
            loading: false
        },
    } as any)
    const [uploadedFiles, setUploadedFiles] = useState({
        done: 0,
        failed: 0,
        started: 0
    })
    const [activeTab, setActiveTab] = useState(0)
    const { uploadMutation } = useUpload();
    const { useGetOneDeveloper, useUpdateDeveloper } = useDeveloperAPI()
    const { data, isLoading, error } = useGetOneDeveloper(id ?? "")
    const updateDeveloper = useUpdateDeveloper()


    const form = useForm({
        initialValues: {
            // title: "",
            // description: "",
            titleRow1: "",
            textRow1: "",
            titleRow2: "",
            textRow2: "",
            titleRow3: "",
            textRow3: "",
        }
    })

    useEffect(() => {
        if (!!data && !!id) {
            console.log("data", data)

            form.setValues({
                titleRow1: data.profile?.text?.titleRow1 ?? "",
                textRow1: data.profile?.text?.textRow1 ?? "",
                titleRow2: data.profile?.text?.titleRow2 ?? "",
                textRow2: data.profile?.text?.textRow2 ?? "",
                titleRow3: data.profile?.text?.titleRow3 ?? "",
                textRow3: data.profile?.text?.textRow3 ?? "",
            })

            setImageForm({
                banner: {
                    file: null,
                    preview: data.profile?.images?.banner?.file?.url ?? null,
                    existingData: data.profile?.images?.banner?.file?.url ? data.profile?.images?.banner.file : null,
                    loading: false
                },
                imageRow1: {
                    file: null,
                    preview: data.profile?.images?.imageRow1?.file?.url ?? null,
                    existingData: data.profile?.images?.imageRow1?.file?.url ? data.profile?.images?.imageRow1.file : null,
                    loading: false
                },
                imageRow2: {
                    file: null,
                    preview: data.profile?.images?.imageRow2?.file?.url ?? null,
                    existingData: data.profile?.images?.imageRow2?.file?.url ? data.profile?.images?.imageRow2.file : null,
                    loading: false
                },
                imageRow3: {
                    file: null,
                    preview: data.profile?.images?.imageRow3?.file?.url ?? null,
                    existingData: data.profile?.images?.imageRow2?.file?.url ? data.profile?.images?.imageRow2.file : null,
                    loading: false
                },
            })
        }
    }, [data, id])


    const handleUpload = async (e: any, imageKey: string) => {
        // setValue(e);
        setImageForm({
            ...imageForm,
            [`${imageKey}`]: {
                ...[`${imageKey}`],
                loading: true
            }
        })
        try {
            const { preview, file } = await convertSingleFileToPreview(e);

            console.log("data", { preview, file })

            setImageForm({
                ...imageForm,
                [`${imageKey}`]: {
                    file,
                    preview: preview.base64,
                    loading: false
                }
            })
        } catch (e) {
            console.log("error", e)
            setImageForm({
                ...imageForm,
                [`${imageKey}`]: {
                    ...[`${imageKey}`],
                    loading: false
                }
            })
        }
    };

    const clearFileUpload = (imageKey: string) => {
        setImageForm({
            ...imageForm,
            [`${imageKey}`]: {
                file: null,
                preview: "",
                existingData: null,
                loading: false
            }
        })
    }

    const SingleImageDrop = ({ ...parentProps }) => {
        return (
            <>
                <FileButton
                    onChange={file => handleUpload(file, parentProps.imageKey)}
                    accept="image/*"
                >
                    {(props) => (
                        <Box pos="relative" h="100%" w="100%">
                            {parentProps.loading ? (
                                <Center w="100%" h="100%">
                                    <Loader />
                                </Center>
                            ) : (
                                <>
                                    {imageForm[parentProps.imageKey as imageFormKeys]?.preview ? (
                                        <>
                                            <BackgroundImage src={imageForm[parentProps.imageKey as imageFormKeys]?.preview} sx={{ ...parentProps.buttonStyle, borderRadius: 8 }} />
                                            {/* <Image src={imageForm[parentProps.imageKey as imageFormKeys]?.preview} /> */}
                                            <ActionIcon
                                                sx={{
                                                    borderRadius: "50%",
                                                    backgroundColor: "white",
                                                    border: "1px solid #BFC8C3",
                                                    position: "absolute",
                                                    top: 0,
                                                    right: 0,
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    clearFileUpload(parentProps.imageKey);
                                                }}
                                            >
                                                <Trash
                                                    size={16}
                                                    color="red"
                                                />
                                            </ActionIcon>
                                        </>
                                    ) : (
                                        <Button  {...props} variant='outline' sx={{ ...parentProps.buttonStyle }}>{parentProps.placeholder}</Button>
                                    )}
                                </>
                            )}
                        </Box>
                    )}
                </FileButton>
            </>
        )
    }

    const totalUploadsNeeded = useMemo(() => {
        let returnVal = 0
        for (const imageKey in imageForm) {
            if (!!imageForm[imageKey as imageFormKeys].file) {
                returnVal += 1
            }
        }

        return returnVal
    }, [imageForm])

    const handleSubmit = async (template: any) => {
        try {
            await updateDeveloper.mutateAsync({
                id,
                profile: template
            })

            notifications.show({
                title: "Success!",
                message: "Successfully updated agency profile",
                autoClose: 2000,
            });

            console.log("template", template)
        } catch (e) {
            notifications.show({
                title: "Error updating agency profile",
                message: "If this issue persist, please contact our support",
                autoClose: 2000,
            });
        }
    }


    const handleSubmitForm = useCallback(async () => {
        let imageTemplate = {
            banner: {
                file: null,
            },
            imageRow1: {
                file: null,
            },
            imageRow2: {
                file: null,
            },
            imageRow3: {
                file: null,
            },
        }

        let imageUploadLength = 0
        for (const imageKey in imageForm) {
            if (!!imageForm[imageKey as imageFormKeys].file && !imageForm[imageKey as imageFormKeys].existingData) {
                imageUploadLength += 1
            } else if (!!imageForm[imageKey as imageFormKeys].existingData) {
                imageTemplate[imageKey as imageFormKeys].file = imageForm[imageKey as imageFormKeys].existingData
            }
        }

        if (imageUploadLength > 0) {
            setUploadedFiles((prevState) => ({
                done: 0,
                failed: 0,
                started: 1
            }))
            for (const imageKey in imageForm) {
                if (!!imageForm[imageKey as imageFormKeys].file) {
                    try {
                        const uploadData = await uploadMutation.mutateAsync({
                            file: imageForm[imageKey as imageFormKeys].file,
                            path: `${filepath}/${id}`,
                        });

                        imageTemplate[imageKey as imageFormKeys].file = uploadData[0]
                        setUploadedFiles((prevState) => ({
                            done: prevState.done + 1,
                            failed: prevState.failed,
                            started: prevState.started
                        }))
                    } catch (e) {
                        console.log("upload err", e)
                        setUploadedFiles((prevState) => ({
                            done: prevState.done,
                            failed: prevState.failed + 1,
                            started: prevState.started
                        }))
                    }
                }
            }

            setUploadedFiles((prevState) => ({
                ...prevState,
                started: 0
            }))
        }



        const template = {
            text: form.values,
            images: imageTemplate
        }

        handleSubmit(template)
    }, [imageForm, form.values])

    const layoutData = useMemo(() => {
        const data = {
            "text": {
                "titleRow1": form.values.titleRow1,
                "textRow1": form.values.textRow1,
                "titleRow2": form.values.titleRow2,
                "textRow2": form.values.textRow2,
                "titleRow3": form.values.titleRow3,
                "textRow3": form.values.textRow3,
            },
            "images": {
                "banner": {
                    "file": {
                        url: imageForm.banner.preview ?? null
                    }
                },
                "imageRow1": {
                    "file": {
                        url: imageForm.imageRow1.preview ?? null
                    }
                },
                "imageRow2": {
                    "file": {
                        url: imageForm.imageRow2.preview ?? null
                    }
                },
                "imageRow3": {
                    "file": {
                        url: imageForm.imageRow3.preview ?? null
                    }
                }
            }
        }

        return data
    }, [form.values, imageForm])

    return (
        <Box pos="relative">
            <Modal
                opened={!!uploadedFiles.started}
                onClose={() => { }}
                centered
                withCloseButton={false}
                closeOnClickOutside={false}
            >
                <Text align='center' fw="bold" fz={24}>Uploading Images</Text>
                <Text align='center' fw="bold" fz={16}>Please hold on...</Text>
                <Group position='center'>
                    <Loader />
                </Group>
                <Text align='center' fw="bold" fz={32}>{uploadedFiles.done}/{totalUploadsNeeded}</Text>
                <Text align='center' fz={14}>failed: {uploadedFiles.failed}</Text>
                <Text align='center' color='red'>This process may take up to 5 minutes</Text>
                <Text align='center' color='red'>Please do not close or refresh this tab </Text>
            </Modal>
            <Container py={32}>
                {activeTab === 0 && (
                    <Stack>
                        <Grid>
                            <Grid.Col sm={12}>
                                <SingleImageDrop
                                    loading={imageForm.banner.loading}
                                    imageKey="banner"
                                    placeholder="Banner"
                                    buttonStyle={{ "height": 150, width: '100%' }}
                                />
                            </Grid.Col>
                            <Grid.Col sm={12} md={7}>
                                <Stack>
                                    <TextInput
                                        placeholder="title"
                                        {...form.getInputProps("titleRow1")}
                                    />
                                    <Textarea
                                        autosize
                                        minRows={6}
                                        placeholder='type your content here...'
                                        {...form.getInputProps("textRow1")}
                                    ></Textarea>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col sm={12} md={5}>
                                <SingleImageDrop
                                    loading={imageForm.imageRow1.loading}
                                    imageKey="imageRow1"
                                    placeholder="Image 1"
                                    buttonStyle={{ "height": 400, width: '100%' }}
                                />
                            </Grid.Col>
                        </Grid>
                        <Grid>
                            <Grid.Col sm={12} md={5} order={2} orderMd={1}>
                                <SingleImageDrop
                                    loading={imageForm.imageRow2.loading}
                                    imageKey="imageRow2"
                                    placeholder="Image 2"
                                    buttonStyle={{ "height": 400, width: '100%' }}
                                />
                            </Grid.Col>
                            <Grid.Col sm={12} md={7} order={1} orderMd={2}>
                                <Stack>
                                    <TextInput
                                        placeholder="title"
                                        {...form.getInputProps("titleRow2")}
                                    />
                                    <Textarea
                                        autosize
                                        minRows={6}
                                        placeholder='type your content here...'
                                        {...form.getInputProps("textRow2")}
                                    ></Textarea>
                                </Stack>
                            </Grid.Col>
                        </Grid>
                        <Grid>
                            <Grid.Col sm={12} md={7}>
                                <Stack>
                                    <TextInput
                                        placeholder="title"
                                        {...form.getInputProps("titleRow3")}
                                    />
                                    <Textarea
                                        autosize
                                        minRows={6}
                                        placeholder='type your content here...'
                                        {...form.getInputProps("textRow3")}
                                    ></Textarea>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col sm={12} md={5}>
                                <SingleImageDrop
                                    loading={imageForm.imageRow3.loading}
                                    imageKey="imageRow3"
                                    placeholder="Image 3"
                                    buttonStyle={{ "height": 400, width: '100%' }}
                                />
                            </Grid.Col>
                        </Grid>
                    </Stack>
                )}
                {activeTab === 1 && (
                    <Stack sx={{ minHeight: "100vh" }}>
                        {!!data && (
                            <DeveloperCard
                                developer={data}
                                onLearnMore={() => { }}
                                onApply={() => { }}
                            />
                        )}
                        <ProfileLayoutTemplate data={layoutData} />
                    </Stack>
                )}

            </Container>
            <Card pos="sticky" bottom={0} withBorder shadow="md">
                <Group position='apart'>
                    <Button variant='outline' color='red' onClick={() => navigate(`/developer/edit/${id}`)}>Back</Button>
                    <Group position='right' sx={{ flex: 1 }}>
                        <Button variant='subtle'>Guide</Button>
                        {activeTab === 0 && (
                            <Button variant='outline' onClick={() => setActiveTab(1)}>Preview</Button>
                        )}
                        {activeTab === 1 && (
                            <Button variant='outline' onClick={() => setActiveTab(0)}>Edit</Button>
                        )}
                        <Button onClick={() => handleSubmitForm()}>Save</Button>
                    </Group>
                </Group>
            </Card>
        </Box>
    );
};

export default DeveloperProfileForm;