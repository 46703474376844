import { Box, Button, Container, Grid, Image, Stack, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const OtherAccounts = () => {
    const navigate = useNavigate();

    const data = [
        {
            title: "Are You Property-Related Business?",
            description: "Register to promote your services to both Agents and Property Owners!",
            img: "/other-services/property-business.png",
            disabled: true,
            link: ""
        },
        {
            title: "Become A Property Agent!",
            description: "Discover a whole new income stream! Choose the right agency to work with on our platform. We’ve  partnered with some great ones.",
            img: "/other-services/potential-agent.png",
            disabled: false,
            link: "/become-an-agent"
        },
        {
            title: "Are you an Owner?",
            description: " Sell your property with us! List the details of your property and we’ll connect you with the right agents to sell it!",
            img: "/other-services/property-owner.png",
            disabled: false,
            link: "/owners"
        },
    ]

    return (
        <Stack spacing={0}>
            <Stack sx={{ backgroundColor: "#81A081", }}>
                <Container
                    size="1200px"
                    w="100%"
                    py={32}
                >
                    <Text fw="bold" align='center' fz={'2em'}><Text c="#B4E562" span>Not looking</Text> for Property?</Text>
                </Container>
            </Stack>
            <Stack sx={{backgroundColor: "#F1FFF8"}}>
                <Container
                    size="1200px"
                    w="100%"
                    py={32}
                >
                    <Stack spacing={32}>
                        <Text align='center' fw="bold">Check out some of our other services!</Text>
                        <Grid>
                            {data.map(d => (
                                <Grid.Col xs={12} md={4}>
                                    <Stack spacing={16} sx={{ height: "100%" }}>
                                        <Image src={d.img} alt={d.title}/>
                                        <Stack spacing={4} sx={{ flex: 1 }} justify='flex-start'>
                                            <Text align='center' c="#15BC69" fw="bold">{d.title}</Text>
                                            <Text align='center'>{d.description}</Text>
                                        </Stack>

                                        <Button disabled={d.disabled} onClick={() => navigate(d.link)}>{d.disabled ? "Coming Soon" : "Learn More"}</Button>
                                    </Stack>
                                </Grid.Col>
                            ))}
                        </Grid>
                    </Stack>
                </Container>
            </Stack>
        </Stack>
    );
};

export default OtherAccounts;