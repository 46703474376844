import { Accordion, Badge, Box, Button, Checkbox, Drawer, Group, NumberInput, ScrollArea, Stack, Text } from '@mantine/core';
import { CONFIG_KEYS, PROPERTY_REQUEST_COLOR_SETTINGS, PROPERTY_REQUEST_TYPE } from 'helpers/constants';
import React, { useEffect, useMemo, useState } from 'react';
import { Bath, Bed, ChevronLeft } from 'tabler-icons-react';
import presets from "../../assets/filterPresets.json";
import { useGetConfigs } from 'api/configAPI';
import { useListingAPI } from 'api/useListingAPI';
import { mergeArraysAndRemoveDuplicates } from 'helpers/utility';
import Paywall from 'pages/AgentDashboard/components/Paywall';
import { useProfileController } from 'hooks/useProfile';

const ListingFilters = ({
    state,
    dispatch,
    handleClearFilters,
    onClose,
    filterType
}: any) => {
    const { isPro, isBasic } = useProfileController();
    const { useGetTags } = useListingAPI();

    const [presetDropdown, setPresetDropdown] = useState({
        sqftMin: false,
        sqftMax: false,
    });
    const [selectTags, setSelectTags] = useState<any[]>([]);
    const [booleanTags, setBooleanTags] = useState<any[]>([]);

    const propertyRequestOptions = [
        PROPERTY_REQUEST_TYPE.BUY,
        PROPERTY_REQUEST_TYPE.RENT,
        PROPERTY_REQUEST_TYPE.INVEST,
    ];

    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    const {
        data: tagsData,
        isLoading: tagsIsLoading,
        error: tagsError,
    } = useGetTags();

    useEffect(() => {
        if (!!tagsData && !tagsIsLoading) {
            const selects = tagsData.filter(
                (tag: any) => tag.values.type === "select"
            );
            const booleans = tagsData.filter(
                (tag: any) => tag.values.type === "boolean"
            );

            setSelectTags(selects);
            setBooleanTags(booleans);
            // setTags([...selects, ...booleans]);
        }
    }, [tagsData, tagsIsLoading]);


    const propertyTypeIsChecked = (title: string) => {
        return state.propertyType.find(
            (propertyType: any) => propertyType === title
        );
    };

    const transactionTypeIsChecked = (title: string) => {
        return state.transactionType.find(
            (transactionType: any) => transactionType === title
        );
    };
    const tagIsChecked = (title: string) => {
        return state.tags.find((tag: any) => tag.value === title);
    };

    const resetAndUpdate = (key: string) => {
        let newObj = {} as any;
        Object.keys(presetDropdown).forEach((e) => {
            newObj[e] = key === e;
        });


        setPresetDropdown(newObj);
    };

    const propertyTypes = useMemo(() => {
        if (!!configData && !configIsLoading) {
            const data = configData.find(
                (p: any) => p.key === CONFIG_KEYS.PROPERTY_TYPES
            ).value;

            if (state.transactionType.length === 0) {
                let proptypeArr = [] as [][];
                data.forEach((d: any) => proptypeArr.push(d.values));
                return mergeArraysAndRemoveDuplicates(proptypeArr);
            }

            return (
                data.find((d: any) => state.transactionType.includes(d.type))
                    ?.values ?? []
            );
        }

        return [];
    }, [configData]);

    const defaultOpenKeys = useMemo(() => {
        let arr = [
            "location",
            "budget",
            "property type",
            "property transaction types",
            "amenities",
            "sqft",
        ] as string[];

        if (selectTags.length > 0) {
            const keys = selectTags.map((s: any) => s.title);
            arr = [...arr, ...keys];
        }

        if (booleanTags.length > 0) {
            arr.push("other");
        }

        return arr;
    }, [selectTags]);

    useEffect(() => {
        console.log("filterType here", filterType)
    }, [filterType])

    return (
        <Stack spacing={8}>
            {!!onClose && (
                <Group>
                    <Button variant='subtle' onClick={() => onClose()}>
                        <ChevronLeft />
                        <Text>Back</Text>
                    </Button>
                </Group>
            )}
            <Button
                variant="outline"
                onClick={() => handleClearFilters()}
            >
                Clear Filters
            </Button>
            {/* {filterType === "agent" && (
            )} */}
            <Stack spacing={4} sx={{ position: 'relative' }}>
                {!isPro && (
                    <Paywall type="PRO" />
                )}

                <Checkbox
                    onChange={(event) =>
                        dispatch({
                            type: "IS_URGENT_CHANGE",
                            payload:
                            {
                                value: event.currentTarget
                                    .checked
                            },
                        })
                    }
                    label={
                        <Badge color="red">⚠️ Urgent</Badge>
                    }
                />
                <Checkbox
                    onChange={(event) =>
                        dispatch({
                            type: "NEW_PROJECTS_ONLY_CHANGE",
                            payload:
                            {
                                value: event.currentTarget
                                    .checked
                            },
                        })
                    }
                    label={
                        <Badge color="blue" size={'lg'}>NEW PROJECTS OK</Badge>
                    }
                />
                <Checkbox
                    onChange={(event) =>
                        dispatch({
                            type: "SUBSALES_OK_CHANGE",
                            payload:
                            {
                                value: event.currentTarget
                                    .checked
                            },
                        })
                    }
                    label={
                        <Badge
                            color="dark"
                            size={'lg'}
                        >
                            Subsales OK
                        </Badge>
                    }
                />
            </Stack>
            <Accordion multiple defaultValue={defaultOpenKeys}>
                <Accordion.Item value={"property transaction types"}>
                    <Accordion.Control>
                        Property Transaction Types
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Stack spacing={8}>
                            {propertyRequestOptions.map(
                                (propRequestType) => (
                                    <Checkbox
                                        checked={transactionTypeIsChecked(
                                            propRequestType
                                        )}
                                        onChange={(event) =>
                                            dispatch({
                                                type: "PROPERTY_TRANSACTION_TYPE_CHANGE",
                                                payload:
                                                    propRequestType,
                                                include:
                                                    event.currentTarget
                                                        .checked,
                                            })
                                        }
                                        label={
                                            <Badge
                                                sx={{
                                                    color: PROPERTY_REQUEST_COLOR_SETTINGS[
                                                        propRequestType
                                                    ].color,
                                                    backgroundColor:
                                                        PROPERTY_REQUEST_COLOR_SETTINGS[
                                                            propRequestType
                                                        ].background,
                                                }}
                                            >
                                                {propRequestType}
                                            </Badge>
                                        }
                                    />
                                )
                            )}
                        </Stack>
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value={"property type"}>
                    <Accordion.Control>Property Type</Accordion.Control>
                    <Accordion.Panel>
                        <Stack>
                            {propertyTypes.map((pt: any) => (
                                <Checkbox
                                    checked={propertyTypeIsChecked(pt)}
                                    onChange={(event) =>
                                        dispatch({
                                            type: "PROPERTY_TYPE_CHANGE",
                                            payload: pt,
                                            include:
                                                event.currentTarget
                                                    .checked,
                                        })
                                    }
                                    label={pt}
                                />
                            ))}
                        </Stack>
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value={"budget"}>
                    <Accordion.Control>Budget</Accordion.Control>
                    <Accordion.Panel>
                        <Stack spacing={32}>
                            <Stack>
                                <Stack
                                    spacing={4}
                                    sx={{ width: "100%" }}
                                >
                                    <NumberInput
                                        hideControls={true}
                                        label="Min Budget"
                                        radius="md"
                                        sx={{ flex: 1 }}
                                        value={state.minPrice}
                                        onChange={(e) =>
                                            dispatch({
                                                type: "BUDGET_MIN_CHANGE",
                                                payload: e as number,
                                            })
                                        }
                                        icon={
                                            <Text
                                                sx={{
                                                    color: "#adb5bd",
                                                }}
                                            >
                                                RM
                                            </Text>
                                        }
                                    />
                                </Stack>
                                <Stack
                                    spacing={4}
                                    sx={{ width: "100%" }}
                                >
                                    <NumberInput
                                        hideControls={true}
                                        label="Max Budget"
                                        radius="md"
                                        sx={{ flex: 1 }}
                                        value={state.maxPrice}
                                        onChange={(e) =>
                                            dispatch({
                                                type: "BUDGET_MAX_CHANGE",
                                                payload: e as number,
                                            })
                                        }
                                        icon={
                                            <Text
                                                sx={{
                                                    color: "#adb5bd",
                                                }}
                                            >
                                                RM
                                            </Text>
                                        }
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Accordion.Panel>
                </Accordion.Item>
                <Box sx={{ position: 'relative' }}>
                    {!isPro && (
                        <Paywall type="PRO" />
                    )}

                    <Accordion.Item value={"amenities"}>
                        <Accordion.Control>Amenities</Accordion.Control>
                        <Accordion.Panel>
                            <Stack spacing={32}>
                                {/* <RangeSlider
                                            min={0}
                                            max={insights.rangeMax}
                                            onChange={(e) => setBudgetFilters(e)}
                                            marks={insights.steps}
                                            value={budgetFilters}
                                        /> */}
                                <NumberInput
                                    label="No. of Bathrooms"
                                    hideControls
                                    sx={{ flex: 1 }}
                                    min={1}
                                    max={5}
                                    value={state.noOfBathrooms}
                                    onChange={(e) =>
                                        dispatch({
                                            type: "BATHROOM_CHANGE",
                                            payload: e as number,
                                        })
                                    }
                                    icon={<Bath />}
                                />
                                <NumberInput
                                    label="No. of Bedrooms"
                                    hideControls
                                    sx={{ flex: 1 }}
                                    min={1}
                                    max={5}
                                    value={state.noOfBedrooms}
                                    onChange={(e) =>
                                        dispatch({
                                            type: "BEDROOM_CHANGE",
                                            payload: e as number,
                                        })
                                    }
                                    icon={<Bed />}
                                />
                            </Stack>
                        </Accordion.Panel>
                    </Accordion.Item>
                    <Accordion.Item value={"sqft"}>
                        <Accordion.Control>Square Feet</Accordion.Control>
                        <Accordion.Panel>
                            <Stack spacing={32}>
                                <Stack
                                    // position="apart"
                                    sx={{ alignItems: "flex-start" }}
                                >
                                    <Stack
                                        spacing={8}
                                        sx={{ flex: 1, width: "100%" }}
                                    >
                                        <NumberInput
                                            hideControls={true}
                                            label="Min Sqft"
                                            radius="md"
                                            sx={{ flex: 1 }}
                                            value={state.minSquareFt}
                                            onChange={(e) =>
                                                dispatch({
                                                    type: "MIN_SQFT_CHANGE",
                                                    payload: e as number,
                                                })
                                            }
                                            rightSection={
                                                <Text
                                                    sx={{
                                                        color: "#adb5bd",
                                                    }}
                                                >
                                                    Sqft
                                                </Text>
                                            }
                                            rightSectionWidth={45}
                                            onFocus={() =>
                                                resetAndUpdate("sqftMin")
                                            }
                                        // onBlur={() =>
                                        //     setPresetDropdown({
                                        //         ...presetDropdown,
                                        //         sqftMin: false,
                                        //     })
                                        // }
                                        />
                                        {presetDropdown.sqftMin && (
                                            <ScrollArea h={200}>
                                                <Stack spacing={4}>
                                                    {presets.sqft.map(
                                                        (s) => (
                                                            <Button
                                                                variant="subtle"
                                                                onClick={() => {
                                                                    dispatch(
                                                                        {
                                                                            type: "MIN_SQFT_CHANGE",
                                                                            payload:
                                                                                s.value as number,
                                                                        }
                                                                    );
                                                                    setPresetDropdown(
                                                                        {
                                                                            ...presetDropdown,
                                                                            sqftMin:
                                                                                false,
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                {s.label}
                                                            </Button>
                                                        )
                                                    )}
                                                </Stack>
                                            </ScrollArea>
                                        )}
                                    </Stack>
                                    <Stack
                                        spacing={8}
                                        sx={{ flex: 1, width: "100%" }}
                                    >
                                        <NumberInput
                                            hideControls={true}
                                            label="Max Sqft"
                                            radius="md"
                                            sx={{ flex: 1 }}
                                            value={state.maxSquareFt}
                                            onChange={(e) =>
                                                dispatch({
                                                    type: "MAX_SQFT_CHANGE",
                                                    payload: e as number,
                                                })
                                            }
                                            rightSection={
                                                <Text
                                                    sx={{
                                                        color: "#adb5bd",
                                                    }}
                                                >
                                                    Sqft
                                                </Text>
                                            }
                                            rightSectionWidth={45}
                                            onFocus={() =>
                                                resetAndUpdate("sqftMax")
                                            }
                                        />
                                        {presetDropdown.sqftMax && (
                                            <ScrollArea h={200}>
                                                <Stack spacing={4}>
                                                    {presets.sqft.map(
                                                        (s) => (
                                                            <Button
                                                                variant="subtle"
                                                                onClick={() => {
                                                                    dispatch(
                                                                        {
                                                                            type: "MAX_SQFT_CHANGE",
                                                                            payload:
                                                                                s.value as number,
                                                                        }
                                                                    );
                                                                    setPresetDropdown(
                                                                        {
                                                                            ...presetDropdown,
                                                                            sqftMax:
                                                                                false,
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                {s.label}
                                                            </Button>
                                                        )
                                                    )}
                                                </Stack>
                                            </ScrollArea>
                                        )}
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Accordion.Panel>
                    </Accordion.Item>

                    {selectTags.map((tag: any) => (
                        <Accordion.Item value={tag.title}>
                            <Accordion.Control>
                                {tag.title}
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Stack spacing={8}>
                                    {tag.values.options.map(
                                        (option: any) => (
                                            <Checkbox
                                                checked={tagIsChecked(
                                                    option.label
                                                )}
                                                onChange={(event) =>
                                                    dispatch({
                                                        type: "TAG_CHANGE",
                                                        payload:
                                                            option.label,
                                                        include:
                                                            event
                                                                .currentTarget
                                                                .checked,
                                                        options: tag.values.options
                                                    })
                                                }
                                                label={option.label}
                                            />
                                        )
                                    )}
                                </Stack>
                            </Accordion.Panel>
                        </Accordion.Item>
                    ))}
                    {booleanTags.length > 0 && (
                        <Accordion.Item value={"other"}>
                            <Accordion.Control>
                                Other Tags
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Stack spacing={8}>
                                    {booleanTags.map((tag: any) => (
                                        <Checkbox
                                            checked={tagIsChecked(
                                                tag.title
                                            )}
                                            onChange={(event) =>
                                                dispatch({
                                                    type: "TAG_CHANGE",
                                                    payload: tag.title,
                                                    include:
                                                        event.currentTarget
                                                            .checked,
                                                })
                                            }
                                            label={tag.title}
                                        />
                                    ))}
                                </Stack>
                            </Accordion.Panel>
                        </Accordion.Item>
                    )}
                </Box>
            </Accordion>
        </Stack>
    );
};

export default ListingFilters;