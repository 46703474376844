//@ts-nocheck
import ReCAPTCHA from "react-google-recaptcha";

import { Anchor, Box, Button, Checkbox, Stack, Text } from '@mantine/core';
import { RegisterFooter } from 'components/Footer/RegisterFooter';
import { BaseFormInput } from 'components/Inputs/BaseFormInput';
import { BasePasswordInput } from 'components/Inputs/BasePasswordInput';
import { FormPasswordInput } from 'components/Inputs/FormPasswordInput';
import { strictNumberInput } from 'helpers/validations';
import React, { useState } from 'react';

const RegisterForm = ({ form, totalListers, checkboxes, handleCheckboxChange, onSubmit, submitIsLoading, isSubmitDisabled }: any) => {
    const [captchaPassed, setCaptchaPassed] = useState(false)
    return (
        <Box
            mx="auto"
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
            }}
            px={16}
        >
            <Stack spacing={0} mb={16}>
                <Text size={32} weight={500}>
                    Just One Last Step
                </Text>
                <Text size={32} weight={500}>
                    Create an Account
                </Text>
                {totalListers ? (
                    <Text size={16}>Join the new generation of property browsing and selling with <br /> {100 + totalListers} other users today!</Text>
                ) : (
                    <Text size={16}>
                        Join the new generation of property browsing and selling
                    </Text>
                )}
            </Stack>
            <BaseFormInput
                label="Preferred Name (How should we greet you?)"
                required
                {...form.getInputProps("fullname")}
            />
            <BaseFormInput
                label="Email Address"
                required
                {...form.getInputProps("email")}
            />
            <Box>
                <BaseFormInput
                    type="number"
                    label="Phone Number"
                    icon={
                        <Text
                            color="black"
                            sx={{ whiteSpace: "nowrap" }}
                        >
                            {`MY (+60)`}
                        </Text>
                    }
                    iconWidth={90}
                    required
                    onKeyDown={(evt: any) => strictNumberInput(evt)}
                    {...form.getInputProps("phoneNumber")}
                />
                {!!form.errors?.phoneNumber && (
                    <Text
                        mt={5}
                        size={14}
                        color="red"
                        sx={{ lineHeight: 1.2 }}
                    >
                        Please double-check this number
                    </Text>
                )}
            </Box>
            <FormPasswordInput
                {...form.getInputProps("password")}
                form={form}
                required
            />
            <BasePasswordInput
                label="Confirm Password"
                required
                {...form.getInputProps("passwordConfirm")}
            />
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                onChange={(e) => {
                    setCaptchaPassed(!!e)
                }}
            />
            <Checkbox
                mt={8}
                label={
                    <Text>
                        I have read and agree to the{" "}
                        <Anchor
                            href="/termsandconditions"
                            target="_blank"
                            sx={{ textDecoration: "underline" }}
                        >
                            Terms of Service
                        </Anchor>
                    </Text>
                }
                sx={{
                    ".mantine-Checkbox-body": {
                        display: "flex",
                        alignItems: "center",
                    },
                }}
                checked={checkboxes.tnc}
                onChange={() => handleCheckboxChange("tnc")}
                mb={16}
            />
            <Checkbox
                label={
                    <Text>
                        I have read and agree to the{" "}
                        <Anchor
                            href="/privacypolicy"
                            target="_blank"
                            sx={{ textDecoration: "underline" }}
                        >
                            Privacy Policy
                        </Anchor>
                    </Text>
                }
                sx={{
                    ".mantine-Checkbox-body": {
                        display: "flex",
                        alignItems: "center",
                    },
                }}
                checked={checkboxes.privacy}
                onChange={() => handleCheckboxChange("privacy")}
            />
            <Text mt={16} size={12}>
                Your personal info is kept private and not shared to
                agents.
            </Text>
            <Button
                size="lg"
                radius={10}
                my={16}
                // type="submit"
                onClick={() => onSubmit(form.values)}
                loading={submitIsLoading}
                disabled={
                    isSubmitDisabled||
                    !captchaPassed
                }
            >
                Register
            </Button>
            <RegisterFooter />
        </Box>
    );
};

export default RegisterForm;