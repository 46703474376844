import { Carousel, Embla } from '@mantine/carousel';
import { Stack, Text, Title } from '@mantine/core';
import { useListingAPI } from 'api/useListingAPI';
import PropRequestCard from 'components/PropRequest/PropRequestCard';
import React, { useEffect, useRef, useState } from 'react';

const PropRequestRiver = () => {
    const { useGetSearchPropRequests } = useListingAPI();
    const [propReqArr, setPropReqArr] = useState([])
    const [embla, setEmbla] = useState<Embla | null>(null);

    const { data, isLoading, error, refetch } = useGetSearchPropRequests({
        pagination: {
            page: 1,
            pageSize: 10
        }
    });

    useEffect(() => {
        let result = data as any
        if (!!data && !!result.data && !isLoading) {
            setPropReqArr(result.data)
        }
    }, [data])

    useEffect(() => {
        if (!!embla) {
            console.log(embla)
            setInterval(() => {
                embla?.scrollNext()
            },10000)
        }
    }, [embla])

    if (!propReqArr) {
        return <></>
    }

    return (
        <Stack>
            <Title order={3}>Current Property Requests</Title>
            <Carousel
                // height={200}
                slideGap="md"
                loop
                align="start"
                breakpoints={[
                    { minWidth: 'md', slideSize: '50%' },
                    { minWidth: 'sm', slideSize: '100%', slideGap: 0 },
                ]}
                withControls={false}
                getEmblaApi={setEmbla}
            >
                {propReqArr.map((d, i) => (
                    <Carousel.Slide key={`landing-propreq-${i}`}>
                        <PropRequestCard data={d} isLanding hideButtons />
                    </Carousel.Slide>
                ))}
            </Carousel>
        </Stack>
    );
};

export default PropRequestRiver;